import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@rezolved/ui';
import { CustomPipesModule } from '../../core/pipes/custom-pipes.module';
import { UserGroupModule } from '../../shared/components/user-group/user-group.module';
import { MerchantEnrollmentComponent } from './merchant-enrollment.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component'
import { MatDialogModule } from '@angular/material/dialog';
import { SignatureComponent } from './signature/signature.component';


@NgModule({
  declarations: [MerchantEnrollmentComponent,TermsConditionsComponent, SignatureComponent],
  imports: [
    CommonModule,
    UiModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    MatCardModule,
    MatSelectModule,
    UserGroupModule,
    CustomPipesModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    TranslateModule.forChild(),
  ],
})
export class MerchantEnrollmentModule {}
