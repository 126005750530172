<rezolve-navbar class="default-theme" #navbar>
  <ng-template #navbarHeader>
    <div class="rezolve-logo"></div>
  </ng-template>

  <ng-template #navbarSection let-d>
    <rezolve-navbar-section
    *ngIf="(authService.isAuthenticated$ | async) && 
    ((authService.roles$ | async)?.includes('MXP_SUPER_ADMIN')) ||
    ((authService.roles$ | async)?.includes('MXP_POWER_ADMIN')) ||
    ((authService.roles$ | async)?.includes('MXP_PARTNER'))
    "
     name="{{ 'MAIN' | translate }}">
      <rezolve-navbar-item routerLink="/dashboard" (click)="d?.toggle()" *ngIf="shouldDisplayMainMenuOnProd">
        <mat-icon svgIcon="dashboard"></mat-icon>
        <span>Dashboard</span>
      </rezolve-navbar-item>
      <rezolve-navbar-item routerLink="/billing" (click)="d?.toggle()">
        <mat-icon svgIcon="billing"></mat-icon>
        <span>{{ 'BILLING' | translate }}</span>
      </rezolve-navbar-item>
    </rezolve-navbar-section>
    <rezolve-navbar-section
      *ngIf="(authService.isAuthenticated$ | async) && 
      ((authService.roles$ | async)?.includes('MXP_POWER_ADMIN') ||
      (authService.roles$ | async)?.includes('MXP_STORE_ADMIN') ||
      (authService.roles$ | async)?.includes('MXP_POWER_USER') ||
      (authService.roles$ | async)?.includes('MXP_USER') ||
      (authService.roles$ | async)?.includes('MXP_SUPER_ADMIN') ||
      (authService.roles$ | async)?.includes('MXP_REPORT_USER'))"
      name="{{ 'PAYMENT' | translate }}">
      <rezolve-navbar-item 
      routerLink="/request-payment"
       (click)="d?.toggle()"
       *ngIf="!((authService.roles$ | async)?.includes('MXP_SUPER_ADMIN') ||
       (authService.roles$ | async)?.includes('MXP_REPORT_USER'))">
        <mat-icon svgIcon="wallet"></mat-icon>
        <span>{{ 'REQUEST_PAYMENT' | translate }}</span>
      </rezolve-navbar-item>
      <rezolve-navbar-item
      routerLink="/bulk-payments"
      (click)="d?.toggle()"
      *ngIf="
        (authService.roles$ | async)?.includes('MXP_PARTNER') ||
        (authService.roles$ | async)?.includes('MXP_POWER_ADMIN')
      ">
      <mat-icon svgIcon="bulk-payment"></mat-icon>
      <span>{{ 'BULK_PAYMENTS' | translate }}</span>
    </rezolve-navbar-item>
    <rezolve-navbar-item
      routerLink="/reports"
      (click)="d?.toggle()"
      *ngIf="
        (authService.roles$ | async)?.includes('MXP_PARTNER') ||
        (authService.roles$ | async)?.includes('MXP_POWER_ADMIN') ||
        (authService.roles$ | async)?.includes('MXP_STORE_ADMIN') ||
        (authService.roles$ | async)?.includes('MXP_POWER_USER') ||
        (authService.roles$ | async)?.includes('MXP_REPORT_USER')
      ">
      <mat-icon svgIcon="reports"></mat-icon>
      <span>{{ 'REPORT' | translate }}</span>
    </rezolve-navbar-item>
    <rezolve-navbar-item
      routerLink="/refunds"
      (click)="d?.toggle()"
      *ngIf="(authService.roles$ | async)?.includes('MXP_SUPER_ADMIN')">
      <mat-icon svgIcon="refunds"></mat-icon>
      <span>Refunds</span>
    </rezolve-navbar-item>
    </rezolve-navbar-section>
    <rezolve-navbar-section
      *ngIf="
        (authService.isAuthenticated$ | async) &&
        ((authService.roles$ | async)?.includes('MXP_PARTNER') ||
          (authService.roles$ | async)?.includes('MXP_POWER_ADMIN') ||
          (authService.roles$ | async)?.includes('MXP_STORE_ADMIN') ||
          (authService.roles$ | async)?.includes('MXP_SUPER_ADMIN'))
      "
      name="{{ 'ADMINISTRATION' | translate }}">
      <rezolve-navbar-item
        routerLink="/stores"
        (click)="d?.toggle()"
        *ngIf="
          (authService.roles$ | async)?.includes('MXP_PARTNER') ||
          (authService.roles$ | async)?.includes('MXP_POWER_ADMIN') ||
          (authService.roles$ | async)?.includes('MXP_STORE_ADMIN')
        ">
        <mat-icon svgIcon="stores"></mat-icon>
        <span>{{ 'STORES' | translate }}</span>
      </rezolve-navbar-item>
      <rezolve-navbar-item
        routerLink="/users"
        (click)="d?.toggle()"
        *ngIf="
          (authService.roles$ | async)?.includes('MXP_PARTNER') ||
          (authService.roles$ | async)?.includes('MXP_POWER_ADMIN') ||
          (authService.roles$ | async)?.includes('MXP_STORE_ADMIN')
        ">
        <mat-icon svgIcon="users"></mat-icon>
        <span>{{ 'USERS' | translate }}</span>
      </rezolve-navbar-item>
      <rezolve-navbar-item
        routerLink="/partners"
        (click)="d?.toggle()"
        *ngIf="(authService.roles$ | async)?.includes('MXP_SUPER_ADMIN')">
        <mat-icon svgIcon="partners"></mat-icon>
        <span>Partners</span>
      </rezolve-navbar-item>
    </rezolve-navbar-section>
    <rezolve-navbar-section name="Account">
      <rezolve-navbar-item *ngIf="authService.isAuthenticated$ | async" routerLink="/my-details" (click)="d?.toggle()">
        <mat-icon svgIcon="my-details"></mat-icon>
        <span>{{ 'MY_DETAILS' | translate }}</span>
      </rezolve-navbar-item>
      <rezolve-navbar-item *ngIf="(authService.isAuthenticated$ | async) === false" (click)="loginWithRedirect()">
        <mat-icon svgIcon="logout"></mat-icon>
        <span>{{ 'LOG_IN' | translate }}</span>
      </rezolve-navbar-item>
    <rezolve-navbar-item *ngIf="(authService.isAuthenticated$ | async) === false" routerLink="/enrollment" (click)="d?.toggle()">
      <mat-icon svgIcon="logout"></mat-icon>
      <span>{{ 'ENROLL' | translate }}</span>
    </rezolve-navbar-item>
      <rezolve-navbar-item *ngIf="authService.isAuthenticated$ | async" (click)="logout()">
        <mat-icon svgIcon="logout"></mat-icon>
        <span>{{ 'LOG_OUT' | translate }}</span>
      </rezolve-navbar-item>
    </rezolve-navbar-section>
  </ng-template>
  <ng-template fxHide.gt-md #navbarToolbarContent>
    <rezolve-navbar-toolbar-content></rezolve-navbar-toolbar-content>
  </ng-template>
  <ng-template #navbarContent>
    <div class="router-outlet">
      <router-outlet></router-outlet>
    </div>
  </ng-template>
</rezolve-navbar>
