<div class="myDetails-spinner-wrapper"  *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
<div class="main-container">
  <div fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutGap="25px" *ngIf="accountDetails">
      <div
        class="details-container"
        fxLayout="column"
        fxLayout.gt-md="row"
        fxLayoutAlign="center center"
        fxLayoutAlign.gt-md="">
        <rezolve-base-container ngClass.gt-md="base-container" class="base-container-mobile">
          <div class="details-container">
            <div class="header">
              <h3 class="title">{{ 'MY_DETAILS' | translate }}</h3>
              <p class="subtitle">
                {{ 'YOUR_DETAILS_CAN_BE_CHANGED_BY_CONTACTING' | translate }}
                <a target="_blank" [href]="'SUPPORT_LINK.URL' | translate">{{ 'SUPPORT_LINK.TEXT' | translate }}</a>
              </p>
            </div>
            <rezolve-divider>{{ 'ACCOUNT_DETAILS' | translate | uppercase }}</rezolve-divider>
            <div class="details">
              <div class="data-view-container" fxLayout="row">
                <mat-label fxFlex="30%" class="label">{{ 'NAME' | translate }}</mat-label>
                <ng-content></ng-content>
                <mat-label fxFlex="70%" class="value" fxLayoutAlign="end">{{ accountDetails.name! }}</mat-label>
              </div>
              <div class="data-view-container" fxLayout="row">
                <mat-label fxFlex="30%" class="label">{{ 'EMAIL' | translate }}</mat-label>
                <ng-content></ng-content>
                <mat-label fxFlex="70%" class="value" fxLayoutAlign="end">{{ accountDetails.email! }}</mat-label>
              </div>
              <div class="data-view-container" fxLayout="row">
                <mat-label fxFlex="30%" class="label">{{ 'PASSWORD' | translate }}</mat-label>
                <ng-content></ng-content>
                <mat-label fxFlex="70%" class="value"> 
                  <mxp-change-password [accountDetailsId]="accountDetails.id"></mxp-change-password> 
                </mat-label>
              </div>
              <div *ngIf="accountDetails.partnerName" class="data-view-container" fxLayout="row">
                <mat-label fxFlex="30%" class="label">{{ 'PARTNER' | translate }}</mat-label>
                <ng-content></ng-content>
                <mat-label fxFlex="70%" class="value" fxLayoutAlign="end">{{ accountDetails.partnerName! }}</mat-label>
              </div>
              <div *ngIf="accountDetails.merchantName" class="data-view-container" fxLayout="row">
                <mat-label fxFlex="30%" class="label">{{ 'MERCHANT' | translate }}</mat-label>
                <ng-content></ng-content>
                <mat-label fxFlex="70%" class="value" fxLayoutAlign="end">{{ accountDetails.merchantName! }}</mat-label>
              </div>
              <div *ngIf="accountDetails.storeName" class="data-view-container" fxLayout="row">
                <mat-label fxFlex="30%" class="label">{{ 'STORE' | translate }}</mat-label>
                <ng-content></ng-content>
                <mat-label fxFlex="70%" class="value" fxLayoutAlign="end">{{ accountDetails.storeName.value! }}</mat-label>
              </div>
              <form [formGroup]="form" #accountDetailsUpdate="ngForm">
                <mat-label fxFlex="30%" class="label">{{ 'LANGUAGE' | translate }}</mat-label>
                <rezolve-drop-down fxFlex="70%" 
                  formControlName="language"
                  [placeholder]="'SELECT_LANGUAGE' | translate"
                  [items]="languages"
                  [optionTemplate]="languageOptionItems">
                  <ng-template #languageOptionItems let-item> {{ item.key | translate }}</ng-template>
                </rezolve-drop-down>
              </form>
            </div>
          </div>
        </rezolve-base-container>
  
        <rezolve-base-container ngClass.gt-md="base-container" class="base-container-mobile">
          <div class="details-container">
            <div class="header">
              <h3 class="title">{{ 'MEMBERSHIP' | translate }}</h3>
              <p class="roles-title">{{ 'YOU_BELONG_TO_THE_FOLLOWING_USER_GROUPS' | translate }}:</p>
            </div>
            <div *ngFor="let role of roleList">
              <rezolve-role
                [name]="role.name | translate"
                [description]="getRoleDescription(role.permissions)"
                [hideCheckbox]="true"></rezolve-role>
            </div>
          </div>
        </rezolve-base-container>
      </div>
  
      <div class="button-container" fxLayoutAlign="end">
        <rezolve-primary-button class="save-button" classList="extra-large full-width" (handleClick)="onSubmit()">
          {{ 'SAVE_CHANGES' | translate }}</rezolve-primary-button
        >
      </div>
    </div>
  </div>
</div>