<div class="container">
  <span class="title">
    {{ 'BULK_PAYMENTS' | translate }}
  </span>
  <span class="subtitle" style="font-style: italic">
    {{ 'BULK_PAYMENTS_SUBTITLE1' | translate }}
  </span>
  <span class="subtitle" style="font-style: italic">
    {{ 'BULK_PAYMENTS_SUBTITLE2' | translate }}
  </span>
  <div class="mt-5">
    <rezolve-basic-button (handleClick)="downloadInputTemplate()">{{ 'INPUT_TEMPLATE_BUTTON' | translate }}</rezolve-basic-button>
  </div>
  <div *ngIf="!error" [ngSwitch]="currentStatus">
    <div *ngSwitchCase="0" class="container">
      <div class="spinner-container" *ngIf="showSpinner"><mat-spinner></mat-spinner></div>
      <span class="info-validating-file" *ngIf="validatingFile">
        {{ 'VALIDATING_FILE' | translate }}
      </span>
      <span class="info" *ngIf="!showSpinner && !validatingFile ">
        {{ 'BULK_PAYMENTS_NO_FILES' | translate }}
      </span>
      <rezolve-upload class="space-up" *ngIf="!showSpinner"
        [dropzoneText]="dropzoneText"
        [configuration]="uploadConfiguration"
        (handleUploadErrors)="onUploadErrors($event)"
        (handleUploadedFile)="onUploadedFile($event)"></rezolve-upload>
    </div>
    <div *ngSwitchCase="1" class="container space">
      <div class="spinner-container" *ngIf="showSpinner"><mat-spinner></mat-spinner></div>
      <span class="info-validating-file" *ngIf="validatingFile">
        {{ 'VALIDATING_FILE' | translate }}
      </span>
      <div *ngIf="!showSpinner" class="button-container">
        <span>input.csv </span>
        <rezolve-primary-button (handleClick)="processFile()" style="margin-left:10px;">
          {{ 'PROCESS_FILE' | translate }}
        </rezolve-primary-button>
        <div>
          <rezolve-checkbox  (checkedChanged)="handleGenerateImages($event)">
            <span>
              {{'GENERATE_IMAGES' | translate }}
            </span>
          </rezolve-checkbox>
        </div>  
      </div>
      <rezolve-upload *ngIf="!showSpinner" class="space-up"
        [dropzoneText]="dropzoneText"
        [configuration]="uploadConfiguration"
        (handleUploadErrors)="onUploadErrors($event)"
        (handleUploadedFile)="onUploadedFile($event)"></rezolve-upload>
        <rezolve-secondary-button *ngIf="!showSpinner" (handleClick)="clean()" class="space">
          {{ 'CLEAN_FILES' | translate }}
        </rezolve-secondary-button>
    </div>
    <div *ngSwitchCase="2" class="container">
      <div class="spinner-container" *ngIf="showSpinner"><mat-spinner></mat-spinner></div>
      <span class="info" *ngIf="!showSpinner">
        {{ 'PROCESSING_INFORMATION' | translate }}
      </span>
      <div class="progress-bar-wrapper" *ngIf="!showSpinner">
        <span>Output.csv</span>
        <mat-progress-bar mode="determinate" [value]="progressBarTokensValue"></mat-progress-bar>
        <span class="progress-bar-info">
          {{ getCurrentTokensCountMessage() }}
        </span>
      </div>
      <div class="progress-bar-wrapper" *ngIf="!showSpinner && generateImagesStatus">
        <span>Images.zip</span>
        <mat-progress-bar mode="determinate" [value]="progressBarImagesValue"></mat-progress-bar>
        <span class="progress-bar-info">
          {{ getCurrentImagesCountMessage() }}
        </span>
      </div>
      <rezolve-secondary-button (handleClick)="refresh()" *ngIf="!showSpinner">
        {{ 'REFRESH' | translate }}
      </rezolve-secondary-button>
      <rezolve-secondary-button class="space-up" (handleClick)="cancel()" *ngIf="!showSpinner">
        {{ 'CANCEL' | translate }}
      </rezolve-secondary-button>
    </div>
    <div *ngSwitchCase="3" class="container">
      <div class="spinner-container" *ngIf="showSpinner"><mat-spinner></mat-spinner></div>
      <span class="info" *ngIf="!showSpinner">
        {{ 'PROCESSING_INFORMATION' | translate }}
      </span>
      <div class="progress-bar-wrapper" *ngIf="!showSpinner">
        <span>Output.csv</span>
        <mat-progress-bar mode="determinate" [value]="progressBarTokensValue"></mat-progress-bar>
        <span class="progress-bar-info">
          {{ getCurrentTokensCountMessage() }}
        </span>
      </div>
      <div class="progress-bar-wrapper" *ngIf="!showSpinner && generateImagesStatus">
        <span>Images.zip</span>
        <mat-progress-bar mode="determinate" [value]="progressBarImagesValue"></mat-progress-bar>
        <span class="progress-bar-info">
          {{ getCurrentImagesCountMessage() }}
        </span>
      </div>
      <rezolve-secondary-button (handleClick)="refresh()" *ngIf="!showSpinner">
        {{ 'REFRESH' | translate }}
      </rezolve-secondary-button>
      <rezolve-secondary-button class="space-up" (handleClick)="cancel()" *ngIf="!showSpinner">
        {{ 'CANCEL' | translate }}
      </rezolve-secondary-button>
    </div>
    <div *ngSwitchCase="4" class="container" style="margin-top: 15px;">
      <div class="spinner-container" *ngIf="showSpinner"><mat-spinner></mat-spinner></div>
      <span class="info" *ngIf="!showSpinner">
        {{ 'DOWNLOAD_FILE_INFORMATION' | translate }}
      </span>
      <div class="button-container" *ngIf="!showSpinner && isQrCodesAvailable">
        <span>output.csv</span>
        <rezolve-primary-button (handleClick)="downloadQrCodes()" style="margin-left:10px;">
          {{ 'DOWNLOAD_FILE' | translate }}
        </rezolve-primary-button>
      </div>
      <div class="button-container mt-5" *ngIf="!showSpinner && isQrImagesAvailable">
        <span>images.zip</span>
        <rezolve-primary-button (handleClick)="downloadQrImages()" style="margin-left:10px;">
          {{ 'DOWNLOAD_FILE' | translate }}
        </rezolve-primary-button>
      </div>
      <rezolve-upload class="space-up" *ngIf="!showSpinner"
        [dropzoneText]="dropzoneText"
        [configuration]="uploadConfiguration"
        (handleUploadErrors)="onUploadErrors($event)"
        (handleUploadedFile)="onUploadedFile($event)"></rezolve-upload>
      <rezolve-secondary-button (handleClick)="clean()" class="space" *ngIf="!showSpinner">
        {{ 'CLEAN_FILES' | translate }}
      </rezolve-secondary-button>
    </div>
    <div *ngSwitchCase="5" class="container">
      <div class="spinner-container" *ngIf="showSpinner"><mat-spinner></mat-spinner></div>
      <span class="info">
        {{ 'PROCESSING_FILE_ERROR' | translate }}
      </span>
      <rezolve-upload class="space-up" *ngIf="!showSpinner"
        [dropzoneText]="dropzoneText"
        [configuration]="uploadConfiguration"
        (handleUploadErrors)="onUploadErrors($event)"
        (handleUploadedFile)="onUploadedFile($event)"></rezolve-upload>
        <rezolve-secondary-button (handleClick)="clean()" class="space" *ngIf="!showSpinner">
          {{ 'CLEAN_FILES' | translate }}
        </rezolve-secondary-button>
    </div>
  <div>
    
    <span class="error-message-container" *ngIf="errorLines?.length">

      <h3>{{errorLines.length}} {{'ERRORS_FOUND' | translate }}</h3>
      <p *ngFor="let err of errorLines.slice(0,10)">
        {{"LINE" | translate }} {{err.lineNumber}} - {{getErrorMessageTranslation(err.errorType!) | translate}}
      </p> 
      <p *ngIf="errorLines.length > 10">
        Etc...
      </p> 
    </span>
    <div *ngSwitchDefault class="spinner-container"><mat-spinner></mat-spinner></div>
  </div>
</div>