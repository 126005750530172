import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface MonthRangePickerOutPut {
  startDate: Date | undefined;
  endDate: Date | undefined;
  monthRangeString: string;
}

@Component({
  selector: 'mxp-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
})
export class MonthPickerComponent implements OnInit {
  @Output() monthRangeSelected = new EventEmitter<MonthRangePickerOutPut>();

  @Input() startDate!: Date;
  @Input() endDate!: Date;
  @Input() requiredMonthRangeError!: string;
  @Input() enableMonthNavigation: boolean = false;
  @Input() labelText! : string ;
  @Input() applyButtonText : string = "Apply"; 
  hasError: boolean = false;
  monthRangeOutput: MonthRangePickerOutPut = {
    startDate: undefined,
    endDate: undefined,
    monthRangeString: '',
  };
  monthRangeValueString: string = '';
  showMonthsPanel: boolean = false;
  currentYearIndex!: number;
  years!: Array<number>;
  months!: Array<string>;
  monthsData!: Array<{
    monthIndex: number;
    monthName: string;
    monthYear: number;
    isInRange: boolean;
    isLowerEdge: boolean;
    isUpperEdge: boolean;
    daysOfMonth: number;
  }>;
  rangeIndexes!: Array<number>;
  monthViewSlicesIndexes!: Array<number>;
  monthDataSlice!: Array<{
    monthIndex: number;
    monthName: string;
    monthYear: number;
    isInRange: boolean;
    isLowerEdge: boolean;
    isUpperEdge: boolean;
    daysOfMonth: number;
  }>;
  globalIndexOffset!: number;
  initialSet: boolean = true;

  onMonthItemClicked(index:number) {
    this.initialSet = false;
    this.handleMonthSliceData(index);
  }

  handleMonthSliceData(indexClicked: any) {
    this.hasError = false;
    if(this.rangeIndexes[0] === -1 && this.rangeIndexes[1] === -1) {
      this.rangeIndexes[0] = this.globalIndexOffset + indexClicked;
      this.rangeIndexes[1] = this.globalIndexOffset + indexClicked;
    }
    else if (this.rangeIndexes[0] === -1) {
      this.rangeIndexes[0] = this.globalIndexOffset + indexClicked;
    } 
    else if (this.rangeIndexes[1] === -1) {
      this.rangeIndexes[1] = this.globalIndexOffset + indexClicked;
      this.rangeIndexes.sort((a, b) => a - b);
      this.monthsData.forEach((month, index) => {
        if (this.rangeIndexes[0] <= index && index <= this.rangeIndexes[1]) {
          month.isInRange = true;
        }
        if (this.rangeIndexes[0] === index) {
          month.isLowerEdge = true;
        }
        if (this.rangeIndexes[1] === index) {
          month.isUpperEdge = true;
        }
      });
    } 
    else {
      this.initRangeIndexes();
      this.initMonthsData();
      this.handleMonthSliceData(indexClicked);
      this.sliceDataIntoView();
    }
  }

  initApplyChanges() {
    if (this.startDate !== undefined && this.endDate !== undefined) {
      const month1 = this.monthDataSlice.findIndex((m) => {
        return m.monthYear === this.startDate.getFullYear() && m.monthIndex === this.startDate.getMonth();
      });
      const month2 = this.monthDataSlice.findIndex((m) => {
        return m.monthYear === this.endDate.getFullYear() && m.monthIndex === this.endDate.getMonth();
      });

      if (month1 !== undefined && month2 !== undefined) {
        this.handleMonthSliceData(month1);
        this.handleMonthSliceData(month2);
        this.applyDates();
      }
    }
  }

  applyDates() {
    let fromMonthYear = this.monthsData[this.rangeIndexes[0]];
    let toMonthYear = this.monthsData[this.rangeIndexes[1]];
    if (fromMonthYear !== undefined && toMonthYear !== undefined) {
      this.monthRangeValueString = `${fromMonthYear.monthName}/${fromMonthYear.monthYear}`;
      this.monthRangeOutput = {
        startDate: new Date(fromMonthYear.monthYear, fromMonthYear.monthIndex, 1, 0, 0, 0),
        endDate: new Date(toMonthYear.monthYear, toMonthYear.monthIndex, toMonthYear.daysOfMonth, 23, 59, 59),
        monthRangeString: this.monthRangeValueString
      };
      this.showMonthsPanel = false;
      this.emitData(this.monthRangeOutput);
    } else {
      this.hasError = true;
    }
  }

  emitData(string: MonthRangePickerOutPut) {
    if(!this.initialSet){
      this.monthRangeSelected.emit(string);
    }
  }

  sliceDataIntoView() {
    this.globalIndexOffset = this.monthViewSlicesIndexes[this.currentYearIndex];
    this.monthDataSlice = this.monthsData.slice(this.globalIndexOffset, this.globalIndexOffset + 24);
  }

  incrementYear() {
    if (this.currentYearIndex !== this.years.length - 1) {
      this.currentYearIndex++;
      this.sliceDataIntoView();
    }
  }

  decrementYear() {
    if (this.currentYearIndex !== 0) {
      this.currentYearIndex--;
      this.sliceDataIntoView();
    }
  }

  initRangeIndexes(upper: number = -1, lower: number = -1) {
    this.rangeIndexes = [upper, lower];
  }

  initUpperLower() {
    let upper: number = -1;
    let lower: number = -1;
    if (this.startDate && this.endDate) {
      const resUpper = this.monthDataSlice.find((m) => {
        return m.monthIndex === this.startDate.getMonth();
      });
      const resLower = this.monthDataSlice.find((m) => {
        return m.monthIndex === this.endDate.getMonth();
      });
      lower = resLower === undefined ? -1 : resLower.monthYear;
      upper = resUpper === undefined ? -1 : resUpper.monthYear;
    }
    this.initRangeIndexes(upper, lower);
  }

  initMonthsData() {
    this.monthsData = new Array();
    this.years.forEach((year: number) => {
      this.months.forEach((month: string, index: number) => {
        this.monthsData.push({
          monthIndex: index,
          monthName: month,
          monthYear: year,
          isInRange: false,
          isLowerEdge: false,
          isUpperEdge: false,
          daysOfMonth: this.getDaysInMonth(index, year),
        });
      });
    });
  }

  getDaysInMonth(month: number, year: number): number {
    return new Date(year, month + 1, 0).getDate();
  }

  initYearLabels() {
    const currentYear = new Date().getFullYear();
    const range = (start: any, stop: any, step: any) =>
      Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
    this.years = range(currentYear - 10, currentYear + 3, 1);
  }

  initMonthLabels() {
    this.months = new Array(12).fill(0).map((_, i) => {
      return new Date(`${i + 1}/1/01`).toLocaleDateString(undefined, {
        month: 'short',
      });
    });
  }

  initViewSlices() {
    this.monthViewSlicesIndexes = [];
    this.years.forEach((year, index) => {
      if (index === 0) {
        this.monthViewSlicesIndexes.push(0);
      } else if (index === 1) {
        this.monthViewSlicesIndexes.push(6);
      } else this.monthViewSlicesIndexes.push(this.monthViewSlicesIndexes[index - 1] + 12);
    });
  }

  ngOnInit() {
    this.initYearLabels();
    this.initMonthLabels();
    this.initViewSlices();
    this.initMonthsData();
    this.initRangeIndexes();
    this.currentYearIndex = this.years.findIndex((year) => year === new Date().getFullYear());
    this.sliceDataIntoView();
    this.initUpperLower();
    this.initApplyChanges();
  }

  handleOpenPanelClick(event: any, inputControl: any) {
    this.showMonthsPanel = !this.showMonthsPanel;
  }

  prevStartMonth() {
    let startIndex = this.rangeIndexes[0];
    startIndex -= 1;
    this.rangeIndexes[0] = startIndex;
    this.onMonthItemClicked(Math.abs(startIndex - this.globalIndexOffset));
    this.applyDates();
  }

  nextEndMonth() {
    let endIndex = this.rangeIndexes[1];
    endIndex += 1;
    this.rangeIndexes[1] = endIndex;
    this.onMonthItemClicked(Math.abs(endIndex - this.globalIndexOffset));
    this.applyDates();
  }
}