import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, Optional, Self, ViewEncapsulation } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { BasicInputComponent } from '../basic-input/basic-input.component';

@Component({
  selector: 'rezolve-email-input',
  templateUrl: '../basic-input/basic-input.component.html',
  styleUrls: ['../basic-input/basic-input.component.scss', '../../../styles/common.styles.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: EmailInputComponent }],
  encapsulation: ViewEncapsulation.None,
})
export class EmailInputComponent extends BasicInputComponent<string> {
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    focusMonitor: FocusMonitor,
    elRef: ElementRef<HTMLElement>,
  ) {
    super(ngControl, focusMonitor, elRef);
    this.type = 'email';
  }
}
