import { createReducer, on, Action } from '@ngrx/store';
import {
  displayMyProfile,
  displayReports,
  displayUsers,
  manageStores,
  notificationPaymentSent,
  qrPaymentSent,
  requestPayment,
  setLoaded,
  setLoading,
} from '../actions/app.actions';
import { ICurrentWorkflow, initialAppState } from '../app.interface';

export const workFlowReducer = createReducer(
  initialAppState,
  on(qrPaymentSent, (state) => ({
    ...state,
    currentStep: 'SCAN_QR_CODE',
  })),
  on(notificationPaymentSent, (state) => ({
    ...state,
    currentStep: 'PAYMENT_NOTIFICATION_SENT',
  })),
  on(requestPayment, (state) => ({
    ...state,
    currentStep: 'REQUEST_PAYMENT',
  })),
  on(manageStores, (state) => ({
    ...state,
    currentStep: 'MANAGE_STORES',
  })),
  on(displayUsers, (state) => ({
    ...state,
    currentStep: 'USERS',
  })),
  on(displayReports, (state) => ({
    ...state,
    currentStep: 'REPORTS',
  })),
  on(displayMyProfile, (state) => ({
    ...state,
    currentStep: 'MY_PROFILE',
  })),
  on(setLoading, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(setLoaded, (state) => ({
    ...state,
    isLoading: false,
  })),
);

/* eslint-disable default-param-last */
export function AppReducer(state = initialAppState, action: Action): ICurrentWorkflow {
  return workFlowReducer(state, action);
}
