<div class="store-container">
  <div class="store-header">
    <span class="store-name">{{ store?.name }}</span>
    <div class="store-actions">
      <mat-icon
        *ngIf="canEditStoreAndEditCodeAccount$ | async" 
        class="store-edit-icon" 
        (click)="editStoreClicked()">edit</mat-icon>
      <mat-icon
        *ngIf="canDeleteStore$ | async"
        class="store-edit-icon"
        (click)="deleteStoreClicked()">delete</mat-icon>
    </div>
  </div>
  <div class="store-subtitle">
    <span> {{ getAddress() }}</span>
  </div>
  <div class="codi-section" *ngIf="!!store.pspId">
    <rezolve-drop-down
      class=""
      [label]="'ACCOUNT_TYPE' | translate"
      [items]="accountTypes"
      [value]="accountType"
      [disabled]="true"
      [optionTemplate]="translatedItem">
    </rezolve-drop-down>
    <mat-icon
      *ngIf="canEditStoreAndEditCodeAccount$ | async"
      class="psp-edit-icon"
      (click)="editCodiAccount()">
      edit
    </mat-icon>
  </div>
  <div *ngIf="!store.pspId" class="action-section">
    <rezolve-primary-button (click)="createNewCodiAccount()">
      {{ 'CONFIGURE_NEW_CODI_ACCOUNT' | translate }}</rezolve-primary-button
    >
  </div>
</div>
<ng-template #translatedItem let-item>{{ item.key | translate }}</ng-template>
