import { Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { displayUsers } from '../../../core/store/actions/app.actions';
import { IAppState } from '../../../core/store/app.interface';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserCreateComponent } from '../user-create/user-create.component';
import { UsersTableComponent } from './users-table/users-table.component';

@Component({
  selector: 'mxp-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
  private readonly _defaultQuery = {
    createdOnDate: { start: new Date(), end: new Date() },
    interactedOnDate: { start: new Date(), end: new Date() },
    status: '',
    searchTerm: '',
    showSnackbar: true,
  };
  tableConfiguration = {
    searchFilter: {},
    sortDirection: 'desc' as const,
    pageIndex: 0,
    pageSize: 10,
    sortField: 'createdOnFixed.keyword',
  };
  pageSizeOptions = [2, 5, 10, 20];

  @ViewChild(UsersTableComponent) usersTable!: UsersTableComponent;

  constructor(private readonly store: Store<IAppState>, private dialog: MatDialog) {
    this.store.dispatch(displayUsers());
  }

  newUserClicked = () => {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '600px';

    this.dialog
      .open(UserCreateComponent, dialogConfig)
      .afterClosed()
      .subscribe((isCreated: boolean) => {
        if (isCreated) {
          this.refreshUsersTable(true);
        }
      });
  };

  refreshUsersTable(userActionCompleted: boolean) {
    if (userActionCompleted) {
      let showSnackbar = false;
      this.usersTable.query.next({ ...Object.assign(this._defaultQuery, { showSnackbar }) });
    }else{
      this.usersTable.query.next({ ...this._defaultQuery });
    }
  }

  applySearchTerm(searchTerm: string) {
    searchTerm = searchTerm ? searchTerm.trim() : '';
    this.usersTable.query.next({
      ...Object.assign(this._defaultQuery, { searchTerm }),
    });
  }
}
