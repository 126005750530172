<mxp-payment-sent [configuration]="paymentSentConfiguration">
  <img class="qr-image" [src]="imgUrl" alt="{{ 'QR_CODE' | translate }}" />
  <h2 class="configuration-price" *ngIf="paymentSentConfiguration?.paymentConfiguration?.price">
    <strong>{{ paymentSentConfiguration?.paymentConfiguration?.price | currency: 'MXN' }}</strong>
  </h2>
  <p *ngIf="paymentSentConfiguration?.paymentConfiguration?.messageIdentifier" class="payment-additional-info">
    {{ 'COLLECTION_MESSAGE_IDENTIFIER' | translate }}
    {{ paymentSentConfiguration?.paymentConfiguration?.messageIdentifier }}
  </p>
  <p class="payment-additional-info">{{ paymentSentConfiguration?.paymentConfiguration?.description }}</p>
  <p class="payment-additional-info">{{ 'PAYMENT_EXPIRES' | translate }} {{ expiryDate | translate }}</p>
  <a [href]="pngUrl" class="centered-row qr-link" [download]="qrCodePngName">
    <mat-icon class="mat-icon" svgIcon="download"></mat-icon>
    <strong>{{ 'SAVE_AS_PNG' | translate }}</strong>
  </a>
</mxp-payment-sent>
