<rezolve-base-card toggleTextShow="{{ 'SEE_STORES' | translate }}" toggleTextHide="{{ 'HIDE_STORES' | translate }}">
  <div header class="account-header">
    <div class="header-line">
      <!--      <h3>{{ psp }}</h3>-->
      <rezolve-toggle-control class="margin-left-auto" (change)="onEnableChange()"></rezolve-toggle-control>
    </div>
    <!--    <p class="additional-information">{{ psp.pspId }}</p>-->
  </div>
  <div body>
    <!--    <div class="stores-container">-->
    <!--      <ng-container *ngFor="let store of psp.stores">-->
    <!--        <mxp-store [store]="store"></mxp-store>-->
    <!--      </ng-container>-->
    <!--    </div>-->
    <div class="add-store" (click)="onAddStore()">
      <mat-icon class="mat-icon" svgIcon="plus"></mat-icon>{{ 'NEW_STORE' | translate }}
    </div>
  </div>
</rezolve-base-card>
