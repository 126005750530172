export const esTranslations = {
  TODAY: 'Hoy',
  YESTERDAY: 'Ayer',
  PAST_MONTH: 'Mes anterior',
  PAST_THREE_MONTHS: 'Últimos 3 meses',
  PAST_SIX_MONTHS: 'Últimos 6 meses',
  ALWAYS: 'Siempre',
  APPLY: 'Aplicar',
  FROM: 'Desde',
  TO: 'Hasta'
};
