<mat-form-field class="checkbox-container" [attr.data-cy]="generateDataCyId('-input-form-field')">
  <input matInput type="text" [hidden]="true" />
  <span>
    <mat-checkbox
      class="rezolve-checkbox"
      #model="ngModel"
      [(ngModel)]="value"
      [required]="required"
      [disabled]="disabled"
      [checked]="checked"
      color="primary"
      [indeterminate]="indeterminate"
      (ngModelChange)="onNgModelChange(model)"
      ><ng-content></ng-content>
    </mat-checkbox>
    <mat-error *ngIf="errorState">
      {{ errorMessage }}
    </mat-error>
  </span>
</mat-form-field>
