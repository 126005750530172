import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { NamedComponent } from './named-component';

@Directive()
export abstract class BasicButtonDirective extends NamedComponent {
  @Input() type = '';
  @Input() disabled = false;
  @Input() classList = 'primary medium';
  @Input() prefixIcon?: string = '';
  @Input() suffixIcon?: string = '';
  buttonClass = ``;
  @Output() handleClick = new EventEmitter<MouseEvent>();

  onClick($event: MouseEvent) {
    this.handleClick.emit($event);
  }
}
