import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { NamedComponent } from './named-component';

@Directive()
export abstract class BasicListItem<T> extends NamedComponent {
  @Input() item!: T;
  @Input() isSelected = false;
  @Input() showRemove = false;
  @Input() showEdit = false;

  @Output() selected = new EventEmitter<T>();
  @Output() unselected = new EventEmitter<T>();
  @Output() remove = new EventEmitter<T>();
  @Output() edit = new EventEmitter<T>();

  onClick(): void {
    if (!this.isSelected) {
      this.selected.emit(this.item);
    } else {
      this.unselected.emit(this.item);
    }
  }

  onRemove(event: Event): void {
    event.stopPropagation();
    this.remove.emit(this.item);
  }

  onEdit(event: Event): void {
    event.stopPropagation();
    this.edit.emit(this.item);
  }
}
