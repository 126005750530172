import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NamedComponent } from '../../abstract/named-component';
import { ListViewComponent } from '../../atoms/list-view/list-view.component';

@Component({
  selector: 'rezolve-basic-list',
  templateUrl: './basic-list.component.html',
  styleUrls: ['./basic-list.component.scss'],
})
export class BasicListComponent<T> extends NamedComponent {
  private _selectedItems: T[] = [];
  @Output() selectionChanged = new EventEmitter<T[]>();
  @ContentChild('item', { static: false })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  template: TemplateRef<any> | null = null;
  @ViewChild('listView') listView?: ListViewComponent<T>;
  @Input() items: T[] = [];
  @Input() showPagination = false;
  @Input() allowMultiSelection = false;
  @Input() itemsPerPage = 5;
  @Input() currentPage = 0;
  @Input() pageSizeOptions: number[] = [5, 10, 15];
  @Input() areEqual: (itemA: T, itemB: T) => boolean = (itemA: T, itemB: T) => itemA == itemB;
  @Input() set selectedItems(selectedItems: T[]) {
    this._selectedItems = this.filterSelectedItems(selectedItems);
  }

  get selectedItems(): T[] {
    return this._selectedItems;
  }

  constructor() {
    super();
    this.componentName = 'basic-list';
  }

  handlePageChanged = (page: number) => {
    this.currentPage = page;
  };

  handlePageSizeChanged = (pageSize: number) => {
    this.itemsPerPage = pageSize;
  };

  handleItemSelected = (item: T) => {
    if (this.allowMultiSelection) {
      if (!this.isElementOfList(item, this.selectedItems)) {
        this.selectedItems = [...this.selectedItems, item];
      }
    } else {
      this.selectedItems = [item];
    }
    this.selectionChanged.emit(this.selectedItems);
  };

  handleItemUnselected = (item: T) => {
    if (this.allowMultiSelection) {
      this.selectedItems = this.selectedItems.filter((selected: T) => {
        return !this.areEqual(selected, item);
      });
    } else {
      this.selectedItems = [];
    }
    this.selectionChanged.emit(this.selectedItems);
  };

  handleItemRemoved = (item: T) => {
    this.items = this.items.filter((selected: T) => {
      return !this.areEqual(selected, item);
    });
  };

  isSelected(item: T): boolean {
    return this.isElementOfList(item, this.selectedItems);
  }

  private filterSelectedItems(selectedItems: T[]): T[] {
    const items = selectedItems.filter((item: T) => {
      return this.isElementOfList(item, this.items);
    });
    return this.allowMultiSelection ? items : items.slice(0, 1);
  }

  private isElementOfList(item: T, list: T[]): boolean {
    for (const value of list) {
      if (this.areEqual(value, item)) {
        return true;
      }
    }
    return false;
  }
}
