import { Component, OnInit } from '@angular/core';
import { BasicButtonDirective } from '../../abstract/basic-button';

@Component({
  selector: 'rezolve-tertiary-button',
  templateUrl: './tertiary-button.component.html',
  styleUrls: ['./tertiary-button.component.scss'],
})
export class TertiaryButtonComponent extends BasicButtonDirective implements OnInit {
  ngOnInit(): void {
    this.buttonClass = `tertiary ${this.classList}`;
  }
}
