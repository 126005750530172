<div class="container">
  <img alt="payment-complete" [src]="paymentIcon" />
  <span class="title">
    {{ title | translate }}
  </span>
  <span class="subtitle">
    {{ subtitle | translate }}
  </span>
  <rezolve-primary-button (handleClick)="requestNewPayment()">
    {{ 'REQUEST_NEW_PAYMENT' | translate }}
  </rezolve-primary-button>
</div>
