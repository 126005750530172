/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component } from '@angular/core';
import { BaseTableComponent } from '../../../../../../../libs/ui/src/lib/components/atoms/base-table/base-table.component';
import { Column } from '../../../../../../../libs/ui/src/lib/interfaces/base-table/column';
import { BaseDataSource } from '../../../../../../../libs/ui/src/lib/components/atoms/base-table/base-data-source';
import { ColumnTypeEnum } from '../../../../../../../libs/ui/src/lib/enums/column-type-enum';
import { ReportsTableData, ReportsTableService } from './reports-table.service';
import { BaseTableParams } from '../../../../../../../libs/ui/src/lib/interfaces/base-table/base-table-params';
import { TranslateService } from '@ngx-translate/core';
import { PaymentStatusEnum, PaymentTypeEnum } from '@rezolved/mxp-proto';

@Component({
  selector: 'mxp-reports-table',
  templateUrl: '../../../../../../../libs/ui/src/lib/components/atoms/base-table/base-table.component.html',
  styleUrls: ['../../../../../../../libs/ui/src/lib/components/atoms/base-table/base-table.component.scss'],
})
export class ReportsTableComponent extends BaseTableComponent<ReportsTableData, PaymentStatusEnum,PaymentTypeEnum> {
  tableColumns: Column[] = [];

  defaultParams: BaseTableParams = {
    searchFilter: {},
    sortDirection: 'desc',
    pageIndex: 0,
    pageSize: 10,
    sortField: 'createdOn',
  };

  columnsData = [
    {
      key: 'timestamp',
      displayName: this.translate.instant('CREATED_ON'),
      type: ColumnTypeEnum.Text,
      sortField: 'createdOn',
      columnWidthPercentage:'10'
    },
    {
      key: 'interactedOnTimeStamp',
      displayName: this.translate.instant('INTERACTED_ON'),
      type: ColumnTypeEnum.Text,
      sortField: 'interactedOn',
      columnWidthPercentage:'8'
    },
    {
      key: 'description',
      displayName: this.translate.instant('DESCRIPTION'),
      type: ColumnTypeEnum.TextWithHover,
      sortField: 'description.keyword',
      columnWidthPercentage:'16'
    },
    {
      key: 'reference',
      displayName: this.translate.instant('REFERENCE'),
      type: ColumnTypeEnum.TextWithHover,
      sortField: 'reference.keyword',
      columnWidthPercentage:'8'
    },
    {
      key: 'extendedReference',
      displayName: this.translate.instant('EXTENDED_REFERENCE'),
      type: ColumnTypeEnum.TextWithHover,
      sortField: 'extendedReference.keyword',
      columnWidthPercentage:'18'
    },
    //{
    //  key: 'phone',
    //  displayName: `${this.translate.instant('PHONE')}`,
    //  type: ColumnTypeEnum.Text,
    //  sortField: 'phone.keyword',
    //  columnWidthPercentage:'10'
    //},
    {
      key: 'contactInfo',
      displayName: `${this.translate.instant('CONTACT_INFO')}`,
      type: ColumnTypeEnum.Text,
      sortField: 'contactInfo.keyword',
      columnWidthPercentage:'16'
    },
    {
      key: 'transactionId',
      displayName: this.translate.instant('FOLIO_CODI'),
      type: ColumnTypeEnum.Text,
      sortField: 'transactionId.keyword',
      columnWidthPercentage:'13'
    },
    //{
    //  key: 'userName',
    //  displayName: this.translate.instant('USERNAME'),
    //  type: ColumnTypeEnum.Text,
    //  sortField: 'userName.keyword',
    //  columnWidthPercentage:'10'
    //},
    {
      key: 'status',
      displayName: this.translate.instant('STATUS'),
      type: ColumnTypeEnum.Text,
      sortField: 'status',
      columnWidthPercentage:'10'
    },
    {
      key: 'type',
      displayName: this.translate.instant('TYPE'),
      type: ColumnTypeEnum.Text,
      sortField: 'type.keyword',
      columnWidthPercentage:'10'
    },
    {
      key: 'total',
      displayName: this.translate.instant('TOTAL'),
      type: ColumnTypeEnum.Text,
      sortField: 'amount',
      columnWidthPercentage:'10'
    }
  ];

  constructor(private translate: TranslateService, reportsTableService: ReportsTableService) {
    super(new BaseDataSource<ReportsTableData>(reportsTableService));
    this.tableColumns = this.columnsData;
  }
}
