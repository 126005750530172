import { Injectable, InjectionToken } from '@angular/core';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { Auth0ClientConfig } from './auth0.config';

@Injectable({
  providedIn: 'root',
})
export class Auth0ClientFactory {
  static createClient(configFactory: Auth0ClientConfig): Auth0Client {
    const config = configFactory.get();

    if (!config) {
      throw new Error(
        'Configuration must be specified either through AuthModule.forRoot or through AuthClientConfig.set',
      );
    }

    const { redirectUri, clientId, domain, audience, useRefreshTokens, organization, ...rest } = config;

    return new Auth0Client({
      domain: domain,
      client_id: clientId,
      redirect_uri: redirectUri,
      audience: audience,
      useRefreshTokens: useRefreshTokens || true,
      ...(organization && {
        organization: organization,
      }),
      ...rest,
    });
  }
}

export const Auth0ClientService = new InjectionToken<Auth0Client>('auth0.client');
