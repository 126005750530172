import { RoleEnum } from '@rezolved/mxp-proto';
import { PermissionTypeEnum } from '../enums/permission-type.enum';

export const UserGroups = {
  BASIC: 'basic',
  ADMIN: 'admin',
};

export const Auth0MxpUserRoles = {
  MXP_PARTNER: 'MXP_PARTNER',
  MXP_POWER_ADMIN: 'MXP_POWER_ADMIN',
  MXP_POWER_USER: 'MXP_POWER_USER',
  MXP_STORE_ADMIN: 'MXP_STORE_ADMIN',
  MXP_USER: 'MXP_USER',
  MXP_REPORT_USER: 'MXP_REPORT_USER',
  MXP_SUPER_ADMIN : 'MXP_SUPER_ADMIN'
};

export function authUserRolesToEnumUserRoles(authRoles: string[]): RoleEnum[] {
  return authRoles.map((role) => {
    switch (role) {
      case Auth0MxpUserRoles.MXP_POWER_ADMIN:
        return RoleEnum.POWER_ADMIN;
      case Auth0MxpUserRoles.MXP_POWER_USER:
        return RoleEnum.POWER_USERS;
      case Auth0MxpUserRoles.MXP_STORE_ADMIN:
        return RoleEnum.STORE_ADMIN;
      case Auth0MxpUserRoles.MXP_USER:
        return RoleEnum.USER;
      case Auth0MxpUserRoles.MXP_REPORT_USER:
        return RoleEnum.REPORT_USER;
      case Auth0MxpUserRoles.MXP_PARTNER:
        return RoleEnum.PARTNER
      default:
        return RoleEnum.USER;
    }
  });
}

export const UserRoles = [
  {
    id: RoleEnum.POWER_ADMIN,
    permissions: [
      PermissionTypeEnum.MANAGE_USERS,
      PermissionTypeEnum.MANAGE_STORES,
      PermissionTypeEnum.REPORTS,
      PermissionTypeEnum.MAKE_PAYMENTS,
      PermissionTypeEnum.READ_BILLINGS
    ],
    name: 'POWER_ADMIN',
  },
  {
    id: RoleEnum.USER,
    permissions: [PermissionTypeEnum.MAKE_PAYMENTS],
    name: 'USER',
  },
  {
    id: RoleEnum.POWER_USERS,
    permissions: [PermissionTypeEnum.MAKE_PAYMENTS, PermissionTypeEnum.REPORTS],
    name: 'POWER_USERS',
  },
  {
    id: RoleEnum.STORE_ADMIN,
    permissions: [PermissionTypeEnum.MANAGE_STORES, PermissionTypeEnum.REPORTS, PermissionTypeEnum.MAKE_PAYMENTS],
    name: 'STORE_ADMIN',
  },
  {
    id: RoleEnum.REPORT_USER,
    permissions: [PermissionTypeEnum.REPORTS],
    name: "REPORT_USER"
  },
  {
    id: RoleEnum.PARTNER,
    permissions: [PermissionTypeEnum.MANAGE_STORES,PermissionTypeEnum.MANAGE_USERS,PermissionTypeEnum.READ_BILLINGS],
    name: "PARTNER"
  }
];
