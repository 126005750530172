// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authSettings: {
    domain: 'rezolve-dev.eu.auth0.com',
    clientId: 'hbk4XoelWnoPjcajs2gSsI8rSeWj8Em9',
    audience: 'https://rezolve.mx-payments.api',
    redirectUri: 'https://mxp.dev.eu2.rezolve.com/',
  },
  bulkPaymentDisabled: true,
  defaultLang: 'es',
  paymentUrl: 'https://payments.dev.eu2.rezolve.com',
  identityUrl: 'https://identity.dev.eu2.rezolve.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
