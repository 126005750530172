<div class="main-container">
    <label for="signature-pad" class="{{ (isSignEmpty || !isSignatureValid) ? 'sign-label-error' : 'sign-label' }}">
        <mat-label class="ng-start-inserted mat-form-field">
            {{'SIGNATURE' |translate}}
        </mat-label>
        <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker ng-star-inserted"> *</span>
    </label>
    <div class="wrapper" class="{{ (isSignEmpty || !isSignatureValid) ? 'wrapper-error-border' : 'wrapper-border' }}">
        <canvas #canvas id="signature-pad" class="signature-pad" [attr.width]="width" [attr.height]="height"></canvas>
    </div>
    <div class="actionButtons">
        <rezolve-secondary-button (handleClick)="clear(); $event.preventDefault()" >
            {{ 'CLEAN_FILES' | translate }}
          </rezolve-secondary-button>
    </div>
    <div class="errorDiv">
        <div class="subtitle" *ngIf="isSignEmpty; then empty else non_empty_invalid">
        </div>
        <ng-template #empty>
            <span class="mat-error">{{'SIGNATURE_IS_REQUIRED_FIELD' | translate}}</span>
        </ng-template>
        <ng-template #non_empty_invalid>
            <span class="mat-error" *ngIf="!isSignatureValid">{{'SIGNATURE_IS_NOT_VALID' | translate}} </span>
        </ng-template>
    </div>
</div>