<div class="main-container">
    <div class="billing-header">
        <h1 class="billing-subtitle">{{ 'BILLING'| translate}}</h1>
    </div>
    <div>
        <mat-card class="billig-content" *ngIf="!hasError" fxLayout="column" >
            <div class="input-row" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" >
                <div fxFlex="100" fxLayout="column" class="sec1" fxLayoutGap="5px" fxFlex.xs="50" *ngIf="hasAccessToPartnerDdl">
                    <div fxFlex="100" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="flex-start space-evenly" >
                        <div class="partner" >
                            <h2 class="partner-ddl-header">{{ 'PARTNER'| translate}}</h2>
                            <mat-form-field appearance="standard" class="partner-ddl rezolve-form-field">
                                <mat-label>{{ 'SELECT_PARTNER' | translate }}</mat-label>
                                <mat-select (selectionChange)="handlePartnerChange($event.value)"
                                    panelClass="bottom-panel-position" disableOptionCentering>
                                    <mat-option *ngFor="let partner of partnersList" [value]="partner.id">
                                        {{partner.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div fxFlex="50" class="sec2" fxLayoutGap="5px" fxLayout="column">
                    <div  fxLayout="column" fxLayout.xs="column"  fxFlex="50" fxFlex.xs="100"
                        fxLayoutAlign="space-evenly" fxLayoutAlign.xs="start stretch">
                        <div fxFlex="100" fxFlex.xs="100" class="month-picker-div">
                            <h2 class="partner-ddl-header">{{ 'MONTH' | translate}}</h2>
                            <mxp-month-picker
                                [startDate]="dateFromObj"
                                [endDate]="dateToObj"
                                [requiredMonthRangeError]="'MONTH_RANGE_ERROR' | translate" 
                                (monthRangeSelected)="handleMonthRangeSelected($event)"
                                [enableMonthNavigation]="true"
                                [labelText]="'SELECT_A_MONTH' | translate"
                                [applyButtonText] = "'APPLY' | translate"
                                ></mxp-month-picker>
                        </div>
                    </div>
                </div>
            </div>
            <div class="data-row" fxLayout="row" fxLayout.xs="column"  fxLayoutGap="20px"  *ngIf="!isFirstCall">
                <div fxFlex="50" fxFlex.xs="100">
                    <div class="" >
                        <h2 class="billing-basis">{{ 'BILLING_BASIS' | translate}}</h2>
                        <div class="table">
                            <div class="table-row table-row-header">
                                <div class="table-cell table-cell-right">
                                    <h4 class="table-header-h4"> {{ 'NUMBER_OF_PAYMENTS' | translate }} </h4>
                                    <hr class="hr-left"/>
                                </div>
                                <div class="table-cell table-cell-left">
                                    <h4 class="table-header-h4"> {{ 'PRICE' | translate }} </h4>
                                    <hr class="hr-right"/>
                                </div>
                            </div>
                            
                            <div class="table-row" *ngFor="let pricing of dataSource; let _index = index; let _count = count;">
                                <div class="table-cell table-cell-right">
                                    <Span>{{ pricing.from }}</Span> 
                                    <span *ngIf="_index + 1 === _count">+</span>
                                    <span *ngIf="_index + 1 !== _count"> - </span> 
                                    <span *ngIf="_index + 1 !== _count">{{pricing.to}}</span>  </div>
                                <div class="table-cell table-cell-left">{{ pricing.price | currency : 'MX$ '}} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dynamic-div"  fxFlex="50" fxFlex.xs="100"  fxLayout="column">
                    <div class="payments-made">
                        <div class="payments-made-header">
                            <h2 class="billing-basis">{{ 'PAYMENT_DETAILS' | translate}}</h2>
                        </div>
                        <table class="mat-table mat-elevation-z0 payment-details-table">
                            <tr class="first-row">
                                <td> 
                                    <h4 class="table-header-h4"> {{'ITEM' | translate}} </h4>
                                    <hr class="hr-left" />
                                </td>
                                <td > 
                                    <h4 class="table-header-h4"> {{ 'AMOUNT' | translate}} </h4>
                                    <hr class="hr-right" />
                                </td>
                            </tr>
                            <tr class="mat-row billing-column">
                                <td class="mat-cell payment-made-col-header-cell table-cell-right" width="50%">{{ 'NUMBER_OF_PAYMENTS' |
                                    translate}}</td>
                                <td class="mat-cell  table-cell-left">{{totalPayments | number :'':'es-MX' }}</td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell payment-made-col-header-cell table-cell-right" width="50%">{{ 'PAYMENTS_MADE' |
                                    translate}}</td>
                                <td class="mat-cell table-cell-left">{{paymentsMade}}</td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell payment-made-col-header-cell table-cell-right" width="50%">{{ 'INVOICED_AMOUNT' |
                                    translate}}</td>
                                <td class="mat-cell table-cell-left">{{invoicedAmount | currency : 'MX$ '}}</td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell payment-made-col-header-cell table-cell-right" width="50%">{{ 'INVOICED_PAID_AMOUNT' |
                                    translate}}</td>
                                <td class="mat-cell table-cell-left">{{invoicedPaidAmount | currency : 'MX$ '}}</td>
                            </tr>
                            <tr class="last-row mat-row billing-column">
                                <th class="mat-cell payment-made-col-header-cell table-cell-right" width="50%">{{ 'TOTAL_TO_PAY' | translate}}
                                </th>
                                <th class="mat-cell table-cell-left"> {{ price | currency : 'MX$ '}} </th>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            
            <div class="spinner-container spinnerAbsolute" *ngIf="isLoading"><mat-spinner></mat-spinner></div>
        </mat-card>
    </div>
    <div class="error-div" *ngIf="hasError">
        <div class="mat-card error-msg">{{ 'BILLING_GENERATED_FAILED' | translate}}</div>
    </div>
</div>
