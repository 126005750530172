<div class="main-container">
  <div class="container">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10" fxLayoutGap.xs="20px">
      <div fxFlex="100%">
        <h1 fxShow fxHide.lt-lg class="payment-request-header">{{ 'PAYMENT_REQUEST' | translate }}</h1>
        <p class="payment-request-subtitle">
          {{ 'REQUEST_PAYMENT_DETAILS' | translate }}
        </p>
      </div>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div fxLayout="row" fxLayoutGap="10">
      <div class="payment-type-header" fxLayout="row"  fxFlex ="100%">
        <rezolve-drop-down 
          fxFlex = "40%" 
          [required]="true" 
          formControlName="paymentType" 
          [label]="'PAY_VIA' | translate"
          [items]="paymentTypes" 
          [errorDescriptions]="{
                required: 'REQUIRED_ERROR_MESSAGE' | translate
              }" 
          [optionTemplate]="languageOptionItems">
          <ng-template #languageOptionItems let-item> {{ item.key | translate }}</ng-template>
        </rezolve-drop-down>
      </div>
      <div class="account-details" fxLayout="column" fxFlex="30%">
        <div>
          <b>Partner</b>: {{ psp }}
        </div>
        <div>
          <b>{{ 'STORE' | translate }}</b>: {{ store }}
        </div>
      </div>
    </div>
    <div class="data-section">
      <mat-card class="payment-form-container">
        <div class="section-header">{{ 'PAYMENT_INFO' | translate | uppercase }}</div>
        <rezolve-basic-input
          required="true"
          [label]="'DESCRIPTION' | translate"
          [placeholder]="'PAYMENT_DESCRIPTION' | translate"
          formControlName="description"
          [errorDescriptions]="{
            maxlength: 'REQUIRED_LENGTH_ERROR_MESSAGE' | translate: { fieldName: 'DESCRIPTION' | translate, minLength: '3', maxLength: '40' },
            minlength: 'REQUIRED_LENGTH_ERROR_MESSAGE' | translate: { fieldName: 'DESCRIPTION' | translate, minLength: '3', maxLength: '40' },
            pattern: 'REQUIRED_SPECIAL_CHARACTER_MESSAGE' | translate: { fieldName: 'DESCRIPTION' | translate },
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }">
        </rezolve-basic-input>
        <ng-template [ngIf]="!isQrPayment" >
          <rezolve-phone-input
            [required]="true"
            [label]="'PHONE' | translate"
            [filterCountryCodes]="['mx']"
            formControlName="phone"
            [phoneNumberErrorLabel]="'PLEASE_PROVIDE_VALID_PHONE_NUMBER' | translate"
            [requiredErrorLabel]="
              'REQUIRED_ERROR_MESSAGE' | translate">
          </rezolve-phone-input>
        </ng-template>
        <ng-template [ngIf]="isQrPayment" [ngIfElse]="amountFormTemplate">
          <rezolve-numeric-input
          [required]="false"
          [validator]="pattern"
          [label]="'AMOUNT_PESO' | translate"
          [placeholder]="'AMOUNT_PESO' | translate"
          formControlName="amountQr"
          [errorDescriptions]="{
            max: 'REQUIRED_ERROR_MESSAGE' | translate
          }">
          </rezolve-numeric-input>
        </ng-template>
        <ng-template #amountFormTemplate>
          <rezolve-numeric-input
          [required]="true"
          [validator]="pattern"
          [label]="'AMOUNT_PESO' | translate"
          [placeholder]="'AMOUNT_PESO_PLACEHOLDER' | translate"
          formControlName="amountPn"
          [errorDescriptions]="{
            max: 'REQUIRED_ERROR_MESSAGE' | translate,
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }">
          </rezolve-numeric-input>
        </ng-template>
        <rezolve-calendar-date-picker 
          (dateChosen)="dateChanged($event)"
          [labelText]="'EXPIRY_DATE' | translate"
          [placeholder]="'ENTER_EXPIRY_DATE' | translate">
          </rezolve-calendar-date-picker>
      </mat-card>   
      <mat-card class="payment-form-container">
        <div class="section-header">{{ 'PAYMENT_TRACKING' | translate | uppercase }}</div>
        <rezolve-basic-input
          required="false"
          [label]="'CONTACT_INFO' | translate"
          [placeholder]="'CONTACT_INFO_PLACEHOLDER' | translate"
          formControlName="contactInfo"
          [errorDescriptions]="{
            maxlength: 'REQUIRED_LENGTH_ERROR_MESSAGE' | translate: { fieldName: 'CONTACT_INFO' | translate, minLength: '3', maxLength: '100' }}">
        </rezolve-basic-input>
        <rezolve-numeric-input
        [required]="false"
        [maxLength]="7"
        [validator]="pattern"
        [label]="'REFERENCE' | translate"
        [placeholder]="'REFERENCE_PLACEHOLDER' | translate"
        formControlName="reference"
        [errorDescriptions]="{
          max: 'REQUIRED_ERROR_MESSAGE' | translate }">
        </rezolve-numeric-input>
        <rezolve-basic-input
          required="false"
          [label]="'EXTENDED_REFERENCE' | translate"
          [placeholder]="'EXTENDED_REFERENCE_PLACEHOLDER' | translate"
          formControlName="extendedReference"
          [errorDescriptions]="{
            maxlength: 'REQUIRED_LENGTH_ERROR_MESSAGE' | translate: { fieldName: 'EXTENDED_REFERENCE' | translate, minLength: '0', maxLength: '40' }}">
        </rezolve-basic-input>
      </mat-card>
    </div>

    <div class="request-button" fxLayoutAlign="end">
      <rezolve-primary-button classList="large full-width" [disabled]="!form.valid">
        {{ 'REQUEST_PAYMENT' | translate }}
      </rezolve-primary-button>
    </div>
  </form>
  <div class="spinner-container" *ngIf="showSpinner"><mat-spinner></mat-spinner></div>
</div>