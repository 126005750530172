import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { AuthSettings } from './auth.settings';
import { AUTH_SETTINGS } from '../index';

@Injectable({
  providedIn: 'root',
})
export class Auth0ClientConfig {
  private config?: AuthSettings;

  constructor(@Optional() @Inject(AUTH_SETTINGS) private authConfig: AuthSettings) {
    if (authConfig) {
      this.set(authConfig);
    }
  }

  set(config: AuthSettings): void {
    this.config = config;
  }

  get(): AuthSettings {
    return <AuthSettings>this.config;
  }
}

export const AuthConfigService = new InjectionToken<AuthSettings>('auth0.config');
