import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AccountDetails,
  GetAccountDetailsResponse,
  GetByIdRequest,
  LanguageEnum,
  MxpAccountDetailsGrpcClient,
  UpdateAccountDetailsRequest,
} from '@rezolved/mxp-proto';
import { AuthService } from '@rezolved/auth';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { authUserRolesToEnumUserRoles, UserRoles } from '../../core/constants/UserGroups';
import { UserRole } from '../../core/interfaces/user-role';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { MxpTranslationService } from '../../core/services/mxp-translation.service';
import { BaseDropDownItem, BaseDropDownItemUtils } from '@rezolved/ui';
import { PermissionTypeEnum } from '../../core/enums/permission-type.enum';
import { MxpSnackBarService } from '../../core/services/mxp-snackbar.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../core/store/app.interface';
import { displayMyProfile } from '../../core/store/actions/app.actions';

@Component({
  selector: 'mxp-my-details',
  templateUrl: './my-details.component.html',
  styleUrls: ['./my-details.component.scss'],
})
export class MyDetailsComponent implements OnInit, OnDestroy {
  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();
  form: FormGroup;
  accountDetails: AccountDetails | undefined;
  languages: BaseDropDownItem[] = [];
  roleList: UserRole[] = [];
  isLoading = false;
  constructor(
    private mxpAccountDetailsGrpcClient: MxpAccountDetailsGrpcClient,
    private authService: AuthService,
    private readonly mxpTranslationService: MxpTranslationService,
    private mxpSnackBarService: MxpSnackBarService,
    private readonly store: Store<IAppState>
  ) {
    this.languages = BaseDropDownItemUtils.transformEnum(LanguageEnum);
    this.form = new FormGroup({
      language: new FormControl(this.accountDetails?.language, Validators.required)
    });
    this.store.dispatch(displayMyProfile());
  }

  get language(): LanguageEnum {
    return this.form.get(`language`)?.value;
  }

  getRoleDescription(permissions: PermissionTypeEnum[]): string {
    return permissions
      .map((permission) => this.mxpTranslationService.getTranslation('PERMISSIONS.' + permission))
      .join(', ');
  }

  initMyDetails(): void {
    this.isLoading = true;
    this.authService.user$.pipe(take(1)).subscribe(
      (user) => {
        if (user) {
          this.mxpAccountDetailsGrpcClient
            .getAccountDetails(
              new GetByIdRequest({
                id: user['https://rezolve.com/mxp_account_details_id']
              })
            )
            .subscribe(
              (response: GetAccountDetailsResponse) => {
                if (response.accountDetails) {
                  this.accountDetails = response.accountDetails;
                  const { language } = response.accountDetails;
                  this.form.get(`language`)?.setValue(language);
                  const authRoles = authUserRolesToEnumUserRoles(user['http://localhost:44346/roles']);
                  if (authRoles.length > 0) {
                    this.roleList = [];
                    UserRoles.forEach((role: UserRole) => {
                      if (authRoles.includes(role.id)) {
                        this.roleList.push(role);
                      }
                    });
                  }
                  this.isLoading = false;
                  this.mxpSnackBarService.showSnackbarMessage('SNACKBAR.GET_USER_DETAILS');
                }
              },
              (error) => {
                this.isLoading = false;
                this.mxpSnackBarService.showSnackbarMessage('SNACKBAR.GET_USER_DETAILS_FAIL');
                console.log(error);
              }
            );
        }
      },
      (error) => {
        this.isLoading = false;
        this.mxpSnackBarService.showSnackbarMessage('SNACKBAR.GET_USER_DETAILS_FAIL');
        console.log(error);
      }
    );
  }

  ngOnInit(): void {
    this.initMyDetails();
  }

  onSubmit(): void {
    this.isLoading = true;
    const request = new UpdateAccountDetailsRequest({
      id: this.accountDetails?.id,
      language: this.language
    });

    this.mxpSnackBarService
      .wrapApiCall('SNACKBAR.UPDATE_USER_DETAILS', this.mxpAccountDetailsGrpcClient.updateAccountDetails(request))
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => 
        {
          this.mxpTranslationService.updateTranslationLanguage(this.language);
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }
}