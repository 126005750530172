import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthSettings } from './auth.settings';
import { AuthService } from './auth.service';
import { Auth0ClientConfig, AuthConfigService } from './auth0.config';
import { Auth0ClientFactory, Auth0ClientService } from './auth0.client';

@NgModule({
  imports: [CommonModule],
})
export class AuthModule {
  public static forRoot(authSettings: AuthSettings): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        {
          provide: AuthConfigService,
          useValue: authSettings,
        },
        {
          provide: Auth0ClientService,
          useFactory: Auth0ClientFactory.createClient,
          deps: [Auth0ClientConfig],
        },
        {
          provide: AUTH_SETTINGS,
          useValue: authSettings,
        },
      ],
    };
  }
}

export const AUTH_SETTINGS = new InjectionToken<string>('auth.settings');
