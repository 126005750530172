import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UserGroups } from '../../../core/constants/UserGroups';
import { RoleEnum } from '@rezolved/mxp-proto/proto/common/mxp-enums.pb';

@Component({
  selector: 'mxp-user-group',
  templateUrl: './user-group.component.html',
  styleUrls: ['./user-group.component.scss'],
})
export class UserGroupComponent implements OnChanges {
  UserGroups = UserGroups;
  isValidUserGroup: boolean;

  @Input() userGroup: RoleEnum | undefined;
  @Input() readonly = true;
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
    this.isValidUserGroup = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isValidUserGroup = Object.values(UserGroups).includes(changes.userGroup.currentValue);
  }

  onChange(event: boolean) {
    this.checkedChange.emit(event);
  }
}
