/* eslint-disable @angular-eslint/use-pipe-transform-interface */
import { Pipe, PipeTransform } from '@angular/core';
import { LanguageEnum } from '@rezolved/mxp-proto';

@Pipe({
  name: 'formatLanguage',
})
export class FormatLanguagePipe implements PipeTransform {
  transform(value: LanguageEnum): string {
    if (value === undefined || value === null) {
      return '';
    }

    return LanguageEnum[value];
  }
}
