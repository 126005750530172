import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyDetailsComponent } from './my-details.component';
import { UiModule } from '@rezolved/ui';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { UserGroupModule } from '../../shared/components/user-group/user-group.module';
import { MatInputModule } from '@angular/material/input';
import { CustomPipesModule } from '../../core/pipes/custom-pipes.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

@NgModule({
  declarations: [MyDetailsComponent, ChangePasswordComponent],
  imports: [
    CommonModule,
    UiModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    UserGroupModule,
    CustomPipesModule,
    MatProgressSpinnerModule,
    TranslateModule.forChild(),
  ],
  exports: [ChangePasswordComponent],
})
export class MyDetailsModule {}
