import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserGroupModule } from './components/user-group/user-group.module';
import { HeaderComponent } from './components/header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@rezolved/ui';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [HeaderComponent, AlertDialogComponent],
  imports: [CommonModule, UserGroupModule, MatProgressSpinnerModule, TranslateModule.forChild(), UiModule, MatDialogModule],
  exports: [UserGroupModule, HeaderComponent],
})
export class SharedModule {}
