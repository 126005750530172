<div class="enroll-spinner-wrapper"  *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
  
  <div class="container header data-section-base data-section-2">
    <div>
      <h1 fxShow fxHide.lt-lg class="merchant-enrollment-header">{{ 'WELCOME_ENROLLMENT' | translate }}</h1>
      <p class="merchant-enrollment-subtitle">
        {{ 'WELCOME_ENROLLMENT_DETAILS' | translate }}
      </p>
    </div>
    <form [formGroup]="langForm" style="max-width:300px;">
      <rezolve-drop-down formControlName="lang" [label]="'LANGUAGE' | translate"
        [placeholder]="'SELECT_LANGUAGE' | translate" [items]="languages" [optionTemplate]="languageOptionItems">
      </rezolve-drop-down>
      <ng-template #languageOptionItems let-item> {{ item.key | translate }}</ng-template>
    </form>
  </div>
  <form [formGroup]="form">
    <mat-card class="enroll-form-container">
      <rezolve-divider>{{ 'MERCHANT' | translate | uppercase }}</rezolve-divider>
      <div class="data-section-base data-section-2">
        <rezolve-basic-input required="true" [label]="'MERCHANT_NAME' | translate" [maxLength]="40"
        [placeholder]="'ENTER_MERCHANT_NAME' | translate" formControlName="merchantName"
        [errorDescriptions]="{required : 'REQUIRED'| translate}">
        </rezolve-basic-input>
        <rezolve-basic-input required="true" [label]="'RFC_LABEL' | translate" [maxLength]="20"
          formControlName="merchantRfc" [placeholder]="'RFC_PLACEHOLDER' | translate"
          [errorDescriptions]="{required : 'REQUIRED' | translate} ">
        </rezolve-basic-input>
      </div>
      <div class="data-section-base data-section-2">
        <rezolve-basic-input required="true" [label]="'STREET' | translate" formControlName="merchantStreet"
        [maxLength]="40" [placeholder]="'ENTER_STREET' | translate"
        [errorDescriptions]="{required : 'REQUIRED' | translate} ">
        </rezolve-basic-input>
        <div class="data-section-base data-section-2">
          <rezolve-numeric-input required="true" [validator]="pattern" [label]="'NUMBER' | translate" [maxLength]="8"
          [placeholder]="'ENTER_NUMBER' | translate" formControlName="merchantNumber"
          [errorDescriptions]="{required: 'REQUIRED' | translate,
                                                    max: 'NUMBER_IS_REQUIRED_ERROR' | translate}">
          </rezolve-numeric-input>
          <rezolve-numeric-input required="true" [label]="'POSTAL_CODE' | translate" formControlName="merchantZip"
          [maxLength]="5" [placeholder]="'ENTER_POSTAL_CODE' | translate" fxFlex="1 0 20%"
          [errorDescriptions]="{required: 'REQUIRED' | translate}">
          </rezolve-numeric-input>
        </div>
      </div>
      <div class="data-section-base data-section-2">
        <rezolve-basic-input required="true" [label]="'CITY' | translate" formControlName="merchantCity" [maxLength]="30"
        [placeholder]="'ENTER_CITY' | translate" fxFlex="2 0 60%"
        [errorDescriptions]="{required: 'REQUIRED' | translate}">
        </rezolve-basic-input>
        <rezolve-drop-down required formControlName="merchantState" [label]="'STATE' | translate"
        [placeholder]="'SELECT_STATE' | translate" [items]="merchantStates" [optionTemplate]="stateItems"
        [errorDescriptions]="{required: 'REQUIRED' | translate}">
        <ng-template #stateItems let-item> {{ item.key | translate }}</ng-template>
        </rezolve-drop-down>
      </div>
      <rezolve-divider>{{ 'BANK_DETAILS' | translate | uppercase }}</rezolve-divider>
      <div class="data-section-base data-section-2">
        <rezolve-drop-down required formControlName="merchantBankCode" [label]="'CEP_ISSUING_BANK_CODE' | translate"
        [placeholder]="'CEP_ISSUING_BANK_CODE_HINT' | translate" [items]="bankCodes" [optionTemplate]="bankCodeItems"
        [errorDescriptions]="{required: 'REQUIRED' | translate}">
        <ng-template #bankCodeItems let-item> {{ item.key | translate }}</ng-template>
        </rezolve-drop-down>
        <rezolve-numeric-input class="issuing-bank-account" required="true" [label]="'CEP_ISSUING_BANK_ACCOUNT' | translate" [maxLength]="20"
        [placeholder]="'CEP_ISSUING_BANK_ACCOUNT_HINT' | translate" formControlName="merchantBankAccount"
        [errorDescriptions]="{required: 'REQUIRED' | translate }">
        </rezolve-numeric-input>
      </div>
      <rezolve-divider>{{ 'USER' | translate | uppercase }}</rezolve-divider>
      <div class="data-section-base data-section-2">
        <rezolve-basic-input [required]="true" [label]="'USER_FULL_NAME' | translate" 
        [placeholder]="'USER_FULL_NAME_PLACEHOLDER' | translate" formControlName="userFullName"
        [errorDescriptions]="{ required: 'REQUIRED' | translate}">
        </rezolve-basic-input>
        <rezolve-phone-input (errorEvent)="handlePhoneFieldError()" [required]="true" [label]="'PHONE' | translate" [filterCountryCodes]="['mx']"
        formControlName="userPhone" [phoneNumberErrorLabel]="'PLEASE_PROVIDE_VALID_PHONE_NUMBER' | translate"
        [phonePlaceHolder]="'PHONE_NUMBER_HINT' | translate" [requiredErrorLabel]="'REQUIRED' | translate">
        </rezolve-phone-input>
      </div>
      <div class="data-section-base data-section-2">
        <rezolve-email-input [required]="true" [label]="'EMAIL' | translate" [maxLength]="39"
          [placeholder]="'ENTER_EMAIL_HINT' | translate" formControlName="userEmail"
          [errorDescriptions]="{required: 'REQUIRED' | translate}">
        </rezolve-email-input>
        <rezolve-drop-down required formControlName="userLanguage" [label]="'LANGUAGE' | translate"
          [placeholder]="'SELECT_LANGUAGE' | translate" [items]="languages" [optionTemplate]="languageOptionItems"
          [errorDescriptions]="{required: 'REQUIRED' | translate}">
          <ng-template #languageOptionItems let-item> {{ item.key | translate }}</ng-template>
        </rezolve-drop-down>
      </div>
      <div>
        <mxp-signature [height]="200" [width]="400" required (isSignatureEmpty)="handleIsEmptySignature($event)"
          (onSave)="handleOnSaveSignature($event)"></mxp-signature>
      </div>
      <!-- <div class="data-section">
        <rezolve-checkbox required formControlName="termsAndConditions"
          [errorMessage]="'REQUIRED' | translate" (checkedChanged)="handleAgreement()">
          <span>
            {{'DECLARATION' | translate }}
          </span>
        </rezolve-checkbox>
        <span>
          <a target="_blank" class="link" (click)="openTandCPopUp()"> {{ 'TERMS_AND_CONDITIONS' | translate}} *</a>
        </span>
      </div> -->
    </mat-card>
  </form>
  <mat-dialog-actions fxLayoutAlign="end">
    <rezolve-primary-button [disabled]="!!form.invalid || !isSigned || phoneFieldHasErrors" classList="medium"
      (handleClick)="onSubmit()">
      {{ 'ENROLL' | translate }}
    </rezolve-primary-button>
  </mat-dialog-actions>