import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'rezolve-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  @Input() opened = false;

  @Output()
  openedChange: EventEmitter<boolean> = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChild('navbarSection') navbarSection!: TemplateRef<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChild('navbarItems') navbarItems!: TemplateRef<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChild('navbarContent') navbarContent!: TemplateRef<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChild('navbarHeader') navbarHeader!: TemplateRef<any>;

  @ContentChild('navbarToolbarContent')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navbarToolbarContent!: TemplateRef<any>;

  toggle() {
    this.opened = !this.opened;
    this.openedChange.emit(this.opened);
  }
}
