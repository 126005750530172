<mat-form-field class="rezolve-role" [attr.data-cy]="generateDataCyId('-form-field')">
  <div class="role-container">
    <input matInput type="text" [hidden]="true" />
    <div class="role-icon-label-container">
      <mat-icon>perm_identity</mat-icon>
      <div class="role-label-container">
        <div *ngIf="name" class="role-name" [attr.data-cy]="generateDataCyId('-name')">
          {{ name }}
        </div>
        <div *ngIf="description" class="role-description" [attr.data-cy]="generateDataCyId('-description')">
          {{ description }}
        </div>
      </div>
    </div>
    <rezolve-checkbox
      *ngIf="!hideCheckbox"
      fxFlexAlign="center"
      [formControl]="role"
      (checkedChanged)="onValueChange()"></rezolve-checkbox>
  </div>
</mat-form-field>
