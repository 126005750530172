import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MxpStoreDto } from '@rezolved/mxp-proto';
import { DateFormatOptions } from '../../../core/constants/DateFormatOptions';

@Component({
  selector: 'mxp-store',
  templateUrl: './psp-store.component.html',
  styleUrls: ['./psp-store.component.scss'],
})
export class PspStoreComponent {
  dateFormat: string;

  constructor() {
    this.dateFormat = DateFormatOptions.DEFAULT_DATE;
  }

  @Input() store!: MxpStoreDto;
  @Output() edit = new EventEmitter<MxpStoreDto>();

  onEdit(): void {
    this.edit.emit(this.store);
  }

  getAddress(): string {
    const { street1, street2, city, state, zip } = this.store;
    return `${street1} ${street2}, ${city}, ${state} ${zip}`;
  }
}
