<div class="main-container">
  <div class="header">
    <h2 class="header-title">{{ 'MANAGE_ACCOUNTS' | translate }}</h2>
    <rezolve-secondary-button
      class="new-account-button"
      classList="secondary large full-width"
      (handleClick)="openDialog()">
      <div class="button-content">
        <mat-icon class="mat-icon" svgIcon="plus"></mat-icon>
        {{ 'NEW_ACCOUNT' | translate }}
      </div>
    </rezolve-secondary-button>
  </div>
  <div class="accounts-container">
    <!--    <ng-container *ngFor="let psp of psps | async">-->
    <!--      <mxp-account class="account-item" [psp]="psp"></mxp-account>-->
    <!--    </ng-container>-->
  </div>
</div>
