import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import Route from '../../../core/constants/Route';
import * as moment from 'moment';
import { DateFormatOptions } from '../../../core/constants/DateFormatOptions';
import { PaymentSentConfiguration } from '../../../core/interfaces/payment-sent-configuration';
import { Store } from '@ngrx/store';
import { qrPaymentSent } from '../../../core/store/actions/app.actions';
import { IAppState } from '../../../core/store/app.interface';

@Component({
  selector: 'mxp-payment-qr',
  templateUrl: './payment-qr.component.html',
  styleUrls: ['./payment-qr.component.scss'],
})
export class PaymentQrComponent implements OnInit {
  showTimer = true;
  imgUrl?: string;
  pngUrl: SafeResourceUrl | undefined;
  counter = 0;
  qrCodePngName = `qr-payment-${Date.now()}`;

  @Input()
  paymentSentConfiguration!: PaymentSentConfiguration;

  constructor(private sanitizer: DomSanitizer, private router: Router, private readonly store: Store<IAppState>) {}

  get expiryDate(): string {
    if (this.paymentSentConfiguration?.paymentConfiguration?.expiryDate) {
      return moment(this.paymentSentConfiguration?.paymentConfiguration?.expiryDate.toDate())
        .format(DateFormatOptions.DEFAULT_PAYMENT_DATE);   
    } else {
      return 'EXPIRY_DATE_REQUIRED';
    }
  }

  ngOnInit() {
    this.setupPayment();
    this.store.dispatch(qrPaymentSent());
  }

  setupPayment(): void {
    if (this.paymentSentConfiguration?.paymentConfiguration?.qr) {
      const contentType = 'image/png';
      const byteCharacters = atob(this.paymentSentConfiguration?.paymentConfiguration?.qr);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: contentType });

      this.imgUrl = `data:image/png;base64,${this.paymentSentConfiguration?.paymentConfiguration?.qr}`;
      this.pngUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    }
  }

  cancelRequest(): void {
    this.router.navigate([Route.REQUEST_PAYMENT]);
  }

  closeTimer(): void {
    this.showTimer = false;
  }
}