<div class="main-container" *ngIf="userGroup">
  <div class="user-group" *ngIf="isValidUserGroup">
    <mat-icon svgIcon="user"></mat-icon>
    <div class="info">
      <ng-container [ngSwitch]="userGroup">
        <ng-container *ngSwitchCase="UserGroups.BASIC">
          <span class="title">{{ 'BASIC_USER' | translate }}</span>
          <span class="subtitle">{{ 'YOU_CAN_MAKE_PAYMENTS_BUT_NOT_VIEW_REPORTS' | translate }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="UserGroups.ADMIN">
          <span class="title">{{ 'ADMINISTRATOR' | translate }}</span>
          <span class="subtitle">{{ 'YOU_HAVE_FULL_ADMINISTRATIVE_ACCESS' | translate }}</span>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <rezolve-checkbox *ngIf="!readonly" [checked]="true" (checkedChanged)="onChange($event)"></rezolve-checkbox>
</div>
