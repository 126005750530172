import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { CustomFormField } from '../../abstract/custom-form-field';

const ERROR_MESSAGES = {
  INCOMPLETE: `Your card's expiration date is incomplete.`,
  IN_PAST: `Your card's expiration date is in the past.`,
};

@Component({
  selector: 'rezolve-expiry-date-input',
  templateUrl: './expiry-date-input.component.html',
  styleUrls: ['./expiry-date-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: ExpiryDateInputComponent }],
  encapsulation: ViewEncapsulation.None,
})
export class ExpiryDateInputComponent extends CustomFormField<string> implements OnDestroy {
  private _inputMask = 'MM/YY';
  private _formattedValue: string | null = null;

  pattern = new RegExp('^(0[1-9]|1[0-2])/?([0-9]{4}|[0-9]{2})$');
  maxLength = 5;
  errorMessage: string | null = null;

  @Output() errorEvent: EventEmitter<string> = new EventEmitter();

  get formattedValue(): string {
    return this._formattedValue || '';
  }

  set formattedValue(value: string) {
    this.value = value.replace(/[^0-9]*/g, '');
    this._formattedValue = value;
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
  ) {
    super(ngControl, `rezolve-card-expiry-date-${ExpiryDateInputComponent.nextId++}`);
    this.controlType = 'rezolve-card-expiry-date-control';
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.focusMonitor.monitor(this.elRef, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elRef);
  }

  onNgModelChange(model: NgModel) {
    this.errorState = !this.isExpiryDateValid(model.value);
    if (this.errorState) {
      model.control.setErrors({ invalidExpiryDate: true });
      this.errorEvent.emit(this.errorMessage || undefined);
    }
  }

  /**
   * Used to validate the passed expiry date in string format: MM/YY
   * @param expiryDate - should be in format MM/YY
   * @returns true if expiry date is valid, otherwise it returns false
   */
  isExpiryDateValid(expiryDate: string): boolean {
    const dateSeparator = '/';
    this.errorMessage = null;
    if (expiryDate.includes(dateSeparator)) {
      const dateParts = expiryDate.split(dateSeparator);
      if (expiryDate.length === this._inputMask.length) {
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const year = Number(currentDate.getFullYear().toString().slice(-2));
        const expiryMonth = Number(dateParts[0]);
        const expiryYear = Number(dateParts[1]);
        const yearIsInPast = expiryYear < year;
        const monthIsInPast = expiryYear <= year && expiryMonth < month;
        const isExpiryDateValid = !yearIsInPast && !monthIsInPast;
        if (!isExpiryDateValid) this.errorMessage = ERROR_MESSAGES.IN_PAST;

        return isExpiryDateValid;
      }
    }
    this.errorMessage = ERROR_MESSAGES.INCOMPLETE;
    return false;
  }
}
