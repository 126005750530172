import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumericInputDirective } from './directives/numeric-input/numeric-input.directive';
import { CvvInputComponent } from './components/atoms/cvv-input/cvv-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ExtendedModule, FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ExpiryDateInputComponent } from './components/atoms/expiry-date-input/expiry-date-input.component';
import { ExpiryDateInputDirective } from './directives/expiry-date-input/expiry-date-input.directive';
import { CardNumberInputComponent } from './components/atoms/card-number-input/card-number-input.component';
import { BaseModalComponent } from './components/atoms/base-modal/base-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { BasicInputComponent } from './components/atoms/basic-input/basic-input.component';
import { EmailInputComponent } from './components/atoms/email-input/email-input.component';
import { ListViewComponent } from './components/atoms/list-view/list-view.component';
import { NumericInputComponent } from './components/atoms/numeric-input/numeric-input.component';
import { ListPaginationComponent } from './components/atoms/list-pagination/list-pagination.component';
import { BasicListComponent } from './components/molecules/basic-list/basic-list.component';
import { CheckboxComponent } from './components/atoms/checkbox/checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AddressListItemComponent } from './components/atoms/address-list-item/address-list-item.component';
import { AddressListComponent } from './components/molecules/address-list/address-list.component';
import { DividerComponent } from './components/atoms/divider/divider.component';
import { TabsComponent } from './components/molecules/tabs/tabs.component';
import { TabItemComponent } from './components/atoms/tabs/tab-item/tab-item.component';
import { TabHeaderComponent } from './components/atoms/tabs/tab-header/tab-header.component';
import { TabBodyComponent } from './components/atoms/tabs/tab-body/tab-body.component';
import { BasicDropDownInputComponent } from './components/atoms/basic-drop-down-input/basic-drop-down-input.component';
import { MatButtonModule } from '@angular/material/button';
import { BasicButtonComponent } from './components/atoms/basic-button/basic-button.component';
import { PrimaryButtonComponent } from './components/atoms/primary-button/primary-button.component';
import { SecondaryButtonComponent } from './components/atoms/secondary-button/secondary-button.component';
import { CardDisplayPipe } from './pipes/card-display/card-display.pipe';
import { CreditCardItemComponent } from './components/atoms/credit-card-item/credit-card-item.component';
import { CreditCardListComponent } from './components/molecules/credit-card-list/credit-card-list.component';
import { SearchInputComponent } from './components/atoms/search-input/search-input.component';
import { AddressInputComponent } from './components/molecules/address-input/address-input.component';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { DataViewComponent } from './components/atoms/data-view/data-view.component';
import { InstantBuyButtonComponent } from './components/atoms/instant-buy-button/instant-buy-button.component';
import { PhoneInputComponent } from './components/molecules/phone-input-component/phone-input-component.component';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CountriesPhoneHelperService, UtilModule } from '@rezolved/util';
import { MatOptionModule, MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { DialCodePipe } from './pipes/dial-code.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BaseTableComponent } from './components/atoms/base-table/base-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TableTestComponent } from './components/atoms/base-table/table-test.component';
import { MatSortModule } from '@angular/material/sort';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { ToggleControlComponent } from './components/atoms/toggle-control/toggle-control.component';
import { BaseCardComponent } from './components/atoms/base-card/base-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './components/molecules/navbar/navbar.component';
import { NavbarItemComponent } from './components/atoms/navbar/navbar-item/navbar-item.component';
import { NavbarSectionComponent } from './components/atoms/navbar/navbar-section/navbar-section.component';
import { DateRangePickerComponent } from './components/molecules/date-range-picker/date-range-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CalendarDateRangerPickerComponent } from './components/atoms/calendar-date-ranger-picker/calendar-date-ranger-picker.component';
import { CalendarDatePickerComponent } from './components/atoms/calendar-date-picker/calendar-date-picker.component';
import { DateRangeDisplayPipe } from './pipes/date-range-display.pipe';
import { BaseContainerComponent } from './components/atoms/base-container/base-container.component';
import { UploadComponent } from './components/atoms/upload/upload.component';
import { DragAndDropDirective } from './directives/drag-and-drop/drag-and-drop.directive';
import { AnchorButtonComponent } from './components/atoms/anchore-button/anchor-button.component';
import { TertiaryButtonComponent } from './components/atoms/tertiary-button/tertiary-button.component';
import { RoleComponent } from './components/molecules/role/role.component';
import { NavbarToolbarContentComponent } from './components/atoms/navbar/navbar-toolbar-content/navbar-toolbar-content.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

export const ngxMaskModule = NgxMaskModule.forRoot();

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FontAwesomeModule,
    FormsModule,
    MatDialogModule,
    FlexLayoutModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSelectModule,
    HttpClientModule,
    MatSelectModule,
    MatOptionModule,
    ngxMaskModule,
    BrowserAnimationsModule,
    ExtendedModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    RouterModule,
    FlexModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatSlideToggleModule,
    UtilModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatChipsModule,
    MatMenuModule,
    MatCardModule,
    MatDividerModule,
    MatRippleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  declarations: [
    NumericInputDirective,
    ExpiryDateInputDirective,
    CvvInputComponent,
    ExpiryDateInputComponent,
    CardNumberInputComponent,
    BasicInputComponent,
    EmailInputComponent,
    ListViewComponent,
    NumericInputComponent,
    ListPaginationComponent,
    BasicListComponent,
    CheckboxComponent,
    DividerComponent,
    AddressListItemComponent,
    AddressListComponent,
    TabsComponent,
    TabHeaderComponent,
    TabBodyComponent,
    TabItemComponent,
    BasicDropDownInputComponent,
    BasicButtonComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    CardDisplayPipe,
    CreditCardItemComponent,
    CreditCardListComponent,
    InstantBuyButtonComponent,
    AddressInputComponent,
    BaseModalComponent,
    SearchInputComponent,
    DataViewComponent,
    PhoneInputComponent,
    DialCodePipe,
    ToggleControlComponent,
    BaseTableComponent,
    TableTestComponent,
    BaseCardComponent,
    NavbarComponent,
    NavbarItemComponent,
    NavbarSectionComponent,
    DateRangePickerComponent,
    CalendarDatePickerComponent,
    CalendarDateRangerPickerComponent,
    DateRangeDisplayPipe,
    BaseContainerComponent,
    UploadComponent,
    DragAndDropDirective,
    TertiaryButtonComponent,
    AnchorButtonComponent,
    RoleComponent,
    NavbarToolbarContentComponent,
  ],
  exports: [
    NumericInputDirective,
    ExpiryDateInputDirective,
    CvvInputComponent,
    ExpiryDateInputComponent,
    CardNumberInputComponent,
    BasicInputComponent,
    EmailInputComponent,
    ListViewComponent,
    NumericInputComponent,
    ListPaginationComponent,
    BasicListComponent,
    CheckboxComponent,
    DividerComponent,
    AddressListItemComponent,
    AddressListComponent,
    TabHeaderComponent,
    TabItemComponent,
    TabBodyComponent,
    TabsComponent,
    BasicDropDownInputComponent,
    BasicButtonComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    SearchInputComponent,
    InstantBuyButtonComponent,
    AddressInputComponent,
    BaseModalComponent,
    DataViewComponent,
    PhoneInputComponent,
    CreditCardListComponent,
    CreditCardItemComponent,
    ToggleControlComponent,
    BaseTableComponent,
    TableTestComponent,
    BaseCardComponent,
    NavbarComponent,
    NavbarItemComponent,
    NavbarSectionComponent,
    MatIconModule,
    MatToolbarModule,
    DateRangePickerComponent,
    CalendarDatePickerComponent,
    CalendarDateRangerPickerComponent,
    BaseContainerComponent,
    DragAndDropDirective,
    UploadComponent,
    TertiaryButtonComponent,
    AnchorButtonComponent,
    RoleComponent,
    NavbarToolbarContentComponent,
  ],
  providers: 
  [
    CountriesPhoneHelperService
  ],
})
export class UiModule {
  constructor(private readonly translateService: TranslateService) {
    if (!this.translateService.defaultLang) {
      this.translateService.use('en');
    }
  }
}