import { Directive, Input } from '@angular/core';

@Directive()
export abstract class NamedComponent {
  @Input() componentName = '';

  generateDataCyId(suffix: string): string {
    return `${this.componentName}${suffix}`;
  }
}
