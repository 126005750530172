import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NamedComponent } from '../../abstract/named-component';
import { enTranslations } from './i18n/en';
import { esTranslations } from './i18n/es';

@Component({
  selector: 'rezolve-list-pagination',
  templateUrl: './list-pagination.component.html',
  styleUrls: ['./list-pagination.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListPaginationComponent extends NamedComponent implements OnInit, OnChanges {
  @Input() itemsLength = 0;
  @Input() pageLinksSize = 7;
  @Input() itemsPerPage = 10;
  @Input() pageSizeOptions?: number[];
  @Input() currentPage = 1;
  @Input() small = false;

  @Output() pageChanged = new EventEmitter<number>();
  @Output() pageSizeChanged = new EventEmitter<number>();

  pageLinks = [1];
  visiblePageLinks = [1];

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.init(this.currentPage);

    this.translateService.setTranslation('en', enTranslations);
    this.translateService.setTranslation('es', esTranslations);
  }

  getOption(value: number, perPageLabel: string): string {
    return `${value} ${perPageLabel}`;
  }

  getSummary(ofLabel: string, itemsLabel: string): string {
    const firstIndex = this.currentPage * this.itemsPerPage + 1;
    const lastIndex =
      (this.currentPage + 1) * this.itemsPerPage > this.itemsLength
        ? this.itemsLength
        : (this.currentPage + 1) * this.itemsPerPage;

    return `${firstIndex} - ${lastIndex} ${ofLabel} ${this.itemsLength} ${itemsLabel}`;
  }

  init(page = 1): void {
    this.pageLinks = [];

    const pageCount = this.getPageCount();

    for (let i = 1; i <= pageCount; ++i) {
      this.pageLinks.push(i);
    }

    this.navigateToPage(page - 1);
  }

  getPageCount(): number {
    return Math.ceil(this.itemsLength / this.itemsPerPage) || 1;
  }

  navigateToPage(index: number, event?: { stopPropagation: () => void } | undefined): void {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    let firstRequest = false;
    const pageExists = this.pageLinks.find((pl) => pl === index + 1);
    if (!pageExists) {
      index = 0;
    }
    firstRequest = this.currentPage === index;
    this.currentPage = index;
    this.setVisiblePageLinks();
    if(!firstRequest)
      this.pageChanged.emit(this.currentPage);
  }

  setVisiblePageLinks(): void {
    if (this.pageLinks.length <= this.pageLinksSize) {
      this.visiblePageLinks = this.pageLinks.slice();
      return;
    }

    this.visiblePageLinks = [];
    this.visiblePageLinks.push(this.currentPage + 1);
    let counter = 1;

    while (this.visiblePageLinks.length < this.pageLinksSize) {
      if (this.pageLinks[this.currentPage - counter]) {
        this.visiblePageLinks.push(this.pageLinks[this.currentPage - counter]);
      }

      counter = counter * -1;

      if (counter > 0) {
        counter++;
      }
    }

    this.visiblePageLinks.sort((plA: number, plB: number) => plA - plB);

    const lowestVisiblePageLink = this.visiblePageLinks[0];
    if (this.pageLinks.indexOf(lowestVisiblePageLink) !== 0) {
      this.visiblePageLinks.splice(0, 1);
    }

    const highestVisiblePageLink = this.visiblePageLinks[this.visiblePageLinks.length - 1];
    if (this.pageLinks.indexOf(highestVisiblePageLink) < this.pageLinks.length - 1) {
      this.visiblePageLinks.pop();
    }
  }

  shouldShowPaginatorDots(side: string): boolean {
    if (side === 'left') {
      const lowestVisiblePageLink = this.visiblePageLinks[0];
      return this.pageLinks.indexOf(lowestVisiblePageLink) !== 0;
    } else {
      const highestVisiblePageLink = this.visiblePageLinks[this.visiblePageLinks.length - 1];
      return this.pageLinks.indexOf(highestVisiblePageLink) < this.pageLinks.length - 1;
    }
  }

  itemsPerPageValueChange(event: { source: { value: string } }): void {
    this.itemsPerPage = parseInt(event.source.value, 10);
    this.pageSizeChanged.emit(this.itemsPerPage);
    this.init(1);
  }

  isExpNaN(value: number): boolean {
    return value !== value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['itemsLength']) {
      const firstChange = changes['itemsLength'].firstChange;
      const value = changes['itemsLength'].currentValue;

      if (typeof value !== 'number' || this.isExpNaN(value) || value < 0) {
        this.itemsLength = 0;
      }

      if (!firstChange) {
        this.init(1);
      }
    }

    if (changes['currentPage']) {
      const firstChange = changes['currentPage'].firstChange;
      const value = changes['currentPage'].currentValue;

      if (typeof value !== 'number' || this.isExpNaN(value) || value < 1) {
        this.currentPage = 0;
      }

      if (!firstChange) {
        this.navigateToPage(this.currentPage);
      }
    }
  }
}
