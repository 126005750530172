import { Component } from '@angular/core';
import { NamedComponent } from '../../abstract/named-component';

@Component({
  selector: 'rezolve-base-container',
  templateUrl: './base-container.component.html',
  styleUrls: ['./base-container.component.scss'],
})
export class BaseContainerComponent extends NamedComponent {
  constructor() {
    super();
    this.componentName = 'base-container';
  }
}
