<div class="card-item" fxLayout="row" fxLayoutGap="20px">
  <div fxFlex="60" fxLayoutAlign="start">
    <span>
      <fa-icon [icon]="getIcon(creditCardInfo?.brand)"></fa-icon>
      {{ creditCardInfo?.number | cardDisplay }}
      <fa-icon [icon]="faCheck" class="fa-icon-check" *ngIf="isSelected"></fa-icon
    ></span>
  </div>

  <div fxFlex="40" fxLayoutAlign="end">
    <rezolve-basic-button (click)="edit()">{{ editLabel }}</rezolve-basic-button>
    <rezolve-basic-button (click)="remove()">{{ removeLabel }}</rezolve-basic-button>
  </div>
</div>
