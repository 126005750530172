import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AddMxpPspRequest, MxpPspGrpcClient } from '@rezolved/mxp-proto';
import { finalize } from 'rxjs/operators';
import { MxpSnackBarService } from '../../../core/services/mxp-snackbar.service';
import { MxpPortalEnrollmentGrpcClient  } from '@rezolved/mxp-proto';
import { BaseDropDownItem } from '@rezolved/ui';
import { Empty } from '@ngx-grpc/well-known-types';

@Component({
  selector: 'mxp-add-codi-account',
  templateUrl: './psp-creation.component.html',
  styleUrls: ['./psp-creation.component.scss'],
})
export class PspCreationComponent {
  form: FormGroup;
  isLoading = false;
  uploadErrors = '';
  shouldPropagateToAll:boolean = false;
  bankCodes: BaseDropDownItem[] = [];

  constructor(
    private dialogRef: MatDialogRef<PspCreationComponent>,
    private translate: TranslateService,
    private mxpPspGrpcClient: MxpPspGrpcClient,
    private mxpSnackBarService: MxpSnackBarService,
    private mxpBulkEnrollmentGrpcClient: MxpPortalEnrollmentGrpcClient,
    @Inject(MAT_DIALOG_DATA) private storeId: string,
  ) {
    this.form = new FormGroup({
      beneficiaryName: new FormControl('', Validators.required),
      beneficiaryBank: new FormControl('', Validators.required),
      beneficiaryAccountNumber: new FormControl('', Validators.required),
      beneficiaryAccountAlias: new FormControl('', Validators.required),
      beneficiaryAccountDigit: new FormControl('', Validators.required),
      beneficiaryAccountKeySource: new FormControl('', Validators.required),
      certificateSerialNumber: new FormControl('', Validators.required),
      isMock: new FormControl(false, Validators.required)
    });

    this.mxpBulkEnrollmentGrpcClient.getBankCodes(new Empty()).subscribe(response => {
      if(response && response.bankCodes) {
        this.bankCodes = response.bankCodes.map((item) => {
          return { key: item.bankName?.value, value: item.bankNumber } as BaseDropDownItem;
        }).sort((a,b) => a.key.localeCompare(b.key));
      }
    });
  }

  onSave(): void {
    const { value: formValues } = this.form;
    this.isLoading = true;

    const request = new AddMxpPspRequest({
      beneficiaryAccountNumber: formValues.beneficiaryAccountNumber,
      beneficiaryBank: formValues.beneficiaryBank + '',
      beneficiaryName: formValues.beneficiaryName,
      certificateSerialNumber: formValues.certificateSerialNumber,
      beneficiaryAccountAlias: formValues.beneficiaryAccountAlias,
      beneficiaryAccountDigit: formValues.beneficiaryAccountDigit,
      beneficiaryAccountKeySource: formValues.beneficiaryAccountKeySource,
      isMock: formValues.isMock,
      storeId: this.storeId,
      shouldPropagateToAll:this.shouldPropagateToAll 
    });
    this.mxpSnackBarService
      .wrapApiCall('SNACKBAR.ADD_PSP', this.mxpPspGrpcClient.addPsp(request), false)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.dialogRef.close(true);
        },
        (error) => {
          console.log(error);
          this.dialogRef.close(false);
        }
      );
  }

  close(): void {
    this.dialogRef.close();
  }

  onUploadErrors(errors: string[]): void {
    if (errors.length > 0) {
      this.uploadErrors = errors[0];

      return;
    }

    this.uploadErrors = '';
  }

  handlePropagateToAll(event:boolean){
    this.shouldPropagateToAll = event;
  }
}
