/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataSourceService } from '../../../../../../../libs/ui/src/lib/interfaces/base-table/data-source-service';
import { map } from 'rxjs/operators';
import { BaseTableParams } from '../../../../../../../libs/ui/src/lib/interfaces/base-table/base-table-params';
import {
  GenerateReportRequestV3,
  MxpPaymentReportGrpcClient,
  PaymentStatusEnum,
  PaymentTypeEnum,
  SortDirectionEnum,
} from '@rezolved/mxp-proto';
import { AuthService } from '@rezolved/auth';
import { QueryData } from 'libs/ui/src/lib/interfaces/base-table/query-data';
import { StringValue, Timestamp } from '@ngx-grpc/well-known-types';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { DateFormatOptions } from '../../../core/constants/DateFormatOptions';
import { MxpSnackBarService } from '../../../core/services/mxp-snackbar.service';
import { TranslateService } from '@ngx-translate/core';

export interface ReportsTableData {
  value: [
    {
      id?: string;
      timestamp?: string;
      interactedOnTimeStamp? : string;
      description?: string;
      reference?: string;
      phone?: string;
      transactionId?: string;
      userName?: string;
      status?: string;
      total?: string;
      type?: string;
      extendedReference?: string;
      contactInfo?: string;
    }
  ];
  total: number;
  totalAmount: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReportsTableService implements DataSourceService {
  private partnerId?: string;
  private merchantId?: string;
  totalCount = '0';
  totalAmount = '0';

  constructor(
    private datePipe: DatePipe,
    private mxpPaymentReportGrpcClient : MxpPaymentReportGrpcClient,
    private authService: AuthService,
    private mxpSnackBarService: MxpSnackBarService,
    private translate: TranslateService
  ) {
    this.authService.user$.subscribe((user) => {
      if (user) {
        this.partnerId = user['https://rezolve.com/mxp_partner_id'];
        this.merchantId = user['https://rezolve.com/mxp_merchant_id'];
      }
    });
  }

  datePipeFunction = (date: Date): string => {
    return this.datePipe.transform(date, DateFormatOptions.DEFAULT_DATE_TIME) ?? '';
  };

  loadData(props: BaseTableParams): Observable<ReportsTableData> {
    const sortDirection = SortDirectionEnum[props.sortDirection.toUpperCase() as keyof typeof SortDirectionEnum];
    const paginationData = {
      pageIndex: props.pageIndex,
      pageSize: props.pageSize,
      sortField: props.sortField ? props.sortField : '',
      sortDirection: props.sortField ? sortDirection : SortDirectionEnum.ASC
    };

    const queryData = props.searchFilter as QueryData<PaymentStatusEnum[],PaymentTypeEnum>;
    let storeIds: string[];
    let userId: StringValue = new StringValue();
    let description: StringValue = new StringValue();
    let createdOnDateStart  = moment.isMoment(queryData.createdOnDate.start) ? queryData.createdOnDate.start.toDate() : queryData.createdOnDate.start;
    let createdOnDateEnd  = moment.isMoment(queryData.createdOnDate.end) ? queryData.createdOnDate.end.toDate() : queryData.createdOnDate.end;
    const createdOnDateFrom = new Timestamp();
    const createdOnDateTo = new Timestamp();
    createdOnDateFrom.fromDate(createdOnDateStart);
    createdOnDateTo.fromDate(createdOnDateEnd);
    let interactedOnDateStart  = moment.isMoment(queryData.interactedOnDate.start) ? queryData.interactedOnDate.start.toDate() : queryData.interactedOnDate.start;
    let interactedOnDateEnd  = moment.isMoment(queryData.interactedOnDate.end) ? queryData.interactedOnDate.end.toDate() : queryData.interactedOnDate.end;
    const interactedOnDateFrom = new Timestamp();
    const interactedOnDateTo = new Timestamp();
    interactedOnDateFrom.fromDate(interactedOnDateStart);
    interactedOnDateTo.fromDate(interactedOnDateEnd);

    storeIds = queryData.additionalData[0];
    userId.value = queryData.additionalData[1] ?? ''
    description.value = queryData.additionalData[2] ?? '';
    
    let generateReportRequest = new GenerateReportRequestV3({
      userId: userId,
      statusList: queryData.status,
      createdOnDateFrom: createdOnDateFrom,
      createdOnDateTo: createdOnDateTo,
      interactedOnDateFrom: interactedOnDateFrom,
      interactedOnDateTo: interactedOnDateTo,
      paginationData,
      storeIds: storeIds,
      paymentType: queryData.type != null ? queryData.type : PaymentTypeEnum.ALL,
      searchTerm: description
    });

    return this.mxpSnackBarService
    .wrapApiCall(
      'SNACKBAR.PAYMENT_REPORT',
      this.mxpPaymentReportGrpcClient.generateReportV3(generateReportRequest)
    )
    .pipe(
      map(({ reports, totalCount, totalAmount }) => {
        const totalAmountAsNumber = totalAmount ? parseFloat(totalAmount).toFixed(2) : 0;
        this.totalAmount = `${totalAmountAsNumber}`;
        this.totalCount = `${totalCount}` ?? '0';
        console.log(
          'totalAmount: ' +
            totalAmount +
            ', totalAmountAsNumber: ' +
            totalAmountAsNumber +
            ', this.totalAmount: ' +
            this.totalAmount
        );
        return {
          value: reports?.map((report) => {
            return {
              id: report?.id,
              timestamp: this.datePipeFunction(moment(report?.createdAt).toDate()),
              interactedOnTimeStamp: report?.interactedOn ? this.datePipeFunction(moment(report.interactedOn.value).toDate()) : null,
              description: report?.description,
              reference: report?.reference,
              phone: report?.clientPhone,
              userName: report?.userName,
              status: this.translateStatus(report?.status),
              total: report?.amount && (Math.round(report?.amount * 100) / 100).toFixed(2),
              type: `${!!report?.paymentType?.toString() && PaymentTypeEnum[report?.paymentType]}`,
              transactionId: report?.transactionId,
              extendedReference: report?.extendedReference?.value,
              contactInfo: report?.contactInfo
            };
          }),
          total: totalCount,
          totalAmount: totalAmount
        } as ReportsTableData;
      })
    );
  }

  translateStatus(status: PaymentStatusEnum | undefined) : string {
    if(status === undefined) {
      return '';
    }
    else {
      return this.translate.instant(PaymentStatusEnum[status]);
    }
  }
}