const Route = {
  PAYMENT_NOTIFICATION: 'payment-notification',
  REQUEST_PAYMENT: 'request-payment',
  PAYMENT_COMPLETE: 'payment-complete',
  PAYMENT_STATUS: 'payment-status',
  BULK_PAYMENTS: 'bulk-payments',
  PAYMENT_ROUTE: 'payment/:id',
  PAYMENT: 'payment',
  CODI_ACCOUNT: 'codi-account',
  STORE_LIST: 'stores',
  MY_DETAILS: 'my-details',
  REPORTS: 'reports',
  USER_LIST: 'users',
  MERCHANT_ENROLLMENT: 'enrollment',
  MAIN_PAGE: '',
  DASHBOARD: 'dashboard',
  REFUNDS: 'refunds',
  PARTNERS: 'partners',
  BILLING : 'billing'
};

export default Route;
