import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CountriesEnum } from '../../../core/enums/countries.enum';
import { AddMxpStoreRequest, MxpStoreGrpcClient } from '@rezolved/mxp-proto';
import { AuthService } from '@rezolved/auth';
import { User } from '@auth0/auth0-spa-js';
import { finalize } from 'rxjs/operators';
import { BaseDropDownItem, BaseDropDownItemUtils } from '@rezolved/ui';
import { MxpSnackBarService } from '../../../core/services/mxp-snackbar.service';
import { NumberPatternEnum } from 'libs/ui/src/lib/enums/number-pattern.enum';
import { StringValue } from '@ngx-grpc/well-known-types';

@Component({
  selector: 'mxp-store-create',
  templateUrl: './store-create.component.html',
  styleUrls: ['./store-create.component.scss'],
})
export class StoreCreateComponent implements OnInit {
  form: FormGroup;
  user!: User;
  countries: BaseDropDownItem[];
  isLoading = false;
  phoneFieldHasErrors = false;
  pattern = NumberPatternEnum.POSITIVE_DECIMAL;

  constructor(
    private dialogRef: MatDialogRef<StoreCreateComponent>,
    private mxpStoreGrpcClient: MxpStoreGrpcClient,
    private authService: AuthService,
    private mxpSnackBarService: MxpSnackBarService,
  ) {
    this.countries = BaseDropDownItemUtils.transformEnum(CountriesEnum);
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      street1: new FormControl('', Validators.required),
      street2: new FormControl(''),
      city: new FormControl('', Validators.required),
      stateProvince: new FormControl(''),
      postalCode: new FormControl('', Validators.compose([Validators.required, Validators.pattern('\\d+'), Validators.maxLength(5)])),
      country: new FormControl('', Validators.required),
      callbackurl: new FormControl('', Validators.compose([Validators.pattern(
        '^([a-zA-Z]+:\\/\\/)' + // protocol
        '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
        '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-zA-Z\\d_]*)?$')]))
    });

    this.form.get('phone')?.valueChanges.subscribe((x) => {
      this.phoneFieldHasErrors = false;
    });
  }

  ngOnInit(): void {
    this.authService.user$.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }

  get country(): string {
    return this.form.get(`country`)?.value || ``;
  }

  handlePhoneFieldError() {
    this.phoneFieldHasErrors = true;
  }

  onSave(): void {
    this.isLoading = true;
    const { name, street1, street2, city, stateProvince, postalCode, country, phone } = this.form.value;
    let callbackWebhookUrl: StringValue = new StringValue();
    callbackWebhookUrl.value = this.form.get('callbackurl')?.value;
    if (this.user) {
      const newStore = new AddMxpStoreRequest({
        name,
        street1,
        street2,
        city,
        state: stateProvince,
        zip: postalCode,
        country,
        phone,
        callbackWebhookUrl: callbackWebhookUrl
      });

      this.mxpSnackBarService
        .wrapApiCall('SNACKBAR.ADD_STORE', this.mxpStoreGrpcClient.addStore(newStore))
        .subscribe(
          (response) => {
            this.dialogRef.close(response);
          },
          (error) => console.log(error),
          () => (this.isLoading = false),
        );
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
