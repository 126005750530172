import { Component, OnInit } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { BasicButtonDirective } from '../../abstract/basic-button';

@Component({
  selector: 'rezolve-secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrls: ['./secondary-button.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SecondaryButtonComponent }],
})
export class SecondaryButtonComponent extends BasicButtonDirective implements OnInit {
  ngOnInit(): void {
    this.buttonClass = `secondary ${this.classList}`;
  }
}
