import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';

import { ModalService, UiModule } from '@rezolved/ui';
import { AuthHttpInterceptor, AuthModule, AuthSettings } from '@rezolved/auth';
import { environment } from '../environments/environment';
import { CommonModule, DatePipe, Location } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { WelcomebackComponent } from './components/welcomeback/welcomeback.component';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { MatNativeDateModule, MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './core/store';
import { MatDialogModule } from '@angular/material/dialog';
import { MyDetailsModule } from './components/my-details/my-details.module';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { ReportsComponent } from './components/reports/reports.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ReportsTableComponent } from './components/reports/reports-table/reports-table.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { UsersTableComponent } from './components/user/user-list/users-table/users-table.component';
import { UsersComponent } from './components/user/user-list/users.component';
import { GRPC_INTERCEPTORS, GrpcCoreModule } from '@ngx-grpc/core';
import { GrpcWebClientModule, GrpcWebClientSettings } from '@ngx-grpc/grpc-web-client';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AuthGrpcInterceptor } from '../../../../libs/feature/auth/src/lib/auth.grpc.interceptor';
import {
  GRPC_MXP_ACCOUNT_DETAILS_GRPC_CLIENT_SETTINGS,
  GRPC_MXP_PORTAL_ENROLLMENT_GRPC_CLIENT_SETTINGS,
  GRPC_MXP_PAYMENT_REPORT_GRPC_CLIENT_SETTINGS,
  GRPC_MXP_PARTNER_GRPC_CLIENT_SETTINGS,
  GRPC_MXP_PAYMENT_GRPC_CLIENT_SETTINGS,
  GRPC_MXP_BULK_PAYMENT_GRPC_CLIENT_SETTINGS,
  GRPC_MXP_PSP_GRPC_CLIENT_SETTINGS,
  GRPC_MXP_STORE_GRPC_CLIENT_SETTINGS,
  GRPC_MXP_USER_GRPC_CLIENT_SETTINGS,
  GRPC_MXP_REPORT_GRPC_CLIENT_SETTINGS,
  GRPC_MXP_BILLING_GRPC_CLIENT_SETTINGS
} from '@rezolved/mxp-proto';
import { PspsModule } from './components/psps-module/psps.module';
import { StoresModule } from './components/stores-module/stores.module';
import { PaymentsModule } from './components/payments-module/payments.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CustomPipesModule } from './core/pipes/custom-pipes.module';
import { UserGroupModule } from './shared/components/user-group/user-group.module';
import { StoreCreateComponent } from './components/stores-module/store-create/store-create.component';
import { UserCreateComponent } from './components/user/user-create/user-create.component';
import { UserEditComponent } from './components/user/user-edit/user-edit.component';
import { UserDeleteComponent } from './components/user/user-delete/user-delete.component';
import { MerchantEnrollmentModule } from './components/merchant-enrollment/merchant-enrollment.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { RefundsComponent } from './components/refunds/refunds.component';
import { PartnersComponent } from './components/partners/partners.component';
import { BillingComponent } from './components/billing/billing.component';
import { MatGridListModule} from "@angular/material/grid-list";
import { MatDatepickerModule } from "@angular/material/datepicker"
import { MatCardModule } from "@angular/material/card";
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MonthPickerComponent } from './components/billing/month-picker/month-picker/month-picker.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    WelcomebackComponent,
    ReportsComponent,
    ReportsTableComponent,
    UsersComponent,
    UsersTableComponent,
    StoreCreateComponent,
    UserCreateComponent,
    UserEditComponent,
    UserDeleteComponent,
    DashboardComponent,
    RefundsComponent,
    PartnersComponent,
    BillingComponent,
    MonthPickerComponent
  ],
  imports: [
    MerchantEnrollmentModule,
    BrowserModule,
    UiModule,
    CommonModule,
    AppRoutingModule,
    MatButtonModule,
    MatTooltipModule,
    MatOptionModule,
    MatRippleModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSnackBarModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    PspsModule,
    StoresModule,
    PaymentsModule,
    CustomPipesModule,
    UserGroupModule,
    MatGridListModule,
    MatDatepickerModule,
    MatCardModule,
    MatMomentDateModule,
    StoreModule.forRoot(reducers, { metaReducers: metaReducers }),
    TranslateModule.forRoot({
      defaultLanguage: environment.defaultLang,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      extend: true,
    }),
    MyDetailsModule,
    AuthModule.forRoot(environment.authSettings as AuthSettings),
    RouterModule.forRoot([], { initialNavigation: 'enabled' }),
    MatDialogModule,
    FlexModule,
    FlexLayoutModule,
    MatIconModule,
    MatSortModule,
    MatTableModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    SharedModule,
    MatExpansionModule,
    GrpcCoreModule.forRoot(),
    GrpcWebClientModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [
    Location,
    DatePipe,
    TranslateService,
    ModalService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: GRPC_INTERCEPTORS, useClass: AuthGrpcInterceptor, multi: true },
    {
      provide: GRPC_MXP_PAYMENT_GRPC_CLIENT_SETTINGS,
      useValue: {
        host: environment.paymentUrl,
      } as GrpcWebClientSettings,
    },
    {
      provide: GRPC_MXP_BULK_PAYMENT_GRPC_CLIENT_SETTINGS,
      useValue: {
        host: environment.paymentUrl,
      } as GrpcWebClientSettings,
    },
    {
      provide : GRPC_MXP_PAYMENT_REPORT_GRPC_CLIENT_SETTINGS,
      useValue: {
        host : environment.paymentUrl,
      } as GrpcWebClientSettings,
    },
    {
      provide: GRPC_MXP_BILLING_GRPC_CLIENT_SETTINGS,
      useValue: {
        host: environment.paymentUrl,
        
      } as GrpcWebClientSettings
    },
    {
      provide: GRPC_MXP_ACCOUNT_DETAILS_GRPC_CLIENT_SETTINGS,
      useValue: {
        host: environment.identityUrl,
      } as GrpcWebClientSettings,
    },
    {
      provide: GRPC_MXP_REPORT_GRPC_CLIENT_SETTINGS,
      useValue: {
        host: environment.identityUrl,
      } as GrpcWebClientSettings,
    },
    {
      provide: GRPC_MXP_PSP_GRPC_CLIENT_SETTINGS,
      useValue: {
        host: environment.identityUrl,
      } as GrpcWebClientSettings,
    },
    {
      provide: GRPC_MXP_PARTNER_GRPC_CLIENT_SETTINGS,
      useValue: {
        host: environment.identityUrl,
      } as GrpcWebClientSettings,
    },
    {
      provide: GRPC_MXP_STORE_GRPC_CLIENT_SETTINGS,
      useValue: {
        host: environment.identityUrl,
      } as GrpcWebClientSettings,
    },
    {
      provide: GRPC_MXP_USER_GRPC_CLIENT_SETTINGS,
      useValue: {
        host: environment.identityUrl,
      } as GrpcWebClientSettings,
    },
    {
      provide: GRPC_MXP_PORTAL_ENROLLMENT_GRPC_CLIENT_SETTINGS,
      useValue: {
        host: environment.identityUrl,
        
      } as GrpcWebClientSettings
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
