import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@rezolved/auth';
import { LanguageEnum } from '@rezolved/mxp-proto';
import { ClaimKeys } from '../constants/ClaimKeys';

@Injectable({
  providedIn: 'root',
})
export class MxpTranslationService {
  constructor(private readonly translateService: TranslateService, private readonly authService: AuthService) {}

  updateTranslationLanguage(language: LanguageEnum) {
    switch (language) {
      case LanguageEnum.EN:
        this.translateService.use('en');
        break;
      case LanguageEnum.ES:
        this.translateService.use('es');
        break;
      default:
        console.error(`Translation for language '${LanguageEnum[language]}' is not supported`);
        break;
    }
  }

  loadTranslation() {
    this.authService.idTokenClaims$.subscribe((tokenClaims) => {
      if (!tokenClaims) {
        return;
      }
      const mxpLanguage = (tokenClaims[ClaimKeys.MXP_LANGUAGE] as LanguageEnum) ?? LanguageEnum.EN;
      this.updateTranslationLanguage(mxpLanguage);
    });
  }

  getTranslation(key: string): string {
    return this.translateService.instant(key);
  }
}
