<div class="full-address-container">
  <div class="apt-number-container">
    <rezolve-basic-input
      [componentName]="componentName + '-apt-number'"
      [label]="flatNumberLabel"
      [(ngModel)]="flatNumber"
      (ngModelChange)="flatNumberChanged($event)">
    </rezolve-basic-input>
  </div>
  <div class="address-container">
    <rezolve-basic-input
      #addressInput
      [validators]="[validateInput]"
      [componentName]="componentName + '-address'"
      [label]="addressLabel"
      [(ngModel)]="address"
      [required]="required">
    </rezolve-basic-input>
  </div>
</div>
