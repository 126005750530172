<div>
  <div
    *ngIf="!rezolveFile"
    class="dropzone"
    rezolveDragAndDrop
    (dropped)="handleUpload($event)"
    fxLayout="row"
    (change)="handleUpload(dropzone && dropzone.files)"
    [attr.data-cy]="generateDataCyId('-upload-container')">
    <input #dropzone [accept]="acceptedFileExtensions" hidden type="file" [required]="required" />
    <span
      *ngFor="let text of dropzoneText"
      [ngClass]="{ 'link-label': text.isClickable }"
      (click)="text.isClickable && dropzone.click()">
      {{ text.label }}
    </span>
  </div>
  <div *ngIf="rezolveFile" class="uploaded-files" fxLayout="row">
    <mat-icon class="description">description</mat-icon>
    <span>{{ rezolveFile.file.name }}</span>
    <mat-icon class="close" fxFlex="1 0 2%" (click)="onRemove()">close</mat-icon>
  </div>
</div>
