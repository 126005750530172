export enum FileTypeEnum {
  CSV,
  PNG,
  KEY,
  SVG,
  TXT,
  RSA_KEY,
  PUB_KEY,
}

interface FileType {
  extension: string;
  MIMEType: string;
}

export function getFileType(type: FileTypeEnum): FileType {
  switch (type) {
    case FileTypeEnum.CSV:
      return {
        extension: '.csv',
        MIMEType: 'text/csv',
      };
    case FileTypeEnum.PNG:
      return { extension: '.png', MIMEType: 'image/png' };
    case FileTypeEnum.KEY:
      return { extension: '.key', MIMEType: '' };
    case FileTypeEnum.RSA_KEY:
      return { extension: '.pkcs8', MIMEType: 'application/x-x509-ca-cert' };
    case FileTypeEnum.PUB_KEY:
      return { extension: '.pem', MIMEType: 'application/x-x509-ca-cert' };
    case FileTypeEnum.SVG:
      return { extension: '.svg', MIMEType: 'image/svg+xml' };
    case FileTypeEnum.TXT:
      return { extension: '.txt', MIMEType: 'text/plain' };
    default:
      return { extension: '', MIMEType: '' };
  }
}
