export enum NumberPatternEnum {
  ALL = '-?[0-9]*',
  POSITIVE_ONLY = '[0-9]*',
  DECIMAL = '\\-?\\d*\\.?\\d{1,2}',
  POSITIVE_DECIMAL = '\\d*\\.?\\d{1,2}'
}

export const NumberPatternOptions : NumberPatternEnum[] = [
  NumberPatternEnum.ALL,
  NumberPatternEnum.POSITIVE_ONLY,
  NumberPatternEnum.DECIMAL,
  NumberPatternEnum.POSITIVE_DECIMAL
]
