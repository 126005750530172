import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import {
  GetByIdRequest,
  PaymentTypeEnum,
  MxpPaymentGrpcClient,
  MxpPartnerGrpcClient,
  MxpUserGrpcClient,
  MakeQRPaymentRequestV3,
  MakeNotificationPaymentRequestV3
} from '@rezolved/mxp-proto';
import { first } from 'rxjs/operators';
import { AuthService } from '@rezolved/auth';
import { Router } from '@angular/router';
import Route from '../../../core/constants/Route';
import { BaseDropDownItem, BaseDropDownItemUtils } from '@rezolved/ui';
import { MxpSnackBarService } from '../../../core/services/mxp-snackbar.service';
import { IAppState } from '../../../core/store/app.interface';
import { Store } from '@ngrx/store';
import { requestPayment } from '../../../core/store/actions/app.actions';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { NumberPatternEnum } from '../../../../../../../libs/ui/src/lib/enums/number-pattern.enum';
import { Empty, FloatValue, Timestamp } from '@ngx-grpc/well-known-types';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

const MIN_VAL = 1;
const MINIMUM_VALUE_ERR_MESSAGE = `Minimum value is ${MIN_VAL}`;
const MINIMUM_DESC_LENGTH = 255;

@Component({
  selector: 'mxp-rezolve-request-payment-details',
  templateUrl: './payments-request.component.html',
  styleUrls: ['./payments-request.component.scss'],
  providers:[
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class PaymentsRequestComponent {
  store = 'store';
  psp = 'psp';
  pspList: BaseDropDownItem[] = [];
  storeList: BaseDropDownItem[] = [];
  paymentTypes: BaseDropDownItem[];
  qrPayment = false;
  isAdmin = false;
  form: FormGroup;
  minValue = MIN_VAL;
  bulkFeatureDisabled = environment.bulkPaymentDisabled;
  pattern = NumberPatternEnum.POSITIVE_DECIMAL;
  @Input() maxDescLength = MINIMUM_DESC_LENGTH;
  showSpinner = false;
  reportLabel = 'DATE_RANGE';
  expiryDate: Date|undefined = undefined;
  currentType: PaymentTypeEnum = PaymentTypeEnum.QR;

  validator(val: number | string) {
    return val < MIN_VAL ? MINIMUM_VALUE_ERR_MESSAGE : null;
  }

  getByIdRequest = (id: string) => {
    return new GetByIdRequest({ id });
  };

  constructor(
    private mxpPaymentGrpcClient: MxpPaymentGrpcClient,
    mxpUserGrpcClient: MxpUserGrpcClient,
    private authService: AuthService,
    private router: Router,
    mxpPartnerGrpcClient: MxpPartnerGrpcClient,
    private mxpSnackBarService: MxpSnackBarService,
    private readonly state: Store<IAppState>
  ) {
    this.state.dispatch(requestPayment());
    this.paymentTypes = [
      { key: PaymentTypeEnum[PaymentTypeEnum.QR], value: PaymentTypeEnum.QR },
      { key: PaymentTypeEnum[PaymentTypeEnum.NOTIFICATION], value: PaymentTypeEnum.NOTIFICATION }
    ];
    this.storeList = BaseDropDownItemUtils.transformList([this.store]);
    this.form = new FormGroup(
      {
        store: new FormControl({ value: this.store, disabled: true }, Validators.required),
        psp: new FormControl({ value: this.psp, disabled: true }, Validators.required),
        paymentType: new FormControl(PaymentTypeEnum.QR, Validators.required),
        contactInfo: new FormControl(
          '',
          Validators.compose([Validators.maxLength(40)])),
        phone: new FormControl(''),
        amountQr: new FormControl(
          '',
          Validators.compose([Validators.min(0.01), Validators.max(9999)]),
        ),
        amountPn: new FormControl(
          '',
          Validators.compose([Validators.min(0.01), Validators.max(9999)]),
        ),
        description: new FormControl(
          '',
          Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern(/^[a-zA-Z0-9 $%,.:;'!@#&*()\/_]+$/)])),
        reference:  new FormControl(
          '',
          Validators.compose([Validators.maxLength(7), Validators.pattern('\\d+')])),
        extendedReference: new FormControl(
          '',
          Validators.compose([Validators.maxLength(40)]))
      },
      {
        validators: this.toggleValidator(Validators.required, ['phone'])
      },
    );
    this.form.get('paymentType')?.valueChanges.subscribe((value) => {
      this.currentType = value;
      if (value === PaymentTypeEnum.NOTIFICATION) {
        this.form.get('phone')?.enable();
        this.form.get('amountPn')?.enable();
        this.form.get('amountQr')?.disable();
      } else {
        this.form.get('phone')?.disable();
        this.form.get('amountPn')?.disable();
        this.form.get('amountQr')?.enable();
      }
      this.form.get('phone')?.reset();
    });

    this.authService.user$.subscribe((user) => {
      if (!user) { 
        return 
      }
      const partnerId = user['https://rezolve.com/mxp_partner_id'];
      const userId = user['https://rezolve.com/mxp_user_id'];
      if (partnerId) {
        mxpPartnerGrpcClient
          .getCurrentPartner(new Empty())
          .subscribe((partnerDetails) => {
            if (partnerDetails && partnerDetails.accountDetails) {
              this.psp = partnerDetails.accountDetails?.name || this.psp;
              this.pspList = BaseDropDownItemUtils.transformList([this.psp]);

              this.form.get('psp')?.patchValue(this.psp);
            }
          });
      }
      if (userId) {
        mxpUserGrpcClient.getMxpUser(new GetByIdRequest({ id: userId })).subscribe((userInfo) => {
          this.store = userInfo.storeName?.value || this.store;
          this.storeList = BaseDropDownItemUtils.transformList([this.store]);
          this.form.get('store')?.patchValue(this.store);
        });
      }
    });
  }

  get isQrPayment(): boolean {
    return this.form.get(`paymentType`)?.value === PaymentTypeEnum.QR;
  }

  dateChanged(date: Date) {
    this.expiryDate = date;
  }

  getExpiryDateTimeStamp(date: Date|undefined) {
    if(date == undefined) {
      return undefined;
    }
    else {
      return Timestamp.fromISOString((date as Date).toISOString());
    }
  }

  onSubmit() {
    this.showSpinner = true;
    this.authService.user$.pipe(first()).subscribe((user) => {
      if (user) {
        let referenceValue = this.form.get('reference')?.value;
        const commonRequest = {
          description: this.form.get('description')?.value,
          reference: referenceValue == '' ? 0 : referenceValue,
          extendedReference: this.form.get('extendedReference')?.value,
          expiryDate: this.getExpiryDateTimeStamp(this.expiryDate),
          contactInfo : this.form.get('contactInfo')?.value
        };

        if (this.isQrPayment) {
          let amountValue = this.form.get('amountQr')?.value;
          const request = new MakeQRPaymentRequestV3({
            ...commonRequest,
            amount:  amountValue == '' ? undefined : { value: amountValue } as FloatValue
          });
          
          this.mxpSnackBarService
            .wrapApiCall('SNACKBAR.MAKE_QR_PAYMENT', this.mxpPaymentGrpcClient.makeQRPaymentV3(request))
            .subscribe(
              (response) =>
                this.router.navigate([Route.PAYMENT, response.corePaymentId], {
                  state: {
                    configuration: {
                      id: response.corePaymentId,
                      qr: response.qrData,
                      price: this.form.get('amountQr')?.value,
                      description: this.form.get('description')?.value,
                      reference: this.form.get('reference')?.value,
                      type: PaymentTypeEnum.QR,
                      expiryDate: this.getExpiryDateTimeStamp(this.expiryDate)
                    }
                  }
                }),
              (error) => this.showSpinner = false,
              () =>  this.showSpinner = false
            );
        } else {
          const phoneValue = this.form.get('phone')?.value.substring(3);
          const request = new MakeNotificationPaymentRequestV3({
            ...commonRequest,
            phone: phoneValue,
            amount: this.form.get('amountPn')?.value
          });
          this.mxpSnackBarService
            .wrapApiCall(
              'SNACKBAR.MAKE_NOTIFICATION_PAYMENT',
              this.mxpPaymentGrpcClient.makeNotificationPaymentV3(request)
            )
            .subscribe(
              ({ corePaymentId }) =>
                this.router.navigate([Route.PAYMENT, corePaymentId], {
                  state: {
                    configuration: {
                      id: corePaymentId,
                      price: this.form.get('amountPn')?.value,
                      description: this.form.get('description')?.value,
                      reference: this.form.get('reference')?.value,
                      type: PaymentTypeEnum.NOTIFICATION,
                      expiryDate: this.getExpiryDateTimeStamp(this.expiryDate),
                      mobileNumber: this.form.get('phone')?.value
                    }
                  }
                }),
              (error) => this.showSpinner = false,
              () => this.showSpinner = false
            );
        }
      }
    });
  }

  toggleValidator(validator: ValidatorFn, controls: string[] = []): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control) return null;
      const formGroup = control as FormGroup;
      return formGroup && (this.currentType == PaymentTypeEnum.QR || controls.some((form) => !validator(formGroup.controls[form])))
        ? null
        : {
            toggleValidator: true
          };
    };
  }
}