import { Component } from '@angular/core';

@Component({
  selector: 'mxp-account',
  templateUrl: './psp.component.html',
  styleUrls: ['./psp.component.scss'],
})
export class PspComponent {
  // @Input() psp!: MxpPsp;
  // @Output() addStore = new EventEmitter<MxpPsp>();

  onAddStore(): void {
    // this.addStore.emit(this.psp);
  }

  onEnableChange(): void {
    // this.psp.enabled = !this.psp.enabled;
  }
}
