import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dialCode',
})
// eslint-disable-next-line @angular-eslint/use-pipe-transform-interface
export class DialCodePipe implements PipeTransform {
  transform(value: string): string {
    return ' +' + value;
  }
}
