import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mxp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() header?: string;
  @Input() caption?: string;
  @Input() buttonText?: string;
  @Input() buttonSuffixIcon?: string;
  @Input() buttonPrefixIcon?: string;
  @Output() buttonClicked: EventEmitter<MouseEvent> = new EventEmitter();

  onClick($event: MouseEvent) {
    this.buttonClicked.emit($event);
  }
}
