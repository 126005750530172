import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PspComponent } from './psp/psp.component';
import { PspsComponent } from './psps/psps.component';
import { PspCreationComponent } from './psp-creation/psp-creation.component';
import { UiModule } from '@rezolved/ui';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UserGroupModule } from '../../shared/components/user-group/user-group.module';
import { CustomPipesModule } from '../../core/pipes/custom-pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { PspStoreComponent } from './psp-store/psp-store.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PspUpdateComponent } from './psp-update/psp-update.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [PspComponent, PspsComponent, PspCreationComponent, PspStoreComponent, PspUpdateComponent],
  exports: [PspComponent, PspsComponent, PspCreationComponent, PspStoreComponent, PspUpdateComponent],
  imports: [
    CommonModule,
    UiModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    UserGroupModule,
    CustomPipesModule,
    TranslateModule.forChild(),
    MatDialogModule,
    MatProgressSpinnerModule
  ],
})
export class PspsModule {}
