<div class="month-range-picker-container" fxLayout="row" >
    <div fxFlex="10" fxFlex.xs="15" fxLayout="column" fxLayoutAlign="space-evenly flex-end"
        fxLayoutAlign.gt-lg="space-evenly center" *ngIf="enableMonthNavigation" class="left-month-nav">
        <button mat-button primary
            class="month-navigation-button mat-focus-indicator mat-button mat-button-base basic-button primary medium"
            (click)="prevStartMonth()">
            <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true"
                data-mat-icon-type="font">chevron_left</mat-icon>
        </button>
    </div>
    <div fxFlex="80" fxFlex.xs="70" fxLayout="row" fxLayoutAlign="space-between" class="custom-calender-input">
        <mat-form-field class="example-full-width" class="month-range-picker">
            <mat-label>{{ labelText }}</mat-label>
            <input readonly matInput type="text" placeholder="" [value]="monthRangeValueString" #inputMonthRange />
            <button mat-icon-button matSuffix class="calender-toggle-button"
                (click)="handleOpenPanelClick($event, inputMonthRange)">
                <mat-icon mat-icon-button="" type="button" class="mat-focus-indicator mat-icon-button mat-button-base"
                    ng-reflect-disabled="false" aria-haspopup="dialog" aria-label="Open calendar" tabindex="0"><span
                        class="mat-button-wrapper"><svg viewBox="0 0 24 24" width="24px" height="24px"
                            fill="currentColor" focusable="false"
                            class="mat-datepicker-toggle-default-icon ng-star-inserted">
                            <path
                                d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z">
                            </path>
                        </svg>
                    </span>
                    <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"
                        ng-reflect-disabled="false" ng-reflect-centered="true"
                        ng-reflect-trigger="[object HTMLButtonElement]">
                    </span>
                    <span class="mat-button-focus-overlay"></span>
                </mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div fxFlex="10" fxFlex.xs="15" fxLayout="column" fxLayoutAlign="space-evenly flex-start"  fxLayoutAlign.gt-lg="space-evenly center"
        *ngIf="enableMonthNavigation" class="right-month-nav">
        <button mat-button primary
            class="month-navigation-button mat-focus-indicator mat-button mat-button-base basic-button primary medium"
            (click)="nextEndMonth()">
            <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true"
                data-mat-icon-type="font">chevron_right</mat-icon>
        </button>
    </div>
</div>
<div class="outerCard" *ngIf="showMonthsPanel">
    <div class="topPanel">
        <button class="prevYearButton calender-button" (click)="decrementYear()">
            <i class="arrow left"></i>
        </button>
        <span class="yearLabel">{{ years[currentYearIndex] }}</span>
        <button class="nextYearButton calender-button" (click)="incrementYear()">
            <i class="arrow right"></i>
        </button>
    </div>
    <div>
        <div (click)="onMonthItemClicked(i)" class="monthItem" *ngFor="let month of monthDataSlice; let i = index"
            [ngClass]="{ isEdge: rangeIndexes[0]===globalIndexOffset+i || rangeIndexes[1]===globalIndexOffset+i, notCurrentYear: currentYearIndex===0? i > 11:(i < 6 || i > 17)}">
            <div class="monthItemHighlight" [ngClass]="{ inRange: month.isInRange, 
                    isLowerEdge: month.isLowerEdge === month.isUpperEdge === true ? false : month.isLowerEdge, 
                    isUpperEdge: month.isUpperEdge === month.isLowerEdge === true ? false : month.isUpperEdge}">
                {{ month.monthName }}
            </div>
        </div>
    </div>
    <div class="apply-button-div" fxLayout="row">
        <div class="error" fxFlex="80" fxLayout="column" fxLayoutAlign="center" *ngIf="hasError">
            <p>{{ requiredMonthRangeError }}</p>
        </div>
        <div class="action-buttons" fxFlex="20" fxLayout="column" fxLayoutAlign="center">
            <button class="apply-button" mat-button (click)="applyDates()"> {{ applyButtonText }} </button>
        </div>
    </div>
</div>