import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Address } from '../../../interfaces/address';
import { NamedComponent } from '../../abstract/named-component';
import { BasicListComponent } from '../basic-list/basic-list.component';

@Component({
  selector: 'rezolve-address-list',
  templateUrl: './address-list.component.html',
})
export class AddressListComponent extends NamedComponent {
  @Input() items: Address[] = [];
  @Input() showRemove = false;
  @Input() showEdit = false;
  @Input() showPagination = false;
  @Input() multiSelection = false;

  @Output() selectionChanged = new EventEmitter<Address[]>();
  @Output() itemEdited = new EventEmitter<Address>();

  @ViewChild('basicList') basicList!: BasicListComponent<Address>;

  constructor() {
    super();
    this.componentName = 'address-list';
  }

  areEqual(addressA: Address, addressB: Address): boolean {
    return addressA.id === addressB.id;
  }
}
