import { AfterContentChecked, AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';
import { TabItemComponent } from '../../atoms/tabs/tab-item/tab-item.component';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
@Component({
  selector: 'rezolve-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit, AfterContentChecked {
  @ContentChildren(TabItemComponent)
  tabs: QueryList<TabItemComponent> = new QueryList<TabItemComponent>();

  tabItems$: Observable<TabItemComponent[]> = new Observable<TabItemComponent[]>();

  selectedTab: TabItemComponent | undefined;

  ngAfterContentInit(): void {
    this.tabItems$ = this.tabs.changes
      .pipe(startWith(''))
      .pipe(delay(0))
      .pipe(map(() => this.tabs.toArray()));
  }

  ngAfterContentChecked() {
    if (!this.selectedTab) {
      Promise.resolve().then(() => {
        this.selectedTab = this.tabs.first;
      });
    }
  }

  selectTab(tabItem: TabItemComponent) {
    if (this.selectedTab === tabItem) {
      return;
    }

    if (this.selectedTab) {
      tabItem.isSelected = false;
    }

    this.selectedTab = tabItem;

    tabItem.isSelected = true;
  }
}
