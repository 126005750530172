/* eslint-disable @typescript-eslint/no-explicit-any */
import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  Input,
  Output,
  TemplateRef,
  EventEmitter,
  Optional,
  ElementRef,
  Self,
  ViewEncapsulation,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, NgControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FloatLabelType, MatFormFieldControl } from '@angular/material/form-field';
import { CustomFormField } from '../../abstract/custom-form-field';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { BaseDropDownItem } from '../../../interfaces/drop-down/base-drop-down-item';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'rezolve-drop-down',
  templateUrl: './basic-drop-down-input.component.html',
  styleUrls: ['./basic-drop-down-input.component.scss', '../../../styles/common.styles.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: BasicDropDownInputComponent }],
  encapsulation: ViewEncapsulation.None,
})
export class BasicDropDownInputComponent<T> extends CustomFormField<T> implements OnInit, OnDestroy {
  @Input() items: BaseDropDownItem[] = [];
  @Input() actionItemTemplate: TemplateRef<any> | null = null;
  @Input() optionTemplate: TemplateRef<any> | null = null;
  @Input() selectTriggerTemplate: TemplateRef<any> | null = null;
  @Input() isActionItemVisible = false;
  @Input() errorDescriptions: { [key: string]: string } = {};
  @Input() validators: ValidatorFn[] = [];
  @Input() floatLabel: FloatLabelType = 'always';
  @Input() isMultiSelect : boolean = false;
  @Input() allSelected : boolean = false;
  @Output() valueChange: EventEmitter<T> = new EventEmitter();
  @Output() actionItemClick: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() selectAllChanged : EventEmitter<MatCheckboxChange> = new EventEmitter();
  formControl : FormControl = new FormControl(null);
  @ViewChild('paymentStatusSelect') select!: MatSelect;

  stateChangeTriggeredBySelectAll : boolean = false;
  optionsCount : number = 0;
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
  ) {
    super(ngControl);
    this.componentName = 'drop-down';
    this.controlType = `rezolve-${this.componentName}-control`;
    this.id = `rezolve-${this.componentName}-${BasicDropDownInputComponent.nextId++}`;
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.focusMonitor.monitor(this.elRef, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    if (this.required) {
      this.validators.push(Validators.required);
    }

    this.ngControl?.control?.validator && this.validators.push(this.ngControl.control.validator);
    this.formControl.setValidators(this.validators);
  }

  handleActionItemClick($event: MouseEvent): void {
    this.actionItemClick.emit($event);
  }

  handleSelectionChange(change: MatSelectChange): void {
    let newStatus = true;
    for (let item of this.select.options) {
      if (item.value != undefined) {
        if (!item.selected) {
          newStatus = false;
          break;
        }
      }
    }
    this.allSelected = newStatus;
    if (this.stateChangeTriggeredBySelectAll && this.optionsCount != 0) {
      this.optionsCount -= 1;
      return;
    }
    this.stateChangeTriggeredBySelectAll = false;
    this.writeValue(change.value);
    this.valueChange.emit(change.value);
  }

  toggleAllSelection($event: MatCheckboxChange): void {
    this.stateChangeTriggeredBySelectAll = true;
    this.optionsCount = 0;
    if (this.allSelected) {
      for (let index = 0; index < this.select.options.length - 1; index++) {
        let element = this.select.options.get(index);
        if (!element?.selected) {
          this.optionsCount++;
          element?.select();
        }
      }
    } else {
      for (let index = 0; index < this.select.options.length - 1; index++) {
        let element = this.select.options.get(index);
        if (element?.selected) {
          this.optionsCount++;
          element?.deselect();
        }
      }
    }
    this.stateChangeTriggeredBySelectAll = !this.allSelected ? false : true;
    this.selectAllChanged.emit($event);
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elRef);
  }

  getState(): T | null {
    return this.formControl.value;
  }

  setState(value: T | null): void {
    this.formControl.setValue(value);
  }

  disableControl(disabled: boolean): void {
    disabled ? this.formControl.disable() : this.formControl.enable();
  }

  getFirstError(errors: ValidationErrors): string {
    return Object.keys(errors)[0];
  }

  get errorState(): boolean {
    return this.formControl.valid && this.isTouched();
  }

  get isInvalid(): boolean {
    if (!!this.formControl.errors && !!this.errorDescriptions[this.getFirstError(this.formControl.errors)]) {
      this.errorMessage = this.errorDescriptions[this.getFirstError(this.formControl.errors)];
      return true;
    }
    this.errorMessage = null;
    return false;
  }
}
