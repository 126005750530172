import { Component, Input } from '@angular/core';

@Component({
  selector: 'rezolve-navbar-section',
  templateUrl: './navbar-section.component.html',
  styleUrls: ['./navbar-section.component.scss'],
})
export class NavbarSectionComponent {
  @Input() public name = '';
}
