<div *ngIf="!error" [ngSwitch]="paymentType">
  <mxp-payment-notification-sent
    [paymentSentConfiguration]="paymentSentConfiguration!"
    *ngSwitchCase="PaymentTypeEnum.NOTIFICATION"></mxp-payment-notification-sent>
  <mxp-payment-qr
    [paymentSentConfiguration]="paymentSentConfiguration!"
    *ngSwitchCase="PaymentTypeEnum.QR"></mxp-payment-qr>
  <div *ngSwitchDefault class="container"><mat-spinner></mat-spinner></div>
</div>
<div *ngIf="error" class="container">
  <span class="title">
    {{ 'PAYMENT_DETAILS_ERROR' | translate }}
  </span>
  <rezolve-primary-button (handleClick)="requestNewPayment()">
    {{ 'REQUEST_NEW_PAYMENT' | translate }}
  </rezolve-primary-button>
</div>
