<div class="main-container">
  <h2 mat-dialog-title>{{ 'DELETE_USER' | translate }}</h2>
  <mat-dialog-content class="mat-typography">
    {{ 'ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_USER' | translate }}
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="end">
    <rezolve-secondary-button class="cancel-button" classList="medium" mat-dialog-close>
      {{ 'NO' | translate }}</rezolve-secondary-button
    >
    <rezolve-primary-button classList="medium" (handleClick)="deleteMxpUser()">
      {{ 'YES' | translate }}</rezolve-primary-button
    >
  </mat-dialog-actions>
  <div class="spinner-container" *ngIf="showSpinner"><mat-spinner></mat-spinner></div>
</div>
