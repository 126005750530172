<rezolve-basic-input
  [placeholder]="placeholder"
  class="rezolve-search-input"
  [class.rezolve-search-input-small]="!large"
  [class.rezolve-search-input-large]="large"
  [iconPrefix]="iconPrefix"
  [iconSuffix]="iconSuffix"
  [required]="required"
  [disabled]="disabled"
  [formControl]="search"
  (handleSuffixClick)="clear()"
  type="search">
</rezolve-basic-input>
