/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: This common component will be refactored
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from './base-modal.component';
import { BaseModalConfig } from './base-modal.config';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private dialogService: MatDialog) {}

  open<T, R = any>(dialog: ComponentType<T>, options?: BaseModalConfig): MatDialogRef<BaseModalComponent, R>;
  open<T, R = any>(dialog: TemplateRef<T>, options?: BaseModalConfig): MatDialogRef<BaseModalComponent, R>;
  open<T, R = any>(
    dialog: ComponentType<T> | TemplateRef<T>,
    options?: BaseModalConfig,
  ): MatDialogRef<BaseModalComponent, R>;
  open<T, R = any>(
    dialog: ComponentType<T> | TemplateRef<T>,
    options?: BaseModalConfig,
  ): MatDialogRef<BaseModalComponent, R> {
    const dialogRef = this.dialogService.open(BaseModalComponent, options);

    const baseModalComponent: any = dialogRef.componentInstance;
    if (dialog instanceof TemplateRef) {
      baseModalComponent.updateDialogContentTemplate(dialog, options?.data, options?.showCloseButton);
    } else {
      baseModalComponent.updateDialogContentComponent(dialog, options?.data, options?.showCloseButton);
    }
    if (options?.header) {
      if (options.header instanceof TemplateRef) {
        baseModalComponent.updateHeaderTemplate(options.header, options?.data, options?.showCloseButton);
      } else {
        baseModalComponent.updateHeaderComponent(options.header, options?.data, options?.showCloseButton);
      }
    }
    return dialogRef;
  }

  close() {
    this.dialogService.closeAll();
  }
}
