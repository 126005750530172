<mat-form-field class="rezolve-toggle" [attr.data-cy]="generateDataCyId('-form-field')">
  <input matInput type="text" [hidden]="true" />
  <div [ngClass]="!isSmall ? 'rezolve-toggle-container-large' : 'rezolve-toggle-container-small'">
    <mat-label
      *ngIf="label"
      [ngClass]="!isSmall ? 'rezolve-toggle-label-large' : 'rezolve-toggle-label-small'"
      [attr.data-cy]="generateDataCyId('-label')">
      {{ label }}
    </mat-label>
    <mat-slide-toggle
      [ngClass]="!isSmall ? 'rezolve-toggle-input-large' : 'rezolve-toggle-input-small'"
      [attr.data-cy]="generateDataCyId('-input')"
      [formControl]="toggleControl"
      [required]="required"
      (change)="onValueChange()">
    </mat-slide-toggle>
  </div>
  <span
    *ngIf="!isSmall && description"
    class="rezolve-toggle-description"
    [attr.data-cy]="generateDataCyId('-description')">
    {{ description }}
  </span>
  <span *ngIf="errorState && errorMessage" class="rezolve-toggle-error" [attr.data-cy]="generateDataCyId('-error')">
    {{ errorMessage }}
  </span>
</mat-form-field>
