import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Loader } from '@googlemaps/js-api-loader';
import { Address } from '../../../interfaces/address';
import { GooglePlace } from '../../../interfaces/google-place';
import { BasicInputComponent } from '../../atoms/basic-input/basic-input.component';

@Component({
  selector: 'rezolve-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: BasicInputComponent }],
})
export class AddressInputComponent extends BasicInputComponent<Address> implements OnInit, OnDestroy {
  @Input() apiKey = '';
  @Input() validateAddress = true;
  @Input() addressLabel = 'Address';
  @Input() flatNumberLabel = 'Flat/Apt #';
  @ViewChild('addressInput') addressInput!: BasicInputComponent<string>;

  invalidAddress = false;
  flatNumber = '';
  address = '';
  fullAddress?: Address;
  errorMessage = 'Invalid address';

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    protected focusMonitor: FocusMonitor,
    protected elRef: ElementRef<HTMLElement>,
  ) {
    super(ngControl, focusMonitor, elRef);
    this.componentName = 'address-input';
  }

  ngOnInit(): void {
    const loader = new Loader({
      apiKey: this.apiKey,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load().then((google) => {
      const autocomplete = new google.maps.places.Autocomplete(this.addressInput.inputField.nativeElement, {
        fields: ['formatted_address', 'name', 'address_components'],
        strictBounds: false,
        types: ['address'],
      });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        const newAddress = this.parsePlace(place);
        this.invalidAddress = !newAddress;
        if (!this.invalidAddress) {
          this.fullAddress = this.parsePlace(place);
          this.emitAddress(this.fullAddress, this.flatNumber);
        }
        this.addressInput.inputField.nativeElement.dispatchEvent(new Event('input'));
      });
    });
  }

  parsePlace(place: GooglePlace): Address | undefined {
    const street = this.getAddrComponent(place, 'route');
    const streetNumber = this.getAddrComponent(place, 'street_number');
    const zipCode = this.getAddrComponent(place, 'postal_code');
    if (this.validateAddress && !(street && streetNumber && zipCode)) {
      return;
    }

    return {
      zip: zipCode,
      state: this.getAddrComponent(place, 'administrative_area_level_1'),
      line_1: `${street || ''} ${streetNumber || ''}`.trim(),
      city: this.getAddrComponent(place, 'locality'),
      country: this.getAddrComponent(place, 'country', true),
    };
  }

  flatNumberChanged(flatNumber: string) {
    this.emitAddress(this.fullAddress, flatNumber);
  }

  getAddrComponent(place: GooglePlace, component: string, shortName?: boolean): string {
    let result;
    if (!place) {
      return '';
    }
    for (const addressComponent of place.address_components) {
      const addressType = addressComponent.types[0];
      if (addressType === component) {
        result = shortName ? addressComponent.short_name : addressComponent.long_name;
        return result;
      }
    }
    return '';
  }

  emitAddress(address?: Address, flatNumber?: string) {
    if (address && !this.invalidAddress) {
      const fullAddress = { ...address };
      if (flatNumber !== '') {
        fullAddress.line_1 += ` / ${flatNumber}`;
      }
      this.value = fullAddress;
    }
  }

  validateInput = () => {
    if (this.invalidAddress) {
      return { addressInvalid: 'Invalid address' };
    }
    return null;
  };
}
