import { Component, Input, OnInit } from '@angular/core';
import { BasicButtonDirective } from '../../abstract/basic-button';

@Component({
  selector: 'rezolve-anchor-button',
  templateUrl: './anchor-button.component.html',
  styleUrls: ['./anchor-button.component.scss'],
})
export class AnchorButtonComponent extends BasicButtonDirective implements OnInit {
  @Input() href = '';
  @Input() target = '_blank';

  ngOnInit(): void {
    this.buttonClass = `anchor-button primary-anchor ${this.classList}`;
  }
}
