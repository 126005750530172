import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PspCreationComponent } from '../psp-creation/psp-creation.component';

@Component({
  selector: 'mxp-codi-accounts',
  templateUrl: './psps.component.html',
  styleUrls: ['./psps.component.scss'],
})
export class PspsComponent {
  constructor(private dialog: MatDialog) {}

  psps = [];

  // ngOnInit(): void {
  // this.psps = this.mxpPspGrpcClient.getMxpPsps(new Empty()).pipe(map(response => response.psps || []));
  // }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  // private createStore(id: string) {
  //
  // }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  // private createAccount(id: string) {
  // }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '600px';

    this.dialog.open(PspCreationComponent, dialogConfig);
  }
}
