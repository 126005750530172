<div class="navbar-toolbar-content-container">
  <h3 class="mobile-navigation-title">
    {{ navbarToolbarContentService.mobileNavigationTitle$ | async }}
  </h3>
  <h3 class="email-address" *ngIf="shouldDisplayEmailAddress">
    <mat-icon class="account-icon" svgIcon="my-details"></mat-icon>
    {{ navbarToolbarContentService.emailAddress$ | async }}
  </h3>
  <mat-spinner *ngIf="navbarToolbarContentService.isLoading$ | async" class="spinner" diameter="40"></mat-spinner>
</div>
