import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import Route from '../../../core/constants/Route';
import { PaymentStatusUpdate } from '../../../core/interfaces/payment-status-update';
import { requestPayment } from '../../../core/store/actions/app.actions';
import { IAppState } from '../../../core/store/app.interface';

@Component({
  selector: 'mxp-payment-status-update',
  templateUrl: './payment-status-update.component.html',
  styleUrls: ['./payment-status-update.component.scss'],
})
export class PaymentStatusUpdateComponent implements OnInit {
  info!: PaymentStatusUpdate;

  constructor(private router: Router, private readonly store: Store<IAppState>) {
    this.info = router.getCurrentNavigation()?.extras.state as PaymentStatusUpdate;
  }

  ngOnInit(): void {
    this.store.dispatch(requestPayment());
  }

  requestNewPayment() {
    this.router.navigate([Route.REQUEST_PAYMENT]).then();
  }
}