<div class="main-container">
  <h1 *ngIf="configuration?.title" class="payment-request-header">{{ configuration.title | translate }}</h1>

  <div class="payment-container">
    <ng-content></ng-content>
  </div>
  <div *ngIf="!isPaymentWithoutAmount" class="payment-countdown-container">
    <mat-icon class="mat-icon" svgIcon="clock"></mat-icon>
    {{ 'CHECKING_FOR_PAYMENT' | translate }} <strong>{{ counter }}</strong>
  </div>
  <rezolve-secondary-button (click)="cancelRequest()">
    {{ 'CANCEL_REQUEST' | translate }}
  </rezolve-secondary-button>
</div>
