import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[rezolveDragAndDrop]',
})
export class DragAndDropDirective {
  @Output() dropped: EventEmitter<FileList> = new EventEmitter();

  @HostListener('drop', ['$event'])
  onDrop($event: CustomEvent & { dataTransfer?: DataTransfer }) {
    $event.preventDefault();
    $event.stopPropagation();
    this.dropped.emit($event.dataTransfer?.files);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event: CustomEvent) {
    $event.preventDefault();
    $event.stopPropagation();
  }
}
