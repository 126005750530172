<div class="main-container">
  <div class="store-list-header">
    <h2 class="store-subtitle">{{ 'MANAGE_STORES' | translate }}</h2>
    <rezolve-secondary-button
      *ngIf="hasAccessToAddStoreButton"
      prefixIcon="add"
      classList="large full-width"
      (click)="handleNewStore()"
      >{{ 'NEW_STORE' | translate }}
    </rezolve-secondary-button>
  </div>
  <div class="store-list">
    <mxp-store *ngFor="let store of stores" [store]="store" (mxpStoreDeletedEvent)="storeDeleted($event)" (mxpPspUpdated)="pspUpdated($event)"></mxp-store>
  </div>
  <div class="spinner-container spinnerAbsolute" *ngIf="isLoading"><mat-spinner></mat-spinner></div>
</div>
