import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from, of, iif, throwError } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { switchMap, first, concatMap, pluck, catchError, tap } from 'rxjs/operators';
import { Auth0Client, GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import { Auth0ClientConfig } from './auth0.config';
import { Auth0ClientService } from './auth0.client';
import { Auth0State } from './auth0.state';
import { ApiRouteDefinition, HttpInterceptorConfig, isHttpInterceptorRouteConfig } from './auth.settings';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(
    private configFactory: Auth0ClientConfig,
    @Inject(Auth0ClientService) private auth0Client: Auth0Client,
    private authState: Auth0State,
  ) {}

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    req: HttpRequest<any>,
    next: HttpHandler,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    const config = this.configFactory.get();
    if (!config.httpInterceptor?.allowedList) {
      return next.handle(req);
    }

    return this.findMatchingRoute(req, config.httpInterceptor).pipe(
      concatMap((route) =>
        iif(
          () => route !== null,
          of(route).pipe(
            pluck('tokenOptions'),
            concatMap<GetTokenSilentlyOptions, Observable<string>>((options) => {
              return this.getAccessTokenSilently(options).pipe(
                catchError((err) => {
                  if (this.allowAnonymous(route, err)) {
                    return of('');
                  }

                  this.authState.setError(err);
                  return throwError(err);
                }),
              );
            }),
            switchMap((token: string) => {
              const clone = token
                ? req.clone({
                    headers: req.headers.set('Authorization', `Bearer ${token}`),
                  })
                : req;

              return next.handle(clone);
            }),
          ),
          next.handle(req),
        ),
      ),
    );
  }

  private getAccessTokenSilently(options?: GetTokenSilentlyOptions): Observable<string> {
    return of(this.auth0Client).pipe(
      concatMap((client) => client.getTokenSilently(options)),
      tap((token) => this.authState.setAccessToken(token)),
      catchError((error) => {
        this.authState.refresh();
        return throwError(error);
      }),
    );
  }

  private stripQueryFrom(uri: string): string {
    if (uri.indexOf('?') > -1) {
      uri = uri.substr(0, uri.indexOf('?'));
    }

    if (uri.indexOf('#') > -1) {
      uri = uri.substr(0, uri.indexOf('#'));
    }

    return uri;
  }

  private canAttachToken(
    route: ApiRouteDefinition,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
  ): boolean {
    const testPrimitive = (value: string | undefined): boolean => {
      if (!value) {
        return false;
      }

      const requestPath = this.stripQueryFrom(request.url);

      if (value === requestPath) {
        return true;
      }

      return value.indexOf('*') === value.length - 1 && request.url.startsWith(value.substr(0, value.length - 1));
    };

    if (isHttpInterceptorRouteConfig(route)) {
      if (route.httpMethod && route.httpMethod !== request.method) {
        return false;
      }

      if (!route.uri && !route.uriMatcher) {
        console.warn('Either a uri or uriMatcher is required when configuring the HTTP interceptor.');
      }

      return route.uriMatcher ? route.uriMatcher(request.url) : testPrimitive(route.uri);
    }

    return testPrimitive(route);
  }

  private findMatchingRoute(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    config: HttpInterceptorConfig,
  ): Observable<ApiRouteDefinition | null> {
    return from(config.allowedList).pipe(first((route) => this.canAttachToken(route, request), null));
  }

  // eslint-disable-next-line
  private allowAnonymous(route: ApiRouteDefinition | null, err: any): boolean {
    return (
      !!route &&
      isHttpInterceptorRouteConfig(route) &&
      !!route.allowAnonymous &&
      ['login_required', 'consent_required'].includes(err.error)
    );
  }
}
