import { Component } from '@angular/core';
import { NavbarToolbarContentService } from './navbar-toolbar-content.service';

@Component({
  selector: 'rezolve-navbar-toolbar-content',
  templateUrl: './navbar-toolbar-content.component.html',
  styleUrls: ['./navbar-toolbar-content.component.scss'],
})
export class NavbarToolbarContentComponent {
  constructor(public navbarToolbarContentService: NavbarToolbarContentService) {
  }

  ngOnInit(): void {
    this.navbarToolbarContentService.emailAddress$.subscribe(value => {
      this.shouldDisplayEmailAddress = value != undefined && value != '';
    });
  }

  shouldDisplayEmailAddress: boolean = false;
}
