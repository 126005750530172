import { AdditionalPaymentStatusEnum, PaymentStatusOption } from '../enums/payment-status-enum';
import { PaymentStatusEnum } from '@rezolved/mxp-proto';

export class PaymentStatusUtils {
  static getPaymentStatus(status: PaymentStatusOption[]): PaymentStatusEnum[] | undefined {
    if(status.length==0)
      return undefined
    var returedStatus = status as PaymentStatusEnum[];
    return returedStatus;
  }

  static getPaymentStatusString(status: PaymentStatusOption[]): string {
    return status.length === 0 ? '' : status.toString();
  }
}
