<button
  [disabled]="disabled"
  [ngClass]="buttonClass"
  [type]="type"
  (click)="onClick($event)"
  mat-button
  [attr.data-cy]="generateDataCyId('-basic-button')">
  <span class="button-content">
    <mat-icon
      inline
      *ngIf="!!prefixIcon"
      class="button-icon"
      [attr.data-cy]="generateDataCyId('-basic-button-prefix')"
      >{{ prefixIcon }}</mat-icon
    >
    <span class="text"><ng-content></ng-content></span>
    <mat-icon *ngIf="!!suffixIcon" class="button-icon" [attr.data-cy]="generateDataCyId('-basic-button-suffix')">{{
      suffixIcon
    }}</mat-icon>
  </span>
</button>
