<div>
  <table mat-table [dataSource]="dataSource" class="base-table" matSort [matSortActive]="defaultParams.sortField"
    [matSortDirection]="defaultParams.sortDirection" matSortDisableClear [attr.data-cy]="generateDataCyId('-base-table')">
    <caption class="hide-text"> {{tableCaption}} </caption>
    <ng-container *ngFor="let column of tableColumns" [matColumnDef]="column.key">
      <th mat-header-cell *matHeaderCellDef [id]="column.displayName" 
        [disabled]="!sortingEnabled" 
        [mat-sort-header]="column.sortField"
        [width]="column.columnWidthPercentage+'%'" >
        {{ column.displayName }}
      </th>
      <ng-container [ngSwitch]="column.type" *matCellDef="let row">
        <div *ngIf="dataSource.loading$ | async; else content" [attr.data-cy]="generateDataCyId('-table-spinner')" class="spinner-container">
          <mat-spinner></mat-spinner>
        </div>
        <ng-template #content>
          <td mat-cell class="base-table-cell" *ngSwitchCase="columnType.Text" [attr.data-cy]="generateDataCyId('-table-text-cell')">
            {{ row[column.key] }}
          </td>
          <td mat-cell *ngSwitchCase="columnType.Actions" class="base-table-cell-actions" [attr.data-cy]="generateDataCyId('-table-action-cell')">
            <button mat-icon-button disableRipple class="base-table-action-button" [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item *ngFor="let columnAction of lastColumnActions" (click)="columnAction.action(row)">
                {{ columnAction.label }}
              </button>
            </mat-menu>
          </td>
          <td mat-cell *ngSwitchCase="columnType.Chip" [attr.data-cy]="generateDataCyId('-table-chip-cell')">
            <span class="base-table-chip"> {{ row[column.key] }}</span>
          </td>
          <td mat-cell *ngSwitchCase="columnType.Button">
            <button
              *ngIf="column.buttonIcon && column.action && !row.hiddenButtonActions?.includes(column.key)"
              mat-icon-button
              disableRipple
              (click)="column.action(row)"
              [attr.data-cy]="generateDataCyId('-table-button-cell')">
              <mat-icon class="base-table-cell-icon">{{ column.buttonIcon }}</mat-icon>
            </button>
          </td>
          <td mat-cell [matTooltip]="row[column.key]" class="base-table-cell" *ngSwitchCase="columnType.TextWithHover" [attr.data-cy]="generateDataCyId('-table-text-cell')">
            {{ row[column.key] }}
          </td>
        </ng-template>
      </ng-container>
    </ng-container>
    <tr *matHeaderRowDef="tableColumnsKeys" mat-header-row class="base-table-header" [attr.data-cy]="generateDataCyId('-table-header')"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumnsKeys" class="base-table-row" [attr.data-cy]="generateDataCyId('-table-row')"></tr>
  </table>
  <rezolve-list-pagination
    [itemsLength]="(dataSource.count$ | async) ?? 0"
    [currentPage]="defaultParams.pageIndex"
    [itemsPerPage]="defaultParams.pageSize"
    [pageSizeOptions]="pageSizeOptions">
  </rezolve-list-pagination>
</div>
