import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { DateFormatOptions } from '../../../core/constants/DateFormatOptions';
import { PaymentSentConfiguration } from '../../../core/interfaces/payment-sent-configuration';
import { notificationPaymentSent } from '../../../core/store/actions/app.actions';
import { IAppState } from '../../../core/store/app.interface';

@Component({
  selector: 'mxp-payment-notification-sent',
  templateUrl: './payment-notification-sent.component.html',
  styleUrls: ['./payment-notification-sent.component.scss'],
})
export class PaymentNotificationSentComponent implements OnInit {
  @Input()
  paymentSentConfiguration!: PaymentSentConfiguration;

  constructor(private readonly store: Store<IAppState>) {}

  get expiryDate(): string {
    if (this.paymentSentConfiguration?.paymentConfiguration?.expiryDate) {
      return moment(this.paymentSentConfiguration?.paymentConfiguration?.expiryDate.toDate())
        .format(DateFormatOptions.DEFAULT_PAYMENT_DATE);   
    } else {
      return 'EXPIRY_DATE_REQUIRED';
    }
  }

  ngOnInit(): void {
    this.store.dispatch(notificationPaymentSent());
  }
}