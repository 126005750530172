import { Component, OnDestroy } from '@angular/core';
import { IconService } from './core/services/icon.service';
import { AuthService } from '@rezolved/auth';
import { Router } from '@angular/router';
import { MxpTranslationService } from './core/services/mxp-translation.service';
import { Store } from '@ngrx/store';
import { IAppState } from './core/store/app.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavbarToolbarContentService } from '@rezolved/ui';

@Component({
  selector: 'mxp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AuthService],
})
export class AppComponent implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  title = 'mx-payment';
  shouldDisplayMainMenuOnProd: boolean = false;
  constructor(
    private iconService: IconService,
    public authService: AuthService,
    public router: Router,
    private readonly mxpTranslationService: MxpTranslationService,
    private navbarToolbarContentService: NavbarToolbarContentService,
    private store: Store<IAppState>
  ) {
    this.shouldDisplayMainMenuOnProd = !window.location.href.includes('https://mxp.mx.rezolve.com/');
    this.iconService.registerIcons();
    this.mxpTranslationService.loadTranslation();
    this.initNavbarToolbarContent();
  }

  private initNavbarToolbarContent() {
    this.store
      .select((state) => state.AppState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((appState) => {
        if (appState?.currentStep) {
          setTimeout(() => {
            this.navbarToolbarContentService.setMobileNavigationTitle(
              this.mxpTranslationService.getTranslation(appState.currentStep),
            );
          });
        }
        this.navbarToolbarContentService.setLoading(appState?.isLoading ?? false);
      });

    this.authService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.navbarToolbarContentService.setEmailAddress(user.email || '');
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loginWithRedirect(): void {
    this.authService.loginWithRedirect();
  }

  logout(): void {
    this.authService.logout({ returnTo: window.location.origin });
  }
}
