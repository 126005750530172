import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { ICalendarOption } from '../components/atoms/calendar-date-ranger-picker/ICalendarOption';

@Pipe({
  name: 'dateRangeDisplay',
})
// eslint-disable-next-line @angular-eslint/use-pipe-transform-interface
export class DateRangeDisplayPipe implements PipeTransform {
  private dateFormat = 'DD/MM/yyyy';
  transform(value: ICalendarOption | undefined): string {
    if (value?.fixedDate) {
      return value.name || '';
    }

    return `${moment(value?.dateRange?.start).format(this.dateFormat)}
    -
      ${moment(value?.dateRange?.end).format(this.dateFormat)}`;
  }
}
