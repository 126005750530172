<div class="container" (click)="onClick()" [ngClass]="{ active: isSelected }" [attr.dataCy]="generateDataCyId('-item')">
  <div class="address">
    <div class="header" [attr.dataCy]="generateDataCyId('-item-header')">{{ item.line_1 }} {{ item.line_2 }}</div>
    <div class="content" [attr.dataCy]="generateDataCyId('-item-content')">{{ item.city }}, {{ item.country }}</div>
  </div>
  <div class="actions">
    <div class="action" *ngIf="showEdit" (click)="onEdit($event)" [attr.dataCy]="generateDataCyId('-item-edit')">
      {{ editLabel }}
    </div>
    <div class="action" *ngIf="showRemove" (click)="onRemove($event)" [attr.dataCy]="generateDataCyId('-item-remove')">
      {{ removeLabel }}
    </div>
  </div>
</div>
