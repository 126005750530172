import { Component, OnInit } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { BasicButtonDirective } from '../../abstract/basic-button';

@Component({
  selector: 'rezolve-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: PrimaryButtonComponent }],
})
export class PrimaryButtonComponent extends BasicButtonDirective implements OnInit {
  ngOnInit(): void {
    this.buttonClass = `primary ${this.classList}`;
  }
}
