<mat-form-field
  class="rezolve-form-field"
  [appearance]="appearance"
  floatLabel="always"
  [ngClass]="{ readonly: readonly }"
  [attr.data-cy]="generateDataCyId('-input-form-field')">
  <mat-icon *ngIf="iconPrefix" matPrefix>{{ iconPrefix }}</mat-icon>
  <mat-label *ngIf="label" [attr.data-cy]="generateDataCyId('-input-label')">
    {{ label }}
    <mat-icon *ngIf="tooltipMessage" matTooltip="{{ tooltipMessage }}" aria-hidden="false" aria-label="help outline"
      >help_outline</mat-icon
    >
  </mat-label>
  <input
    [formControl]="control"
    #inputField
    [name]="componentName"
    [attr.data-cy]="generateDataCyId('-input')"
    matInput
    [type]="isPassword ? (hideText ? 'password' : 'text') : type"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [required]="required"
    [maxlength]="maxLength || null"
    (input)="onInput()" />
  <mat-icon *ngIf="isPassword" matSuffix (click)="hideText = !hideText">
    {{ hideText ? 'visibility_off' : 'visibility' }}
  </mat-icon>
  <button mat-button *ngIf="iconSuffix && !isPassword" matSuffix mat-icon-button (click)="suffixClick()">
    <mat-icon>{{ iconSuffix }}</mat-icon>
  </button>
  <mat-error *ngIf="hasErrors" [attr.data-cy]="generateDataCyId('-input-error')">
    {{ errorMessage }}
  </mat-error>
</mat-form-field>
