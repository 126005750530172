import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  GetMxpPspRequest,
  MxpPspGrpcClient,
  UpdateMxpPspRequest,
  UpdateMxpPspResponse,
  MxpPortalEnrollmentGrpcClient
} from '@rezolved/mxp-proto';
import { MxpSnackBarService } from '../../../core/services/mxp-snackbar.service';
import { BaseDropDownItem } from '@rezolved/ui';
import { Empty } from '@ngx-grpc/well-known-types';

@Component({
  selector: 'mxp-edit-codi-account',
  templateUrl: './psp-update.component.html',
  styleUrls: ['./psp-update.component.scss'],
})
export class PspUpdateComponent {
  form: FormGroup;
  isLoading = true;
  shouldPropagateToAll:boolean = false;
  bankCodes: BaseDropDownItem[] = [];
  bankCode: BaseDropDownItem = {
    key: '',
    value: ''
  }

  constructor(
    private dialogRef: MatDialogRef<PspUpdateComponent>,
    private translate: TranslateService,
    private mxpPspGrpcClient: MxpPspGrpcClient,
    private mxpSnackBarService: MxpSnackBarService,
    private mxpBulkEnrollmentGrpcClient: MxpPortalEnrollmentGrpcClient,
    @Inject(MAT_DIALOG_DATA) private storeId: string,
  ) {
    this.form = new FormGroup({
      beneficiaryName: new FormControl('', Validators.required),
      beneficiaryBank: new FormControl('', Validators.required),
      beneficiaryAccountNumber: new FormControl('', Validators.required),
      beneficiaryAccountAlias: new FormControl('', Validators.required),
      beneficiaryAccountDigit: new FormControl('', Validators.required),
      beneficiaryAccountKeySource: new FormControl('', Validators.required),
      certificateSerialNumber: new FormControl('', Validators.required),
      isMock: new FormControl(false, Validators.required)
    });

    this.mxpBulkEnrollmentGrpcClient.getBankCodes(new Empty()).subscribe(response => {
      if(response && response.bankCodes) {
        this.bankCodes = response.bankCodes.map((item) => {
          return { key: item.bankName?.value, value: item.bankNumber } as BaseDropDownItem;
        }).sort((a,b) => a.key.localeCompare(b.key));
        this.mxpPspGrpcClient.getPsp(new GetMxpPspRequest({ storeId: this.storeId })).subscribe(
          (response) => {
            if(response) {    
              let bankCode = this.bankCodes.find(x => x.value == response.beneficiaryBank);
              if(bankCode == undefined) {
                this.bankCode = this.bankCodes[0];
              }
              else {
                this.bankCode = bankCode;
              }
              this.form.controls.beneficiaryBank.setValue(this.bankCode.value);
              this.form.controls.beneficiaryName.setValue(response.beneficiaryName);
              this.form.controls.beneficiaryAccountAlias.setValue(response.beneficiaryAccountAlias);
              this.form.controls.beneficiaryAccountNumber.setValue(response.beneficiaryAccountNumber);
              this.form.controls.beneficiaryAccountDigit.setValue(response.beneficiaryAccountDigit);
              this.form.controls.beneficiaryAccountKeySource.setValue(response.beneficiaryAccountKeySource);
              this.form.controls.certificateSerialNumber.setValue(response.certificateSerialNumber);
              this.isLoading = false;
            }  
          },
          (error) => {
            this.isLoading = false;
          }
        );    
      }
    });
  }

  onSave(): void {
    const { value: formValues } = this.form;
    this.isLoading = true;

    const request = new UpdateMxpPspRequest({
      beneficiaryAccountNumber: formValues.beneficiaryAccountNumber,
      beneficiaryBank: formValues.beneficiaryBank + '',
      beneficiaryName: formValues.beneficiaryName,
      certificateSerialNumber: formValues.certificateSerialNumber,
      beneficiaryAccountAlias: formValues.beneficiaryAccountAlias,
      beneficiaryAccountDigit: formValues.beneficiaryAccountDigit,
      beneficiaryAccountKeySource: formValues.beneficiaryAccountKeySource,
      isMock: formValues.isMock,
      storeId: this.storeId,
      shouldPropagateToAll: this.shouldPropagateToAll
    });

    let response = this.mxpPspGrpcClient.updatePsp(request);
    response.subscribe(
      (response: UpdateMxpPspResponse) => {
        let targetMessage = 'SNACKBAR.UPDATE_PSP';
        let storeNames = '';
        if (!response.success) {
          storeNames = response.failedStores != undefined ? response.failedStores?.join(', ') : '';
          targetMessage = 'SNACKBAR.UPDATE_PSP_PARTIAL';
        }
        this.mxpSnackBarService.showSnackbarMessage(targetMessage, storeNames);
        this.dialogRef.close(true);
      },
      (error) => {
        console.log(error);
        this.mxpSnackBarService.showSnackbarMessage('SNACKBAR.UPDATE_PSP_FAIL');
        this.dialogRef.close(false);
      }
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  handlePropagateToAll(event:boolean){
    this.shouldPropagateToAll = event;
  }
}