<mat-form-field class="calendar-range-form">
  <div class="calendar-range">
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [attr.dataCy]="generateDataCyId('-input')">
      <input
        matStartDate
        formControlName="start"
        readonly
        [placeholder]="FromLabel"
        [attr.dataCy]="generateDataCyId('-startdate')" />
      <input
        matEndDate
        formControlName="end"
        (dateChange)="endDateSelected()"
        readonly
        [placeholder]="ToLabel"
        [attr.dataCy]="generateDataCyId('-endate')" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </div>
  <button
    mat-stroked-button
    color="primary"
    [disabled]="!dateSelected"
    [attr.dataCy]="generateDataCyId('-select')"
    (click)="approveSelectedRange()">
    {{ ApplyLabel }}
  </button>
</mat-form-field>
