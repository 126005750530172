import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'rezolve-tab-header',
  templateUrl: './tab-header.component.html',
})
export class TabHeaderComponent {
  @ViewChild(TemplateRef)
  // eslint-disable-next-line
  headerContent: TemplateRef<any> | null = null;

  hide() {
    this.headerContent?.elementRef.nativeElement.remove();
  }
}
