import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export enum Icons {
  MyDetails = 'my-details',
  Logout = 'logout',
  CodiAccount = 'codi-account',
  StoreAccount = 'store-account',
  Stores = 'stores',
  Users = 'users',
  Login = 'login',
  Wallet = 'wallet',
  Reports = 'reports',
  User = 'user',
  Download = 'download',
  DownloadWhite = 'download-white',
  Clock = 'clock',
  Edit = 'edit',
  Plus = 'plus',
  Refresh = 'refresh',
  UpdatePaymentStatuses = 'updatePaymentStatuses',
  Dashboard = 'dashboard',
  Billing = 'billing',
  BPG = 'bulk-payment',
  Refunds = 'refunds',
  Partners = "partners",
  Pdf = "pdf"
}

@Injectable({
  providedIn: 'root',
})
export class IconService {
  constructor(private readonly matIconRegistry: MatIconRegistry, private readonly domSanitizer: DomSanitizer) {}

  registerIcons(): void {
    this.loadIcons(Object.values(Icons), '/assets/svg');
  }

  private loadIcons(iconKeys: string[], iconUrl: string): void {
    iconKeys.forEach((key) => {
      this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
    });
  }
}
