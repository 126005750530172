<span>
  <h3>{{ 'ARE_YOU_SURE' | translate }}</h3></span
>

<div class="action-section">
  <rezolve-secondary-button class="cancel-button" classList="large" mat-dialog-close>
    {{ 'NO' | translate }}</rezolve-secondary-button
  >
  <rezolve-primary-button classList="large" (handleClick)="actionConfirmed()">
    {{ 'YES' | translate }}</rezolve-primary-button
  >
</div>
