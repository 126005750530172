<ng-container>
  <div class="header-line">
    <h3 class="account-name">{{ store.name }}</h3>
    <mat-icon (click)="onEdit()" class="mat-icon margin-left-auto" svgIcon="edit"></mat-icon>
  </div>
  <p class="additional-information">{{ getAddress() }}</p>
  <p class="additional-information">
    <!--    {{ 'CREATED_ON' | translate }} {{ store.creationDate | date: dateFormat }}-->
  </p>
</ng-container>
