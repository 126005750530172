import { Component, ChangeDetectorRef } from '@angular/core';
import {
  GetByIdRequest,
  GetMxpStoresReply,
  MxpStoreDto,
  MxpStoreGrpcClient,
  MxpUserGrpcClient,
} from '@rezolved/mxp-proto';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { StoreCreateComponent } from '../store-create/store-create.component';
import { AuthService } from '@rezolved/auth';
import { MxpSnackBarService } from '../../../core/services/mxp-snackbar.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../core/store/app.interface';
import { manageStores } from '../../../core/store/actions/app.actions';
import { Auth0MxpUserRoles } from '../../../core/constants/UserGroups';
import { Empty } from '@ngx-grpc/well-known-types';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'mxp-store-list',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss'],
})
export class StoresComponent {
  dataChanged = false;
  stores: MxpStoreDto[] = [];
  mxpUserRoles = Auth0MxpUserRoles;
  rolesWithAccessToAddStoreButton = [this.mxpUserRoles.MXP_PARTNER, this.mxpUserRoles.MXP_POWER_ADMIN];
  hasAccessToAddStoreButton = false;
  isLoading = false;
  private _mxpPartnerId: string | undefined;
  private _mxpMerchantId: string | undefined;

  constructor(
    public router: Router,
    private cdr: ChangeDetectorRef,
    private mxpStoreGrpcClient: MxpStoreGrpcClient,
    private mxpUserGrpcClient: MxpUserGrpcClient,
    private dialog: MatDialog,
    public authService: AuthService,
    private mxpSnackBarService: MxpSnackBarService,
    private readonly store: Store<IAppState>,
  ) {
    this.store.dispatch(manageStores());
    this.authService.user$
      .pipe(
        switchMap((user) => {
          if (user) {
            this._mxpPartnerId = user['https://rezolve.com/mxp_partner_id'];
            this._mxpMerchantId = user['https://rezolve.com/mxp_merchant_id'];
            return this.authService.roles$.pipe(
              switchMap((roles) => {
                if (roles) {
                  if (this.rolesWithAccessToAddStoreButton.some((role) => roles.includes(role))) {
                    this.hasAccessToAddStoreButton = true;
                    return of(true);
                  } else if (roles.includes(this.mxpUserRoles.MXP_STORE_ADMIN)) {
                    const userId = user['https://rezolve.com/mxp_user_id'];
                    return this.mxpUserGrpcClient.getMxpUser(new GetByIdRequest({ id: userId })).pipe(
                      map((userById) => {
                        if (userById && userById.storeId) {
                          return userById.storeId;
                        }
                        return null;
                      }),
                    );
                  }
                }
                return of(null);
              }),
            );
          }
          return of(null);
        }),
        tap((result) => {
          if (typeof result === 'string') {
            this.loadMxpStores(result);
          } else {
            this.loadMxpStores();
          }
        }),
        take(1),
        catchError((error) => {
          console.error(error);
          return of(null);
        }),
      )
      .subscribe();
  }

  handleNewStore = () => {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '600px';

    this.dialog
      .open(StoreCreateComponent, dialogConfig)
      .afterClosed()
      .subscribe(
        (response: MxpStoreDto) => {
          if (response) {
            this.stores.unshift(response);
          }
        },
        (error) => console.log(error),
      );
  };

  loadMxpStores(filteredStoreId: string = '', isCloseSnackBar: boolean = false) {
    this.mxpSnackBarService
      .wrapApiCall(
        'SNACKBAR.GET_STORES',
        this.mxpStoreGrpcClient.getAllStores(new Empty()),
        undefined,
        undefined,
        undefined,
        isCloseSnackBar,
      )
      .subscribe(
        (response: GetMxpStoresReply) => {
          if (response.stores) {
            this.stores = [];
            this.stores =
              filteredStoreId != '' ? response.stores.filter((x) => x.id == filteredStoreId) : response.stores;
          }
          this.dataChanged = !this.dataChanged;
          this.cdr.detectChanges();
        },
        (error) => {
          console.log(error);
        },
      );
  }

  storeDeleted(storeId: string) {
    this.stores = this.stores.filter((item) => item.id !== storeId);
    this.router.navigate(['stores']);
  }

  pspUpdated(response: boolean) {
    if (response) {
      this.stores = [];
      this.loadMxpStores(undefined, true);
      //this.router.navigate(['/','stores']);  --- this is for reloading the component , not working in this case
      window.location.reload();
      this.dataChanged = !this.dataChanged;
      this.cdr.detectChanges();
    }
  }
}
