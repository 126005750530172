import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { NamedComponent } from '../../abstract/named-component';
import { IDateRange } from './IDateRange';

@Component({
  selector: 'rezolve-calendar-date-ranger-picker',
  templateUrl: './calendar-date-ranger-picker.component.html',
  styleUrls: ['./calendar-date-ranger-picker.component.scss'],
})
export class CalendarDateRangerPickerComponent extends NamedComponent implements OnInit, OnDestroy {
  @Output()
  rangeApproved: EventEmitter<IDateRange> = new EventEmitter<IDateRange>();
  @Input() FromLabel = 'From';
  @Input() ToLabel = 'To';
  @Input() ApplyLabel = 'Apply';
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  public dateSelected = false;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  constructor(private readonly adapter: DateAdapter<Date>) {
    super();
    this.componentName = 'calendar-date-range-picker';
  }

  ngOnInit(): void {
    this.adapter.setLocale('en-GB');
  }

  approveSelectedRange = () => {
    this.rangeApproved.emit(this.dateRange());
  };

  dateRange(): IDateRange {
    let dateStart = moment(this.range.get('start')?.value);
    let dateEnd = moment(this.range.get('end')?.value);
    return {
      start: new Date(dateStart.year(), dateStart.month(), dateStart.date(), 0, 0, 0),
      end: new Date(dateEnd.year(), dateEnd.month(), dateEnd.date(), 23, 59, 59)
    };
  }

  clearSelection = () => {
    this.range.controls['start'].setValue(null);
    this.range.controls['end'].setValue(null);
    this.dateSelected = false;
  };

  endDateSelected = () => {
    this.dateSelected = true;
  };

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
