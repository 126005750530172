import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CreditCard, CreditCardIcons } from '../../molecules/credit-card-list/credit-card';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'rezolve-credit-card-item',
  templateUrl: './credit-card-item.component.html',
  styleUrls: ['./credit-card-item.component.scss'],
})
export class CreditCardItemComponent {
  @Input() creditCardInfo: CreditCard | undefined;
  @Input() editLabel = 'Edit';
  @Input() removeLabel = 'Remove';
  @Output() editClicked: EventEmitter<CreditCard> = new EventEmitter();
  @Output() removeClicked: EventEmitter<CreditCard> = new EventEmitter();
  @Input() isSelected = false;
  faCheck = faCheck;
  ccIconsHelper: CreditCardIcons;
  constructor() {
    this.ccIconsHelper = new CreditCardIcons();
  }
  edit = () => {
    this.editClicked.emit(this.creditCardInfo);
  };
  remove = () => {
    this.removeClicked.emit(this.creditCardInfo);
  };

  getIcon = (brand: string | undefined) => {
    return this.ccIconsHelper.getIconByCardName(brand);
  };
}
