import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AbstractNavigator {
  private readonly router?: Router;

  constructor(private location: Location, injector: Injector) {
    this.router = injector.get(Router);
  }

  navigateByUrl(url: string): void {
    if (this.router) {
      this.router.navigateByUrl(url);

      return;
    }

    this.location.replaceState(url);
  }
}
