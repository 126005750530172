import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Empty } from '@ngx-grpc/well-known-types';
import { AuthService } from '@rezolved/auth';
import {
  CreateMxpUserRequest, GetByIdRequest, GetMxpStoresReply, GetMxpUserReply, LanguageEnum, MxpStoreDto, MxpStoreGrpcClient, MxpUserGrpcClient, RoleEnum
} from '@rezolved/mxp-proto';
import { BaseDropDownItem, BaseDropDownItemUtils } from '@rezolved/ui';
import { first } from 'rxjs/operators';
import { Auth0MxpUserRoles, UserRoles } from '../../../core/constants/UserGroups';
import { PermissionTypeEnum } from '../../../core/enums/permission-type.enum';
import { MxpSnackBarService } from '../../../core/services/mxp-snackbar.service';
import { MxpTranslationService } from '../../../core/services/mxp-translation.service';
import { RolesValidator } from '../../../core/validators/roles.validator';
import { StoreCreateComponent } from '../../stores-module/store-create/store-create.component';

@Component({
  selector: 'mxp-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss'],
})
export class UserCreateComponent {
  form: FormGroup;
  storeOptions: string[] = [];
  languages: BaseDropDownItem[] = [];
  allRoleList = UserRoles;
  roleList: any[] = [];
  roleBelowStoreAdminList = UserRoles.filter((role) => role.id == RoleEnum.USER || role.id == RoleEnum.POWER_USERS);
  stores: BaseDropDownItem[] = [];
  isOnlyReportUserRoleSelected = false;
  showSpinner = false;
  phoneFieldHasErrors = false;
  constructor(
    private dialogRef: MatDialogRef<StoreCreateComponent>,
    private mxpTranslationService: MxpTranslationService,
    private mxpStoreGrpcClient: MxpStoreGrpcClient,
    private mxpUserGrpcClient: MxpUserGrpcClient,
    private authService: AuthService,
    private mxpSnackBarService: MxpSnackBarService,
  ) {
    this.authService.user$.pipe(first()).subscribe((user) => {
      if (user) {
        this.mxpSnackBarService
          .wrapApiCall(
            'SNACKBAR.GET_STORES',
            this.mxpStoreGrpcClient.getAllStores(
              new Empty()
            ),
          )
          .subscribe(
            (response: GetMxpStoresReply) => {
              if (response.stores) {
                this.stores = response.stores.reduce((dropDownStores: BaseDropDownItem[], store: MxpStoreDto) => {
                  if (store.pspId && store.name && store.id) {
                    dropDownStores.push({ value: store.id, key: store.name });
                  }
                  return dropDownStores;
                }, []);
              }
              if (user['http://localhost:44346/roles']?.includes(Auth0MxpUserRoles.MXP_STORE_ADMIN)) {
                this.mxpUserGrpcClient
                  .getMxpUser(new GetByIdRequest({ id: user['https://rezolve.com/mxp_user_id'] }))
                  .subscribe((userById: GetMxpUserReply | null) => {
                    if (userById?.storeId) {
                      this.stores = this.stores.filter((store) => store.value === userById.storeId);
                      this.roleList = this.roleBelowStoreAdminList.filter((role) => role.id != RoleEnum.PARTNER);
                      this.buildRolesForm();
                    }
                  });
              } else {
                this.roleList = this.allRoleList.filter((role) => role.id != RoleEnum.PARTNER);
                this.buildRolesForm();
              }
            },
            
            (error) => console.log(error),
          );
      }
    });
    this.languages = BaseDropDownItemUtils.transformEnum(LanguageEnum);
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.compose([Validators.required,Validators.email])),
      phone: new FormControl('', Validators.compose([Validators.required, Validators.pattern('\\+?\\d+')])),
      storeId: new FormControl(''),
      active: new FormControl(true, Validators.required),
      language: new FormControl('', Validators.required),
      roles: new FormArray(
        this.roleList.map(() => new FormControl(false)),
        RolesValidator
      )
    });

    this.form.controls.roles.valueChanges.subscribe(roles => {
      if(!roles) {
        return;
      }
      const rolesArray = roles as Array<boolean>;
      if (rolesArray.findIndex((role) => role == true) === 4) {
        this.isOnlyReportUserRoleSelected = true;
        this.form.controls.storeId.setValidators(Validators.nullValidator);
      }
      else {
        this.isOnlyReportUserRoleSelected = false;
        this.form.controls.storeId.setValidators(Validators.required);
      }
      this.form.controls.storeId.updateValueAndValidity();
    });

    this.form.get('phone')?.valueChanges.subscribe((x) => {
      this.phoneFieldHasErrors = false;
    });
  }
  private buildRolesForm(): void {
    this.rolesFormArray.clear();

    this.roleList.forEach(() => this.rolesFormArray.push(new FormControl(false)));
  }

  get rolesFormArray(): FormArray {
    return this.form.controls.roles as FormArray;
  }

  get roles(): boolean[] {
    return this.form.get(`roles`)?.value || [];
  }

  onSave() {
    this.showSpinner = true;
    const { name, email, storeId, phone, active, language } = this.form.value;
    const roles = this.roleList.filter((role) => this.roles[this.roleList.indexOf(role)]).map((role) => role.id);

    this.authService.user$.pipe(first()).subscribe((user) => {
      if (user) {
        const newUser = new CreateMxpUserRequest({
          name,
          email,
          phone,
          language,
          storeId: this.isOnlyReportUserRoleSelected ? '' : storeId,
          active,
          roles
        });

        this.mxpSnackBarService
          .wrapApiCall('SNACKBAR.CREATE_USER', this.mxpUserGrpcClient.createUser(newUser))
          .subscribe(
            () => this.dialogRef.close(true),
            () => {
              this.dialogRef.close(false);
              this.showSpinner = false;
              console.log("error");
            },
            () => this.showSpinner = false,
          );
      }
    });
  }

  getRoleDescription(permissions: PermissionTypeEnum[]): string {
    return permissions
      .map((permission) => this.mxpTranslationService.getTranslation('PERMISSIONS.' + permission))
      .join(', ');
  }

  close() {
    this.dialogRef.close();
  }

  handlePhoneFieldError(){
    this.phoneFieldHasErrors = true;
  }
}
