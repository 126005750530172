import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { AuthService } from '@rezolved/auth';
import { Observable, combineLatest } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BillingAuthGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(private auth: AuthService) {}
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(take(1));
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.redirectIfUnauthenticated(state);
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.redirectIfUnauthenticated(state);
  }

  private redirectIfUnauthenticated(state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest(
      this.auth.isAuthenticated$,
      this.checkUsersRoles(),
      (first, second) => first && second,
    ).pipe(
      tap((loggedIn) => {
        if (!loggedIn) {
          this.auth.loginWithRedirect(state.url);
        }
      }),
    );
  }

  private checkUsersRoles(): Observable<boolean> {
    return this.auth.roles$.pipe(
      map(
        (value) =>
          value.includes('MXP_SUPER_ADMIN') || value.includes('MXP_PARTNER') || value.includes('MXP_POWER_ADMIN')
      ),
    );
  }
}
