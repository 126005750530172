import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { NamedComponent } from '../../abstract/named-component';
import { CalendarDateRangerPickerComponent } from '../../atoms/calendar-date-ranger-picker/calendar-date-ranger-picker.component';
import { ICalendarOption } from '../../atoms/calendar-date-ranger-picker/ICalendarOption';
import { IDateRange } from '../../atoms/calendar-date-ranger-picker/IDateRange';
import { FixedDateOption } from './calendar-options';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { TranslateService } from '@ngx-translate/core';
import { esTranslations } from './i18n/es';
import { enTranslations } from './i18n/en';

@Component({
  selector: 'rezolve-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent extends NamedComponent {
  constructor(private readonly translateService: TranslateService) {
    super();
    this.componentName = 'date-range-selector';

    this.translateService.setTranslation('en', enTranslations);
    this.translateService.setTranslation('es', esTranslations);

  }
  @Input() appearance = 'outline' as MatFormFieldAppearance;
  @Input() label = 'Date Range';
  @Input() shouldRefresh = false;
  @Output()
  rangeSelected: EventEmitter<ICalendarOption> = new EventEmitter<ICalendarOption>();
  @ViewChild('dateSelect', { static: true }) dateSelect: MatSelect | undefined;
  @ViewChild('customPicker', { static: true }) customPicker: CalendarDateRangerPickerComponent | undefined;

  dateRangeAvailable: Array<ICalendarOption> = FixedDateOption;
  selectedOption: ICalendarOption | undefined;

  optionChanged = (e: MatSelectChange) => {
    this.selectedOption = e.value;
    if (this.selectedOption?.fixedDate) this.customPicker?.clearSelection();
    this.rangeSelected.emit(this.selectedOption);
  };

  customDateApproved = (e: IDateRange) => {
    this.selectedOption = { name: 'custom', fixedDate: false, dateRange: e };
    this.dateSelect?.close();
  };

  ngOnChanges(changes: SimpleChanges) {
    if(this.shouldRefresh) {
      this.selectedOption = undefined;
    }
  }
}