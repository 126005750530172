import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DataSourceService } from '../../../interfaces/base-table/data-source-service';
import { BaseTableParams } from '../../../interfaces/base-table/base-table-params';
import { catchError, finalize } from 'rxjs/operators';

export class BaseDataSource<T> implements DataSource<T> {
  private dataSubject = new BehaviorSubject<T[]>([]);
  private loadingDataSubject = new BehaviorSubject<boolean>(false);

  constructor(private dataService: DataSourceService) {}

  public loading$ = this.loadingDataSubject.asObservable();
  public count$ = new BehaviorSubject<number>(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingDataSubject.complete();
  }

  loadData(baseTableProps: BaseTableParams) {
    this.loadingDataSubject.next(true);

    this.dataService
      .loadData(baseTableProps)
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          this.loadingDataSubject.next(false);
        }),
      )
      .subscribe((data) => {
        this.dataSubject.next(data.value);
        this.count$.next(data.total);
      });
  }
}
