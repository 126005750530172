<div mat-dialog-title *ngIf="headerTemplate || headerComponent">
  <div fxLayout="row nowrap">
    <div fxFlex="1 1 100%">
      <ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: data }"></ng-container>
      <ng-container *ngComponentOutlet="headerComponent"></ng-container>
    </div>
    <div fxFlex="0 0 auto" *ngIf="showCloseButton">
      <button type="button" (click)="close()" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <ng-container *ngTemplateOutlet="contentTemplate; context: { $implicit: data }"></ng-container>
  <ng-container *ngComponentOutlet="contentComponent"></ng-container>
</div>
