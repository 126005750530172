import { Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NamedComponent } from '../../abstract/named-component';

enum PanelState {
  collapsed = 'collapsed',
  expanded = 'expanded',
}

@Component({
  selector: 'rezolve-base-card',
  templateUrl: './base-card.component.html',
  styleUrls: ['./base-card.component.scss'],
  animations: [
    trigger('bodyExpansion', [
      state(PanelState.collapsed, style({ height: '0px', visibility: 'visible' })),
      state(PanelState.expanded, style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BaseCardComponent extends NamedComponent {
  @Input() toggleTextShow = 'Show';
  @Input() toggleTextHide = 'Hide';
  state = PanelState.collapsed || PanelState.expanded;
  show = true;
  toggle = true;

  onToggle() {
    this.state = this.state === PanelState.collapsed ? PanelState.expanded : PanelState.collapsed;
    this.show = !this.show;
  }
}
