<mat-form-field class="rezolve-form-field" appearance="outline" floatLabel="always">
  <mat-label *ngIf="labelText" [attr.data-cy]="generateDataCyId('-input-label')">
    {{ labelText }}
  </mat-label>
  <input 
      matInput 
      [matDatepicker]="picker"
      [placeholder]="placeholder"
      [attr.dataCy]="generateDataCyId('-dateValue')" 
      (dateChange)="dateValueSelected($event)"
      [matDatepickerFilter]="dateFilter"/>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>