import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { faCcMastercard, faCcVisa, faCcApplePay } from '@fortawesome/free-brands-svg-icons';

export interface CreditCard {
  address_id: string;
  brand: string;
  expiration_date: Date;
  name_on_card: string;
  number: string;
  short_name: string;
  type: string;
  valid_from_date: Date;
  pan4: number;
  isSelected: boolean;
}

export enum CardBrand {
  Default = 'Default',
  MasterCard = 'Master Card',
  Visa = 'Visa',
}

export class CreditCardIcons {
  genericCardIcon = faCreditCard;
  masterCardIcon = faCcMastercard;
  visaCardIcon = faCcVisa;
  appleCardIcon = faCcApplePay;

  getIconByCardName(cardBrand: string | undefined): IconDefinition {
    if (cardBrand == CardBrand.Default) {
      return this.masterCardIcon;
    } else if (cardBrand == CardBrand.Visa) {
      return this.visaCardIcon;
    } else {
      return this.genericCardIcon;
    }
  }
}
