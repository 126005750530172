import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetByIdRequest, MxpUserGrpcClient } from '@rezolved/mxp-proto';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MxpSnackBarService } from '../../../core/services/mxp-snackbar.service';

@Component({
  selector: 'mxp-user-delete',
  templateUrl: './user-delete.component.html',
  styleUrls: ['./user-delete.component.scss'],
})
export class UserDeleteComponent implements OnDestroy {
  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();
  private _id: string | undefined;
  showSpinner = false;
  constructor(
    private dialogRef: MatDialogRef<UserDeleteComponent>,
    private mxpUserGrpcClient: MxpUserGrpcClient,
    private mxpSnackBarService: MxpSnackBarService,
    @Inject(MAT_DIALOG_DATA) id: string,
  ) {
    this._id = id;
  }

  deleteMxpUser(): void {
    this.showSpinner = true;
    this.mxpSnackBarService
      .wrapApiCall('SNACKBAR.DELETE_USER', this.mxpUserGrpcClient.deleteUser(new GetByIdRequest({ id: this._id })))
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => {
          this.showSpinner = false;
          this.dialogRef.close(true);
        },
        (error) => {
          console.error(error);
          this.showSpinner = false;
          this.dialogRef.close(false);
        },
      );
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }
}
