import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import { HttpMethod } from './enums/http-method';

export interface AuthSettings {
  domain: string;
  clientId: string;
  redirectUri: string;
  audience: string;
  useRefreshTokens?: boolean;
  organization?: string;
  tenant?: string;
  errorPath?: string;
  httpInterceptor?: HttpInterceptorConfig;
}

export interface HttpInterceptorConfig {
  allowedList: ApiRouteDefinition[];
}

export type ApiRouteDefinition = HttpInterceptorRouteConfig | string;

export interface HttpInterceptorRouteConfig {
  uri?: string;
  uriMatcher?: (uri: string) => boolean;
  tokenOptions?: GetTokenSilentlyOptions;
  httpMethod?: HttpMethod | string;
  allowAnonymous?: boolean;
}

export function isHttpInterceptorRouteConfig(def: ApiRouteDefinition): def is HttpInterceptorRouteConfig {
  return typeof def !== 'string';
}
