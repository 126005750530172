import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Route from '../../../core/constants/Route';

@Component({
  selector: 'mxp-payment-complete',
  templateUrl: './payment-complete.component.html',
  styleUrls: ['./payment-complete.component.scss'],
})
export class PaymentCompleteComponent {
  paymentIcon = '/assets/svg/confirmation-message-icon.svg';
  title = 'YOUR_PAYMENT_HAS_BEEN_COMPLETED';
  // TODO: Add message identifier to state
  subtitle = '';

  constructor(private router: Router) {}

  requestNewPayment() {
    this.router.navigate([Route.REQUEST_PAYMENT]).then();
  }
}
