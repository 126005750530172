import { Component } from '@angular/core';
import {
  MxpBulkPaymentGrpcClient,
  FileStatusEnum,
  UploadFileResponse,
  UploadFileRequest,
  ProcessFileResponse,
  ProcessFileRequest,
  DownloadFileResponse,
  GetStatusResponse,
  CancelResponse,
  ErrorLinesDto,
  BulkPaymentValidationErrorTypeEnum,
  ProcessStatusEnum,
} from '@rezolved/mxp-proto';
import { AuthService } from '@rezolved/auth';
import { TranslateService } from '@ngx-translate/core';
import { FileTypeEnum } from 'libs/ui/src/lib/enums/file-mimetypes.enum';
import { RezolveFile } from 'libs/ui/src/lib/interfaces/upload/rezolve-file';
import { MxpSnackBarService } from '../../../core/services/mxp-snackbar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MxpTranslationService } from '../../../core/services/mxp-translation.service';
import { Empty } from '@ngx-grpc/well-known-types';

@Component({
  selector: 'mxp-bulk-payment',
  templateUrl: './bulk-payment.component.html',
  styleUrls: ['./bulk-payment.component.scss'],
})
export class BulkPaymentComponent {
  element: HTMLElement | undefined;
  currentStatus: ProcessStatusEnum = ProcessStatusEnum.NO_FILE;
  isQrCodesAvailable: boolean = false;
  isQrImagesAvailable: boolean = false;
  error: boolean = false;
  showSpinner: boolean = false;
  userId: string = '';
  file: File | undefined = undefined;
  validatingFile: boolean = false;
  generateImages : boolean = false;
  generateImagesStatus: boolean = false;
  dropzoneText = [
    { label: this.translate.instant('DROP_ITEMS_HERE_OR'), isClickable: false },
    { label: this.translate.instant('BROWSE'), isClickable: true },
    { label: this.translate.instant('FOR_FILES'), isClickable: false },
  ];
  uploadConfiguration = {
    acceptedFileTypes: [FileTypeEnum.CSV],
    maxFileSize: 5000000,
  };
  uploadErrors = '';
  currentTokensCount: number = 0;
  currentImagesCount: number = 0;
  totalCount: number = 0;
  progressBarTokensValue: number = 0;
  progressBarImagesValue: number = 0;
  errorLines: ErrorLinesDto[] = [];

  constructor(
    private mxpBulkPaymentGrpcClient: MxpBulkPaymentGrpcClient,
    private translate: TranslateService,
    private authService: AuthService,
    private mxpSnackBarService: MxpSnackBarService,
    private snackBar: MatSnackBar,
    private mxpTranslationService: MxpTranslationService,
  ) {
    this.authService.user$.subscribe((user) => {
      if (user) {
        this.userId = user['https://rezolve.com/mxp_user_id'];
        this.refresh();
      } else {
        console.log('No user defined!');
      }
    });
  }

  handleGenerateImages(event:boolean){
    this.generateImages = event;
  }

  processFile() {
    this.showSpinner = true;
    const request = new ProcessFileRequest({
      generateImages : this.generateImages
    });

    this.mxpBulkPaymentGrpcClient.processFile(request).subscribe(
      (response: ProcessFileResponse) => {
        this.showSpinner = false;
        if (response.success) {
          this.snackBar.open(
            this.mxpTranslationService.getTranslation('PROCESSING_FILE_DESCRIPTION'),
            this.mxpTranslationService.getTranslation('SNACKBAR.DISMISS'),
            { duration: 2000 },
          );
          this.currentStatus = ProcessStatusEnum.PROCESSING_TOKENS;
          this.generateImagesStatus = this.generateImages;
        } else {
          this.snackBar.open(
            this.mxpTranslationService.getTranslation('PROCESSING_FILE_ERROR'),
            this.mxpTranslationService.getTranslation('SNACKBAR.DISMISS'),
            { duration: 2000 },
          );
          console.log(response.message);
          console.log('Error starting processing file');
          this.currentStatus = ProcessStatusEnum.ERROR;
        }
      },
      (error) => {
        this.showSpinner = false;
        console.log(error);
        this.snackBar.open(
          this.mxpTranslationService.getTranslation('PROCESSING_FILE_ERROR'),
          this.mxpTranslationService.getTranslation('SNACKBAR.DISMISS'),
          { duration: 2000 },
        );
      },
    );
  }

  downloadQrCodes() {
    this.mxpBulkPaymentGrpcClient.downloadQrCodes(new Empty()).subscribe(
      (response: DownloadFileResponse) => {
        let result = response?.content;
        const blob = new Blob([result ?? new Uint8Array()], { type: 'text/csv' });
        let url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'output';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      },
      (error) => {
        console.log(error);
      },
    );
  }

  downloadQrImages() {
    this.mxpBulkPaymentGrpcClient.downloadQrImages(new Empty()).subscribe(
      (response: DownloadFileResponse) => {
        let result = response?.content;
        const blob = new Blob([result ?? new Uint8Array()], { type: 'application/zip' });
        let url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'images';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      },
      (error) => {
        console.log(error);
      },
    );
  }

  onUploadErrors(errors: string[]): void {
    if (errors.length > 0) {
      this.uploadErrors = errors[0];
      return;
    }

    this.uploadErrors = '';
    this.showSpinner = false;
    this.validatingFile = false;
  }

  onUploadedFile(file: RezolveFile): void {
    this.showSpinner = true;
    this.generateImages = false;
    this.file = file.file;
    this.validatingFile = this.currentStatus == ProcessStatusEnum.INPUT_FILE_UPLOADED;
    this.file.arrayBuffer().then((buff) => {
      let arrayBuffer = new Uint8Array(buff);
      this.validatingFile = true;
      this.showSpinner = true;
      const request = new UploadFileRequest({
        name: this.file?.name,
        content: arrayBuffer,
        extension: '.csv',
      });
      this.errorLines = [];

      this.mxpBulkPaymentGrpcClient.uploadFile(request).subscribe(
        (response: UploadFileResponse) => {
          this.showSpinner = false;
          this.validatingFile = false;
          if (response.success) {
            this.snackBar.open(
              this.mxpTranslationService.getTranslation('FILE_UPLOADED'),
              this.mxpTranslationService.getTranslation('SNACKBAR.DISMISS'),
              { duration: 2000 },
            );
            // trigger refresh if user uploads new file on status 'uploaded'
            if (this.currentStatus == ProcessStatusEnum.INPUT_FILE_UPLOADED) {
              this.refresh();
            } else {
              this.currentStatus = ProcessStatusEnum.INPUT_FILE_UPLOADED;
            }
          } else {
            const errorMessage = response.errorLines;
            this.errorLines = errorMessage!;
            this.snackBar.open(
              `${this.mxpTranslationService.getTranslation('FILE_VALIDATION')}`,
              this.mxpTranslationService.getTranslation('SNACKBAR.DISMISS'),
              { duration: 2000 },
            );
            this.removeFile();
          }
        },
        (error) => {
          console.log(error);
          this.snackBar.open(
            this.mxpTranslationService.getTranslation('FILE_UPLOADED_FAIL'),
            this.mxpTranslationService.getTranslation('SNACKBAR.DISMISS'),
            { duration: 2000 },
          );
          this.showSpinner = false;
          this.validatingFile = false;
          this.removeFile();
        },
      );
    });
  }

  getErrorMessageTranslation(errorType: BulkPaymentValidationErrorTypeEnum): string {
    let map = new Map<BulkPaymentValidationErrorTypeEnum,string>([
      [BulkPaymentValidationErrorTypeEnum.EMPTY_CLIENT_ID, 'VALIDATION_CLIENT_ID_EMPTY'],
      [BulkPaymentValidationErrorTypeEnum.INVALID_AMOUNT_COLUMN, 'VALIDATION_ERROR_AMOUNT_COLUMN'],
      [BulkPaymentValidationErrorTypeEnum.INVALID_REFERENCE_COLUMN, 'VALIDATION_ERROR_REFERENCE_COLUMN'],
      [BulkPaymentValidationErrorTypeEnum.INVALID_DATE_COLUMN, 'VALIDATION_ERROR_DATE_COLUMN'],
      [BulkPaymentValidationErrorTypeEnum.INVALID_EXTENDED_REFERENCE_COLUMN, 'VALIDATION_ERROR_EXTENDED_REFERENCE_COLUMN'],
      [BulkPaymentValidationErrorTypeEnum.INVALID_DESCRIPTION_COLUMN, 'VALIDATION_ERROR_DESCRIPTION_COLUMN']]);

      return map.get(errorType) ?? 'VALIDATION_ERROR';
  }

  removeFile() {
    this.file = undefined;
    // fake click on close mat-icon in rezolve-upload component
    this.element = document.getElementsByClassName('close')[0] as HTMLElement;
    this.element.click();
  }

  clean() {
    this.showSpinner = true;
    this.generateImages = false;
    this.mxpSnackBarService
      .wrapApiCall('FILES_CLEANED', this.mxpBulkPaymentGrpcClient.cleanFiles(new Empty()), false)
      .pipe()
      .subscribe(
        (response: Empty) => {
          this.showSpinner = false;
          this.currentStatus = ProcessStatusEnum.NO_FILE;
        },
        (error) => {
          this.showSpinner = false;
          this.currentStatus = ProcessStatusEnum.ERROR;
          console.log(error);
        }
      );
  }

  refresh() {
    this.showSpinner = true;
    this.generateImages = false;
    this.mxpBulkPaymentGrpcClient.getStatus(new Empty()).subscribe(
      (response: GetStatusResponse) => {
        this.showSpinner = false;
        this.currentStatus = response.processStatus ?? ProcessStatusEnum.NO_FILE;
        this.currentTokensCount = response.currentTokensCount ?? 0;
        this.currentImagesCount = response.currentImagesCount ?? 0;
        this.totalCount = response.totalCount ?? 0;
        this.progressBarTokensValue = response.totalCount && response.currentTokensCount ? ((this.currentTokensCount * 100) / this.totalCount) : 0;
        this.progressBarImagesValue = response.totalCount && response.currentImagesCount ? ((this.currentImagesCount * 100) / this.totalCount) : 0;
        this.isQrCodesAvailable = response.qrCodesStatus && response.qrCodesStatus == FileStatusEnum.AVAILABLE ? true: false;
        this.isQrImagesAvailable = response.qrImagesStatus && response.qrImagesStatus == FileStatusEnum.AVAILABLE ? true: false;
        this.generateImagesStatus = response.generateImages ?? false;
      },
      (error) => {
        this.showSpinner = false;
        this.progressBarTokensValue = 0;
        this.progressBarImagesValue = 0;
        console.log(error);
      },
    );
  }

  cancel() {
    this.showSpinner = true;
    this.generateImages = false;
    this.mxpBulkPaymentGrpcClient.cancel(new Empty()).subscribe(
      (response: CancelResponse) => {
        this.showSpinner = false;
        if (response.success) {
          this.refresh();
        }
      },
      (error) => {
        this.showSpinner = false;
        console.log(error);
      },
    );
  }

  getCurrentTokensCountMessage(): string {
    if (this.totalCount !== 0) {
      let percentage = Math.floor((this.currentTokensCount * 100) / this.totalCount);
      return `${percentage} % `;
    }
    return '';
  }

  getCurrentImagesCountMessage(): string {
    if (this.totalCount !== 0) {
      let percentage = Math.floor((this.currentImagesCount * 100) / this.totalCount);
      return `${percentage} % `;
    }
    return '';
  }

  downloadInputTemplate() {
    this.mxpBulkPaymentGrpcClient.downloadInputTemplate(new Empty()).subscribe(
      (response: DownloadFileResponse) => {
        let result = response?.content;
        const blob = new Blob([result ?? new Uint8Array()], { type: 'text/csv' });
        let url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'input';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      },
      (error) => {
        console.log(error);
      },
    );
  }
}