import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'rezolve-tab-body',
  templateUrl: './tab-body.component.html',
})
export class TabBodyComponent {
  @ViewChild(TemplateRef)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bodyContent: TemplateRef<any> | null = null;
}
