/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { BaseTableComponent } from '@rezolved/ui';
import { UserService } from './user.service';
import { BaseDataSource } from '../../../../../../../../libs/ui/src/lib/components/atoms/base-table/base-data-source';
import { Column } from '../../../../../../../../libs/ui/src/lib/interfaces/base-table/column';
import { ColumnTypeEnum } from '../../../../../../../../libs/ui/src/lib/enums/column-type-enum';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserEditComponent } from '../../user-edit/user-edit.component';
import { UserDeleteComponent } from '../../user-delete/user-delete.component';
import { Subject } from 'rxjs';
import { User } from 'apps/mx-payment/src/app/core/interfaces/user';
import { UserData } from 'apps/mx-payment/src/app/core/interfaces/user-data';
import { TranslateService } from '@ngx-translate/core';
import { ButtonActions } from 'apps/mx-payment/src/app/core/constants/ButtonActions';

@Component({
  selector: 'mxp-users-table',
  templateUrl: '../../../../../../../../libs/ui/src/lib/components/atoms/base-table/base-table.component.html',
  styleUrls: ['../../../../../../../../libs/ui/src/lib/components/atoms/base-table/base-table.component.scss'],
})
export class UsersTableComponent extends BaseTableComponent<UserData, string,undefined> implements OnDestroy {
  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();

  @Output() userActionSucceeded = new EventEmitter();

  editButtonColumn: Column;
  deleteButtonColumn: Column;
  tableColumns: Column[] = [];

  usersTableColumns = [
    {
      key: 'created',
      displayName: this.translateService.instant('CREATED'),
      type: ColumnTypeEnum.Text,
      sortField:'createdOnFixed.keyword',
      columnWidthPercentage : '10'
    },
    {
      key: 'name',
      displayName: this.translateService.instant('NAME'),
      type: ColumnTypeEnum.Text,
      sortField:'name.keyword',
      columnWidthPercentage : '20'
    },
    {
      key: 'email',
      displayName: this.translateService.instant('EMAIL'),
      type: ColumnTypeEnum.Text,
      sortField: 'email.keyword',
      columnWidthPercentage : '15'
    },
    {
      key: 'role',
      displayName: this.translateService.instant('ROLE'),
      type: ColumnTypeEnum.Text,
      sortField: 'roles',
      columnWidthPercentage : '15'
    },
    {
      key: 'store',
      displayName: this.translateService.instant('STORE'),
      type: ColumnTypeEnum.Text,
      sortField:'storeId.keyword',
      columnWidthPercentage : '15'
    },
    {
      key: 'language',
      displayName: this.translateService.instant('LANGUAGE'),
      type: ColumnTypeEnum.Text,
      sortField:'language',
      columnWidthPercentage : '10'
    },
    {
      key: 'status',
      displayName: this.translateService.instant('STATUS'),
      type: ColumnTypeEnum.Chip,
      sortField:'active',
      columnWidthPercentage : '5'
    },
  ];

  constructor(
    readonly userService: UserService,
    private translateService: TranslateService,
    private readonly dialog: MatDialog,
  ) {
    super(new BaseDataSource<UserData>(userService));
    this.deleteButtonColumn = {
      key: ButtonActions.DELETE,
      displayName: '',
      type: ColumnTypeEnum.Button,
      buttonIcon: 'delete',
      action: this.deleteUser,
      sortField: '',
      columnWidthPercentage: '5'
    };

    this.editButtonColumn = {
      key: ButtonActions.EDIT,
      displayName: '',
      type: ColumnTypeEnum.Button,
      buttonIcon: 'edit',
      action: this.editUser,
      sortField:'',
      columnWidthPercentage:'5'
    };

    this.tableColumns = [...this.usersTableColumns, this.editButtonColumn, this.deleteButtonColumn];
  }

  editUser = (user: User) => {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '600px';
    dialogConfig.data = user.id;

    this.dialog
      .open(UserEditComponent, dialogConfig)
      .afterClosed()
      .subscribe((isUpdated: boolean) => {
        if (isUpdated) {
          this.userActionSucceeded.emit(true);
        }
      });
  };

  deleteUser = (user: User) => {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = user.id;

    this.dialog
      .open(UserDeleteComponent, dialogConfig)
      .afterClosed()
      .subscribe((isDeleted: boolean) => {
        if (isDeleted) {
          this.userActionSucceeded.emit(true);
        }
      });
  };

  ngOnDestroy() {
    super.ngOnDestroy();
    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }
}
