import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetByIdRequest, MxpStoreGrpcClient } from '@rezolved/mxp-proto';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MxpSnackBarService } from '../../../core/services/mxp-snackbar.service';

@Component({
  selector: 'mxp-delete-store',
  templateUrl: './delete-store.component.html',
  styleUrls: ['./delete-store.component.scss'],
})
export class DeleteStoreComponent implements OnDestroy {
  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();
  private _storeId: string | undefined;
  isLoading = false;
  constructor(
    private dialogRef: MatDialogRef<DeleteStoreComponent>,
    private mxpStoreGrpcClient: MxpStoreGrpcClient,
    private mxpSnackBarService: MxpSnackBarService,
    @Inject(MAT_DIALOG_DATA) storeId: string,
  ) {
    this._storeId = storeId;
  }

  deleteMxpStore(): void {
    this.isLoading = true;
    this.mxpSnackBarService
      .wrapApiCall(
        'SNACKBAR.DELETE_STORE',
        this.mxpStoreGrpcClient.deleteStore(new GetByIdRequest({ id: this._storeId })),
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => {
          this.dialogRef.close(true);
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
          this.dialogRef.close(false);
        },
      );
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }
}
