<div class="container">
  <div class="header-container">
    <h1 fxShow fxHide.lt-lg class="header-title">{{ 'REPORT' | translate }}</h1>
    <div class="button-container">
      <button class="action-button" mat-button primary (click)="refresh()">
        <mat-icon svgIcon='refresh' class="material-icon-fixed"></mat-icon>
        <span class="text">{{ 'REFRESH' | translate }}</span>
      </button>
      <button [disabled]="updatingPayments" class="action-button" mat-button primary (click)="updatePaymentStatuses()">
        <mat-icon svgIcon='updatePaymentStatuses' class="material-icon-fixed"></mat-icon>
        <span class="text">{{ 'UPDATING_PAYMENTS' | translate }}</span>
      </button>
      <button class="action-button" mat-button primary [matMenuTriggerFor]="downloadMenu">
        <mat-icon svgIcon="download-white" class="material-icon-fixed"></mat-icon>
        <span class="text">Download</span>
      </button>
    </div>
  </div>
  <span *ngIf="updatingPayments">
    <h3 class="info-text">{{ 'UPDATING_PAYMENTS_INFORMATION' | translate }}</h3>
    <h3 *ngIf="isUpdatePaymentMessageReady" class="info-text">{{ getUpdatePaymentsMessage() }}</h3>
  </span>
  <div class="filter-container"
       [ngClass]="{
      'partner-admin-filter-container':
        (authService.isAuthenticated$ | async) &&
        ((authService.roles$ | async)?.includes('MXP_PARTNER') ||
          (authService.roles$ | async)?.includes('MXP_POWER_ADMIN') ||
          (authService.roles$ | async)?.includes('MXP_REPORT_USER')),
      'default-filter-container':
        (authService.isAuthenticated$ | async) &&
        !(
          (authService.roles$ | async)?.includes('MXP_PARTNER') ||
          (authService.roles$ | async)?.includes('MXP_POWER_ADMIN')
        )
    }">
    <rezolve-date-range-picker (rangeSelected)="createdOnDateChanged($event)" [label]="createdOnDateLabel | translate"></rezolve-date-range-picker>
    <rezolve-date-range-picker (rangeSelected)="interactedOnDateChanged($event)" [label]="interactedOnDateLabel | translate"></rezolve-date-range-picker>

    <rezolve-drop-down [label]="'PAYMENT_STATUS' | translate"
                       [items]="statusOptions"
                       [optionTemplate]="optionItems"
                       [value]="selectedStatus"
                       [isMultiSelect]="true"
                       [allSelected]="allPaymentStatus"
                       (valueChange)="statusChanged($event)"
                       (selectAllChanged)="selectAllStateChanged($event)"
                       [placeholder]="'PLEASE_SELECT' | translate">
      <ng-template #optionItems let-item> {{ item.key | translate }}</ng-template>
    </rezolve-drop-down>

    <!--<rezolve-drop-down [label]="'FORMAT' | translate"
                       [items]="fileFormatOption"
                       [value]="selectedFormat"
                       (valueChange)="formatChanged($event)">
    </rezolve-drop-down>-->

    <rezolve-drop-down *ngIf="
        (authService.isAuthenticated$ | async) &&
        ((authService.roles$ | async)?.includes('MXP_PARTNER') ||
        (authService.roles$ | async)?.includes('MXP_REPORT_USER') ||
        (authService.roles$ | async)?.includes('MXP_POWER_ADMIN'))"
                       [label]="'STORES' | translate"
                       [items]="stores"
                       [optionTemplate]="storeItems"
                       [value]="selectedStoreId"
                       (valueChange)="storeChange($event)">
      <ng-template #storeItems let-item> {{ item.key | translate }}</ng-template>
    </rezolve-drop-down>

    <rezolve-drop-down *ngIf="(authService.isAuthenticated$ | async) &&
        ((authService.roles$ | async)?.includes('MXP_PARTNER') ||
        (authService.roles$ | async)?.includes('MXP_POWER_ADMIN') ||
        (authService.roles$ | async)?.includes('MXP_REPORT_USER') ||
        (authService.roles$ | async)?.includes('MXP_STORE_ADMIN'))"
                       [label]="'USERS' | translate"
                       [items]="users"
                       [optionTemplate]="userItems"
                       [value]="selectedUserId"
                       (valueChange)="userChange($event)">
      <ng-template #userItems let-item> {{ item.key | translate }}</ng-template>
    </rezolve-drop-down>

    <rezolve-drop-down *ngIf="(authService.isAuthenticated$ | async) &&
      ((authService.roles$ | async)?.includes('MXP_PARTNER') ||
      (authService.roles$ | async)?.includes('MXP_POWER_ADMIN') ||
      (authService.roles$ | async)?.includes('MXP_REPORT_USER') ||
      (authService.roles$ | async)?.includes('MXP_STORE_ADMIN'))"
                       [label]="'PAYMENT_TYPE' | translate"
                       [items]="paymentTypes"
                       [optionTemplate]="paymentypeItems"
                       [value]="selectedPaymentType"
                       (valueChange)="paymentTypeChanged($event)">
      <ng-template #paymentypeItems let-item> {{ item.key | translate }}</ng-template>
    </rezolve-drop-down>

    <rezolve-search-input [placeholder]="'SEARCH' | translate" (textChange)="applySearchTerm($event)"></rezolve-search-input>
  </div>

  <div class="data-table-container">
    <mxp-reports-table [queryArray]="query" [sortingEnabled]="true" [pageSizeOptions]="pageSizeOptions" (sortChange)="handleSortChange($event)"></mxp-reports-table>
    <div class='totals'>
      <rezolve-data-view [label]="'PAGE_TOTAL' | translate" [value]="reportsTableService.totalCount"></rezolve-data-view>
      <rezolve-data-view [label]="'REPORT_TOTAL' | translate" [value]="'MX$ ' + reportsTableService.totalAmount"></rezolve-data-view>
    </div>
  </div>
</div>

<mat-menu #downloadMenu="matMenu">
  <button mat-menu-item (click)="downloadReport(PaymentReportFileTypeEnum.PDF)">
    <span>PDF</span>
  </button>
  <button mat-menu-item (click)="downloadReport(PaymentReportFileTypeEnum.CSV)">
    <span>Excel</span>
  </button>
</mat-menu>
