import { BaseDropDownItem } from '../interfaces/drop-down/base-drop-down-item';

export class BaseDropDownItemUtils {
  static transformList(list: string[]): BaseDropDownItem[] {
    const result = list.map((item) => {
      return { key: item, value: item };
    });
    return result;
  }

  static transformEnum<TKey extends string, TEnumValue extends string | number>(enumVariable: {
    [key in TKey]: TEnumValue;
  }) {
    const result = Object.keys(enumVariable)
      .filter((key) => isNaN(Number(key)))
      .map((key: string) => {
        return {
          key,
          value: enumVariable[key as TKey],
        };
      });
    return result;
  }

  static transformEnumAndReplaceUnderscores<TKey extends string, TEnumValue extends string | number>(enumVariable: {
    [key in TKey]: TEnumValue;
  }) {
    const result = Object.keys(enumVariable)
      .filter((key) => isNaN(Number(key)))
      .map((key: string) => {
        return {
          key: key.replace(/_/gi, ' '),
          value: enumVariable[key as TKey],
        };
      });
    return result;
  }
}
