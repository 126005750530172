<mat-form-field [appearance]="appearance" class="daterange">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    panelClass="date-range-panel-position"
    [attr.dataCy]="generateDataCyId('-date-option')"
    #dateSelect
    (selectionChange)="optionChanged($event)"
    [value]="selectedOption">
    <mat-select-trigger>
      {{ selectedOption | dateRangeDisplay | translate }}
    </mat-select-trigger>

    <mat-option *ngFor="let opt of dateRangeAvailable" [value]="opt">
      <p>{{ opt.name | translate }}</p>
    </mat-option>
    <mat-option [disabled]="true">
      <rezolve-divider></rezolve-divider>
    </mat-option>
    <mat-option
      [disabled]="!customPicker.dateSelected"
      [value]="{ fixedDate: false, dateRange: customPicker.dateRange() }"
      class="calendar-selector">
      <rezolve-calendar-date-ranger-picker
        #customPicker
        [FromLabel]="'FROM' | translate"
        [ToLabel]="'TO' | translate"
        [ApplyLabel]="'APPLY' | translate"
        (rangeApproved)="customDateApproved($event)"></rezolve-calendar-date-ranger-picker>
    </mat-option>
  </mat-select>
</mat-form-field>
