import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Route from '../../../core/constants/Route';
import { MxpPaymentGrpcClient } from '@rezolved/mxp-proto';
import { AuthService } from '@rezolved/auth';
import { BasePayment } from '../payment-processing/base-payment';

@Component({
  selector: 'mxp-payment-notification',
  templateUrl: '../payment-complete/payment-complete.component.html',
  styleUrls: ['../payment-complete/payment-complete.component.scss'],
})
export class PaymentNotificationComponent extends BasePayment {
  paymentIcon = '/assets/svg/confirmation-message-icon.svg';
  title = 'PAYMENT_NOTIFICATION_HAS_BEEN_SENT';
  subtitle = 'YOU_WILL_RECEIVE_A_NOTIFICATION_ON_YOUR_PHONE_SHORTLY';

  constructor(router: Router, authService: AuthService, mxpPaymentGrpcClient: MxpPaymentGrpcClient) {
    super(router, mxpPaymentGrpcClient, authService);
  }

  requestNewPayment() {
    this.router.navigate([Route.REQUEST_PAYMENT]).then();
  }
}
