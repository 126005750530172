<mat-card [attr.dataCy]="generateDataCyId('-card')">
  <div class="header">
    <div class="content">
      <ng-content select="[header]" [attr.dataCy]="generateDataCyId('-card-header')"></ng-content>
    </div>
    <span class="toggle-content" [ngClass]="{ show: show, hide: !show }" (click)="onToggle()">{{
      !show ? toggleTextHide : toggleTextShow
    }}</span>
  </div>
  <mat-card-content [@bodyExpansion]="state" class="expandable-content">
    <div>
      <mat-divider></mat-divider>
    </div>
    <div class="body">
      <ng-content select="[body]" [attr.data-cy]="generateDataCyId('-card-body')"></ng-content>
    </div>
  </mat-card-content>
</mat-card>
