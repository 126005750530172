import { Component, Input } from '@angular/core';
import { Address } from '../../../interfaces/address';
import { BasicListItem } from '../../abstract/basic-list-item';

@Component({
  selector: 'rezolve-address-list-item',
  templateUrl: './address-list-item.component.html',
  styleUrls: ['./address-list-item.component.scss'],
})
export class AddressListItemComponent extends BasicListItem<Address> {
  @Input() editLabel = 'Edit';
  @Input() removeLabel = 'Remove';
}
