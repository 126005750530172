import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl, FormControl, ValidatorFn, Validators, ValidationErrors } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { CustomFormField } from '../../abstract/custom-form-field';

@Component({
  selector: 'rezolve-basic-input',
  templateUrl: './basic-input.component.html',
  styleUrls: ['./basic-input.component.scss', '../../../styles/common.styles.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: BasicInputComponent }],
  encapsulation: ViewEncapsulation.None,
})
export class BasicInputComponent<T> extends CustomFormField<T> implements OnInit, OnDestroy {
  @Input() validators: ValidatorFn[] = [];
  @Input() pattern = '';
  @Input() tooltipMessage = '';
  @Input() maxLength?: number;
  @Input() errorDescriptions: { [key: string]: string } = {};
  @Input() isPassword = false;
  @Input() readonly = false;
  hideText = false;
  @Input() iconPrefix = '';
  @Input() iconSuffix = '';

  @Output() errorEvent: EventEmitter<string> = new EventEmitter();
  @Output() handleSuffixClick: EventEmitter<void> = new EventEmitter();
  @ViewChild('inputField') inputField!: ElementRef;

  errorMessage: string | null = null;

  control: FormControl = new FormControl('');

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    protected focusMonitor: FocusMonitor,
    protected elRef: ElementRef<HTMLElement>,
  ) {
    super(ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.focusMonitor.monitor(this.elRef, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
    this.componentName = 'basic';
  }

  ngOnInit(): void {
    this.id = `rezolve-${this.componentName}-input-${BasicInputComponent.nextId++}`;
    this.controlType = `rezolve-${this.componentName}-input-control`;

    if (this.required) {
      this.validators.push(Validators.required);
    }
    if (this.type === 'email') {
      this.validators.push(Validators.email);
    }
    if(this.isPassword){
      this.hideText = true;
    }
    if (this.pattern) {
      this.validators.push(Validators.pattern(this.pattern));
    }
    if (this.maxLength) {
      this.validators.push(Validators.maxLength(this.maxLength));
    }

    this.ngControl?.control?.validator && this.validators.push(this.ngControl.control?.validator);
    this.control.setValidators(this.validators);
  }

  suffixClick(): void {
    this.handleSuffixClick.emit();
  }

  getState(): T | null {
    return this.control.value;
  }

  setState(value: T | null): void {
    this.control.setValue(value);
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elRef);
  }

  onInput(): void {
    this.markAsTouched();
    this.onChange(this.value);
  }

  disableControl(disabled: boolean): void {
    disabled ? this.control.disable() : this.control.enable();
  }

  get errorState(): boolean {
    return this.control.valid && this.isTouched();
  }

  getFirstError(errors: ValidationErrors): string {
    return Object.keys(errors)[0];
  }

  get hasErrors(): boolean {
    if (!this.control.errors) {
      this.errorMessage = null;
      return false;
    }

    this.errorMessage = this.errorDescriptions[this.getFirstError(this.control.errors)] ?? null;
    return this.errorMessage ? true : false;
  }
}
