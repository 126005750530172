import { Injectable } from '@angular/core';
import { getCountryCallingCode, getCountries } from 'libphonenumber-js';
@Injectable({
  providedIn: 'root',
})
export class CountriesPhoneHelperService {
  getIso2CountriesCodeWithDialCodes() {
    return getCountries().map((cc: any) => ({
      CC: cc,
      DialCode: getCountryCallingCode(cc),
    }));
  }

  public getCountryByCode(code: any, defaultCountries: Array<CountryPhoneCodeModel>): CountryPhoneCodeModel {
    return (
      defaultCountries.find((c) => c.iso2 === code.toLowerCase()) || {
        name: 'UN',
        iso2: 'UN',
        dialCode: '0',
        flagClass: 'UN',
      }
    );
  }
}
export interface CountryPhoneCodeModel {
  name: string;
  iso2: string;
  dialCode: string;
  flagClass: string;
}
