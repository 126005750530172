import { Component } from '@angular/core';
import { Column } from '../../../interfaces/base-table/column';
import { TableTestService, TestTableData } from './table-test.service';
import { BaseDataSource } from './base-data-source';
import { BaseTableComponent } from './base-table.component';
import { ColumnTypeEnum } from '../../../enums/column-type-enum';

@Component({
  selector: 'rezolve-table-test',
  templateUrl: './base-table.component.html',
  styleUrls: ['./base-table.component.scss'],
})
export class TableTestComponent extends BaseTableComponent<TestTableData, string,undefined> {
  tableColumns: Column[] = [];

  constructor(private dummyService: TableTestService) {
    super(new BaseDataSource<TestTableData>(dummyService));
    this.tableColumns = [
      {
        key: 'name',
        displayName: 'Name',
        type: ColumnTypeEnum.Text,
        sortField:'',
        columnWidthPercentage:''
      },
      {
        key: 'email',
        displayName: 'Email',
        type: ColumnTypeEnum.Text,
        sortField:'',
        columnWidthPercentage:''
      },
      {
        key: 'role',
        displayName: 'Role',
        type: ColumnTypeEnum.Text,
        sortField:'',
        columnWidthPercentage:''
      },
      {
        key: 'store',
        displayName: 'Store',
        type: ColumnTypeEnum.Text,
        sortField:'',
        columnWidthPercentage:''
      },
      {
        key: 'timezone',
        displayName: 'Timezone',
        type: ColumnTypeEnum.Text,
        sortField:'',
        columnWidthPercentage:''
      },
      {
        key: 'language',
        displayName: 'Language',
        type: ColumnTypeEnum.Text,
        sortField:'',
        columnWidthPercentage:''
      },
      {
        key: 'created',
        displayName: 'Created',
        type: ColumnTypeEnum.Button,
        buttonIcon: 'home',
        sortField:'',
        columnWidthPercentage:''
      },
      {
        key: 'status',
        displayName: 'Status',
        type: ColumnTypeEnum.Chip,
        sortField:'',
        columnWidthPercentage:''
      },
      {
        key: 'actions',
        displayName: '',
        type: ColumnTypeEnum.Actions,
        sortField:'',
        columnWidthPercentage:''
      },
    ];

    this.lastColumnActions = [
      {
        label: 'Delete',
        action: this.delete,
      },
    ];
  }

  delete(row: TestTableData) {
    console.log('Delete data', row);
  }
}
