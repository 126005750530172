<mxp-payment-sent [configuration]="paymentSentConfiguration">
  <img alt="payment-complete" [src]="'/assets/svg/notification-payment-sent.svg'" />
  <h2 class="configuration-price" *ngIf="paymentSentConfiguration?.paymentConfiguration?.price">
    <strong>{{ paymentSentConfiguration?.paymentConfiguration?.price | currency: 'MXN' }}</strong>
  </h2>
  <p class="payment-additional-info">{{ paymentSentConfiguration?.paymentConfiguration?.description }}</p>
  <p class="payment-additional-info">{{ 'PAYMENT_EXPIRES' | translate }} {{ expiryDate | translate }}</p>
  <p class="payment-additional-info">
    {{ 'SENT_TO' | translate }}:
    {{ paymentSentConfiguration?.paymentConfiguration?.mobileNumber | formatPhoneNumber: 'MXN' }}
  </p>
</mxp-payment-sent>
