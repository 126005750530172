import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { CustomFormField } from '../../abstract/custom-form-field';

import {
  CountryCode as CC,
  E164Number,
  parsePhoneNumber,
  parsePhoneNumberFromString,
  PhoneNumber,
} from 'libphonenumber-js';
import { CountriesPhoneHelperService, CountryPhoneCodeModel, PhoneCountryList } from '@rezolved/util';
import { ErrorStateMatcher } from '@angular/material/core';

const MAX_PHONE_NUMBER_LENGTH = 15;

@Component({
  selector: 'rezolve-phone-input',
  templateUrl: './phone-input-component.component.html',
  styleUrls: ['./phone-input-component.component.scss', '../../../styles/common.styles.scss'],
})
export class PhoneInputComponent extends CustomFormField<E164Number> implements OnInit, OnDestroy {
  @Input() maxLength = MAX_PHONE_NUMBER_LENGTH;
  @Input() phonePlaceHolder = '';
  @Input() filterCountryCodes: string[] = [];
  @Input() phoneNumberCountry: CC | undefined;
  @Input() errorMatcher: ErrorStateMatcher;
  @Input() phoneNumber: E164Number = '';
  @Input() phoneNumberErrorLabel = 'Please provide valid phone number.';
  @Input() requiredErrorLabel = 'Phone is required';

  @Output() errorEvent: EventEmitter<string> = new EventEmitter();
  @Output()
  phoneChanged: EventEmitter<E164Number | null> = new EventEmitter<E164Number | null>();

  phoneNumberStruct: PhoneNumber | undefined;
  avaiableCountries: Array<CountryPhoneCodeModel> = PhoneCountryList;
  selectedCountry: CountryPhoneCodeModel = PhoneCountryList[0];

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _changeDetectorRef: ChangeDetectorRef,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private countryHelper: CountriesPhoneHelperService,
  ) {
    super(ngControl, `rezolve-phone-input-${PhoneInputComponent.nextId++}`);
    this.controlType = 'rezolve-phone-input-control';
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.focusMonitor.monitor(this.elRef, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
    this.errorMatcher = new ErrorStateMatcher();
  }

  ngOnInit(): void {
    if (this.filterCountryCodes.length > 0) {
      this.avaiableCountries = PhoneCountryList.filter((c) => this.filterCountryCodes.includes(c.iso2));
    }

    this.phoneNumber = this.formattedPhoneNumber;
    const parsedPhoneResult = this.parsePhone(this.phoneNumber.toString(), this.phoneNumberCountry);
    if (parsedPhoneResult != null) {
      this.phoneNumberStruct = parsedPhoneResult;
    }
    this.value = this.phoneNumberStruct?.number || '';
    if (this.phoneNumberStruct && this.phoneNumberStruct.country) {
      this.selectedCountry = this.countryHelper.getCountryByCode(this.phoneNumberStruct.country, PhoneCountryList);
    } else {
      this.selectedCountry = this.avaiableCountries[0];
    }

    this._changeDetectorRef.markForCheck();
    this.stateChanges.next();
    this.prepareInput();
  }

  prepareInput() {
    this.errorState = true;
    try {
      this.phoneNumberStruct = parsePhoneNumberFromString(
        this.phoneNumber.toString(),
        this.selectedCountry.iso2.toUpperCase() as CC,
      );
      this.value = this.phoneNumberStruct?.number || '';
      this.phoneChanged.emit(this.value);
      if (this.phoneNumberStruct && this.phoneNumberStruct.isValid()) {
        if (this.phoneNumber !== this.formattedPhoneNumber) {
          this.phoneNumber = this.formattedPhoneNumber;
        }
        this.errorState = false;
      } else {
        this.setError();
      }
    } catch (e) {
      this.value = this.phoneNumber.toString();
      this.setError();
    }
    this._changeDetectorRef.markForCheck();
  }

  parsePhone(phoneNumber: string, countryCode: CC | undefined): PhoneNumber | null {
    try {
      if (phoneNumber.length > 0 && (countryCode?.length || 0) > 0) {
        return parsePhoneNumber(this.phoneNumber.toString(), this.phoneNumberCountry);
      }
      return null;
    } catch {
      return null;
    }
  }

  dialCodeChanged(): void {
    this._changeDetectorRef.markForCheck();
    this.stateChanges.next();
    this.phoneNumber = '';
  }

  onNgModelChange(model: NgModel): void {
    this.errorState = true;
    try {
      this.phoneNumberStruct = parsePhoneNumberFromString(
        this.phoneNumber.toString(),
        this.selectedCountry.iso2.toUpperCase() as CC,
      );
      this.value = this.phoneNumberStruct?.number || '';
      this.phoneChanged.emit(this.value);
      if (this.phoneNumberStruct && this.phoneNumberStruct.isValid()) {
        if (this.phoneNumber !== this.formattedPhoneNumber) {
          this.phoneNumber = this.formattedPhoneNumber;
        }
        this.errorState = false;
      } else {
        model.control.setErrors({ invalidPhone: true });
        this.setError();
      }
    } catch (e) {
      this.value = this.phoneNumber.toString();
      model.control.setErrors({ invalidPhone: true });
      this.setError();
    }
    this._changeDetectorRef.markForCheck();
  }

  setError(): void {
    this.errorState = true;
    this.errorMessage = this.phoneNumberErrorLabel;
    this.errorEvent.emit(this.errorMessage || undefined);
  }

  private get formattedPhoneNumber(): string {
    if (!this.phoneNumberStruct) {
      return this.phoneNumber.toString();
    }
    return this.phoneNumberStruct.formatNational();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elRef);
  }
}
