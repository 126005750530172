import * as moment from 'moment';
import { ICalendarOption } from '../../atoms/calendar-date-ranger-picker/ICalendarOption';
export const FixedDateOption: ICalendarOption[] = [
  {
    name: 'TODAY',
    fixedDate: true,
    dateRange: { start: moment().startOf('day').toDate(), end: moment().endOf('day').toDate() },
  },
  {
    name: 'YESTERDAY',
    fixedDate: true,
    dateRange: {
      start: moment().subtract(1, 'day').startOf('day').toDate(),
      end: moment().subtract(1, 'day').endOf('day').toDate(),
    },
  },
  {
    name: 'PAST_MONTH',
    fixedDate: true,
    dateRange: { start: moment().subtract(1, 'month').startOf('day').toDate(), end: moment().endOf('day').toDate() },
  },
  {
    name: 'PAST_THREE_MONTHS',
    fixedDate: true,
    dateRange: { start: moment().subtract(3, 'month').startOf('day').toDate(), end: moment().endOf('day').toDate() },
  },
  {
    name: 'PAST_SIX_MONTHS',
    fixedDate: true,
    dateRange: { start: moment().subtract(6, 'month').startOf('day').toDate(), end: moment().endOf('day').toDate() },
  },
  {
    name: 'ALWAYS',
    fixedDate: true,
    dateRange: { start: moment.utc('0001-01-01').toDate(), end: moment().endOf('day').toDate() },
  },
];
export const CustomDateOption = [
  {
    name: 'Custom',
    fixedDate: false,
    dateRange: { start: moment().startOf('day').toDate(), end: moment().endOf('day').toDate() },
  },
];
export const CalendarOptionsAll: ICalendarOption[] = [...FixedDateOption, ...CustomDateOption];
