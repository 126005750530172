import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'mxp-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit, AfterViewInit {
  @Input() width: number = 0;
  @Input() height: number = 0;
  @ViewChild('canvas') canvas!: ElementRef<HTMLCanvasElement>;
  @Output() isSignatureEmpty: EventEmitter<boolean> = new EventEmitter();
  @Output() onSave = new EventEmitter<Uint8Array>();
  signaturePad!: SignaturePad;
  isSignEmpty = false;
  isSignatureValid = true;
  isInFocus = false;
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvas.nativeElement, {
      minWidth: 2,
      maxWidth: 4,
      penColor: 'rgb(0, 0, 0)',
    });

    this.signaturePad.addEventListener('beginStroke', () => {
      this.isSignEmpty = this.signaturePad.isEmpty();
      this.isSignatureValid = this.isValidSignature();
    });

    this.signaturePad.addEventListener('endStroke', () => {
      this.isSignEmpty = this.signaturePad.isEmpty();
      this.isSignatureValid = this.isValidSignature();
      this.save();
    });
  }

  clear() {
    this.signaturePad.clear();
    this.isSignEmpty = this.signaturePad.isEmpty();
    this.isSignatureEmpty.emit(this.signaturePad.isEmpty());
  }

  save() {
    if (!this.signaturePad.isEmpty() && this.isValidSignature()) {
      const signatureDataUrl = this.signaturePad.toDataURL();
      const byteCharacters = atob(signatureDataUrl.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const signatureByteArray = new Uint8Array(byteNumbers);
      this.onSave.emit(signatureByteArray);
      return;
    }
    this.isSignEmpty = false;
    this.onSave.emit();
    return;
  }

  isValidSignature(): boolean {
    let dotCont = 0;
    const data = this.signaturePad.toData();
    if (data.length === 0) return false;
    data.forEach((element) => {
      if (element.points.length != 0) dotCont += element.points.length;
    });
    if (dotCont < 4) return false;
    return true;
  }
}
