<div class="container">
  <h1>{{ header }}</h1>
  <rezolve-secondary-button
    class="button"
    classList="large"
    [prefixIcon]="buttonPrefixIcon"
    [suffixIcon]="buttonSuffixIcon"
    (handleClick)="onClick($event)"
    >{{ buttonText }}
  </rezolve-secondary-button>
  <p class="mat-caption">{{ caption }}</p>
</div>
