import { Component } from '@angular/core';
import { ActivatedRoute, convertToParamMap, Params, Router } from '@angular/router';
import { AuthService } from '@rezolved/auth';
import { GetByIdRequest, MxpPaymentGrpcClient, PaymentTypeEnum } from '@rezolved/mxp-proto';
import { first } from 'rxjs/operators';
import Route from '../../../core/constants/Route';
import { PaymentConfiguration } from '../../../core/interfaces/payment-configuration';
import { PaymentSentConfiguration } from '../../../core/interfaces/payment-sent-configuration';

@Component({
  selector: 'mxp-payment-processing',
  templateUrl: './payment-processing.component.html',
  styleUrls: ['./payment-processing.component.scss'],
})
export class PaymentProcessingComponent {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private mxpPaymentGrpcClient: MxpPaymentGrpcClient,
  ) {
    this.configuration = this.router.getCurrentNavigation()?.extras.state?.configuration;
    if (this.configuration) {
      this.paymentType = this.configuration.type;
      this.setPaymentSentConfiguration(this.paymentType, this.configuration);
      return;
    }

    this.activatedRoute.params.subscribe((params: Params) => {
      const paramsMap = convertToParamMap(params);
      if (paramsMap.has('id')) {
        const paymentId = paramsMap.get('id') ?? '';
        this.authService.user$.pipe(first()).subscribe((user) => {
          if (user) {
            this.retrievePayment(paymentId);
          }
        });
      } else {
        this.error = true;
      }
    });
  }

  error = false;
  configuration: PaymentConfiguration | null = null;
  PaymentTypeEnum = PaymentTypeEnum;
  paymentType: PaymentTypeEnum | null = null;

  paymentSentConfiguration: PaymentSentConfiguration | null = null;

  requestNewPayment() {
    this.router.navigate([Route.REQUEST_PAYMENT]).then();
  }

  retrievePayment(paymentId: string): void {
    const paymentRequest = new GetByIdRequest({
      id: paymentId,
    });
    this.mxpPaymentGrpcClient.getPayment(paymentRequest).subscribe(
      (response) => {
        const paymentType = response.type === 'codiqr' ? PaymentTypeEnum.QR : PaymentTypeEnum.NOTIFICATION;
        this.configuration = {
          id: paymentId,
          type: paymentType,
        };
        this.configuration.description = response.description;
        this.configuration.price = response.amount?.toString();
        this.configuration.qr = response.payload;
        this.configuration.reference = response.reference;
        this.paymentType = paymentType;
        this.setPaymentSentConfiguration(this.paymentType, this.configuration);
      },
      (error) => {
        console.log(error);
        this.error = true;
      },
    );
  }
  private setPaymentSentConfiguration(paymentType: PaymentTypeEnum, paymentConfiguration: PaymentConfiguration) {
    switch (paymentType) {
      case PaymentTypeEnum.NOTIFICATION:
        this.paymentSentConfiguration = {
          paymentConfiguration,
          title: 'PAYMENT_NOTIFICATION_SENT',
        };
        break;

      case PaymentTypeEnum.QR:
        this.paymentSentConfiguration = {
          paymentConfiguration,
          title: 'SCAN_QR_CODE',
        };
        break;

      default:
        break;
    }
  }
}
