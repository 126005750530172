<rezolve-basic-list
  #basicList
  [items]="items"
  [componentName]="componentName"
  [showPagination]="showPagination"
  [allowMultiSelection]="multiSelection"
  [areEqual]="areEqual"
  (selectionChanged)="selectionChanged.emit($event)">
  <ng-template #item let-item="item">
    <rezolve-address-list-item
      [componentName]="componentName"
      [item]="item"
      [isSelected]="basicList.isSelected(item)"
      (selected)="basicList.handleItemSelected(item)"
      (unselected)="basicList.handleItemUnselected(item)"
      (edit)="itemEdited.emit(item)"
      (remove)="basicList.handleItemRemoved(item)"
      [showEdit]="showEdit"
      [showRemove]="showRemove">
    </rezolve-address-list-item>
  </ng-template>
</rezolve-basic-list>
