import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@rezolved/auth';
import { GetByIdRequest, GetPaymentStatusResponse, MxpPaymentGrpcClient, PaymentStatusEnum } from '@rezolved/mxp-proto';
import { interval, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import Route from '../../../core/constants/Route';
import { PaymentConfiguration } from '../../../core/interfaces/payment-configuration';

@Directive()
export abstract class BasePayment implements OnInit, OnDestroy {
  constructor(
    protected router: Router,
    private mxpPaymentGrpcClient: MxpPaymentGrpcClient,
    private authService: AuthService,
  ) {}

  @Input()
  configuration!: PaymentConfiguration;

  @Input()
  timeRange = 10;

  protected subscription?: Subscription;
  counter = 0;

  updateTimer(): void {
    if (this.counter === 0) {
      this.counter = this.timeRange;
      this.authService.user$.pipe(first()).subscribe((user) => {
        if (user) {
          const paymentStatusRequest = new GetByIdRequest({
            id: this.configuration.id,
          });
          this.mxpPaymentGrpcClient.getPaymentStatus(paymentStatusRequest).subscribe(
            (response) => {
              if (response) {
                switch (response.status) {
                  case PaymentStatusEnum.PAYMENT_MADE:
                    this.redirectByPaymentStatus(response, true);
                    break;
                  case PaymentStatusEnum.REQUEST_REJECTED:
                    this.redirectByPaymentStatus(response, false);
                    break;
                  default:
                    break;
                }
              }
            },
            (error) => console.log(error),
          );
        }
      });

      return;
    }
    this.counter--;
  }

  redirectByPaymentStatus(response: GetPaymentStatusResponse,isPaid: boolean): void {
    this.router.navigate([Route.PAYMENT_STATUS], {
      state: {
        paymentStatus: response,
        imageUrl: isPaid ? '/assets/svg/payment-received.svg' : '/assets/svg/payment-rejected.svg',
        primaryStatusText: isPaid ?'PAYMENT_RECEIVED' : 'PAYMENT_CANCELLED',
        secondaryStatusText: isPaid ? 'PAID': '',
      },
    });
  }

  cancelRequest(): void {
    this.router.navigate([Route.REQUEST_PAYMENT]);
  }

  setupPayment(): void {
    this.subscription = interval(1000).subscribe(() => {
      this.updateTimer();
    });
  }

  ngOnInit() {
    this.setupPayment();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}