import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, defer, merge, of, ReplaySubject, Subject } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, mergeMap, scan, switchMap } from 'rxjs/operators';
import { Auth0ClientService } from './auth0.client';
import { Auth0Client } from '@auth0/auth0-spa-js';

@Injectable({
  providedIn: 'root',
})
export class Auth0State {
  private isLoadingSubject$ = new BehaviorSubject<boolean>(true);
  private refresh$ = new Subject<void>();
  private accessToken$ = new ReplaySubject<string>(1);
  private errorSubject$ = new ReplaySubject<Error>(1);

  public readonly isLoading$ = this.isLoadingSubject$.asObservable();

  private accessTokenTrigger$ = this.accessToken$.pipe(
    scan(
      (acc: { current: string | null; previous: string | null }, current: string | null) => {
        return {
          previous: acc.current,
          current,
        };
      },
      { current: null, previous: null },
    ),
    filter(({ previous, current }) => previous !== current),
  );

  private readonly isAuthenticatedTrigger$ = this.isLoading$.pipe(
    filter((loading) => !loading),
    distinctUntilChanged(),
    switchMap(() =>
      merge(
        defer(() => this.auth0Client.isAuthenticated()),
        this.accessTokenTrigger$.pipe(mergeMap(() => this.auth0Client.isAuthenticated())),
        this.refresh$.pipe(mergeMap(() => this.auth0Client.isAuthenticated())),
      ),
    ),
  );

  readonly isAuthenticated$ = this.isAuthenticatedTrigger$.pipe(distinctUntilChanged());

  readonly user$ = this.isAuthenticatedTrigger$.pipe(
    concatMap((authenticated) => (authenticated ? this.auth0Client.getUser() : of(null))),
  );

  readonly idTokenClaims$ = this.isAuthenticatedTrigger$.pipe(
    concatMap((authenticated) => (authenticated ? this.auth0Client.getIdTokenClaims() : of(null))),
  );

  public readonly error$ = this.errorSubject$.asObservable();

  constructor(@Inject(Auth0ClientService) private auth0Client: Auth0Client) {}

  public setIsLoading(isLoading: boolean): void {
    this.isLoadingSubject$.next(isLoading);
  }

  public refresh(): void {
    this.refresh$.next();
  }

  public setAccessToken(accessToken: string): void {
    this.accessToken$.next(accessToken);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setError(error: any): void {
    this.errorSubject$.next(error);
  }
}
