<mat-form-field [appearance]="appearance" class="card-expiry-container">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    #model="ngModel"
    matInput
    numericInput
    expiryDateInput
    [(ngModel)]="formattedValue"
    [placeholder]="placeholder"
    [required]="required"
    [disabled]="disabled"
    [pattern]="pattern"
    [maxlength]="maxLength"
    (ngModelChange)="onNgModelChange(model)" />
  <mat-error *ngIf="this.errorState">
    {{ errorMessage }}
  </mat-error>
</mat-form-field>
