<div class="tabs-header">
  <button
    mat-button
    class="tab-header"
    (click)="selectTab(item)"
    [class.active]="selectedTab === item"
    *ngFor="let item of tabItems$ | async">
    <ng-container *ngIf="item.headerComponent">
      <ng-container *ngTemplateOutlet="item.headerComponent.headerContent"> </ng-container>
    </ng-container>
    <ng-container *ngIf="!item.headerComponent">
      {{ item.label }}
    </ng-container>
  </button>
</div>
<div class="tabs-body">
  <ng-container *ngIf="selectedTab && selectedTab.bodyComponent">
    <ng-container *ngTemplateOutlet="selectedTab.bodyComponent.bodyContent"> </ng-container>
  </ng-container>
</div>
