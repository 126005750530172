import { PaymentStatusEnum } from '@rezolved/mxp-proto';

export enum AdditionalPaymentStatusEnum {
  ALL = 'ALL',
}

export type PaymentStatusOption = PaymentStatusEnum | AdditionalPaymentStatusEnum;

export const PaymentStatusOptions: PaymentStatusOption[] = [
  PaymentStatusEnum.NOT_INTERACTED,
  PaymentStatusEnum.INTERIM,
  PaymentStatusEnum.PAYMENT_MADE,
  PaymentStatusEnum.REQUEST_REJECTED,
  PaymentStatusEnum.REQUEST_POSTPONED,
];
