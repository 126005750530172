import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { MxpTranslationService } from './mxp-translation.service';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { setLoaded, setLoading } from '../store/actions/app.actions';
import { IAppState } from '../store/app.interface';

@Injectable({
  providedIn: 'root',
})
export class MxpSnackBarService {
  constructor(
    private snackBar: MatSnackBar,
    private readonly store: Store<IAppState>,
    private mxpTranslationService: MxpTranslationService
  ) {}

  private readonly failedMessageSufix = '_FAIL';

  showSnackbarMessage(
    message: string,
    params: string = '',
    dissmissMessage: string = 'SNACKBAR.DISMISS',
    duration = 3000
  ) {
    this.snackBar.open(
      this.mxpTranslationService.getTranslation(message) + params,
      this.mxpTranslationService.getTranslation(dissmissMessage),
      {
        duration: duration,
        panelClass: 'snackbar-long'
      }
    );
  }
  wrapApiCall<T>(
    message: string,
    observable: Observable<T>,
    showLoader: boolean = false,
    dissmissMessage: string = 'SNACKBAR.DISMISS',
    duration = 3000,
    displayOnlyError = false
  ): Observable<T> {
    showLoader && this.store.dispatch(setLoading());
    return observable.pipe(
      finalize(() => showLoader && this.store.dispatch(setLoaded())),
      tap(
        (response: any) => {
          console.log(response);
          // TODO: verify
          // if(message == 'SNACKBAR.ENROLL_MERCHANT' && !response.success)
          // {
          //   const res : AddBulkMerchantResponse =  response;
          //   const msg = `${res.error?.code} ${res.error?.description}`;
          //   this.snackBar.open(
          //     msg,
          //     this.mxpTranslationService.getTranslation(dissmissMessage),
          //     { duration: 5000 },
          //   );
          // } else
          // this.snackBar.open(
          //     this.mxpTranslationService.getTranslation(message),
          //   this.mxpTranslationService.getTranslation(dissmissMessage),
          //   { duration: duration },
          // );
          if (!displayOnlyError) {
            this.snackBar.open(
              this.mxpTranslationService.getTranslation(message),
              this.mxpTranslationService.getTranslation(dissmissMessage),
              { duration: duration },
            );
          }
        },
        (error) => {
          if (
            message == 'SNACKBAR.MAKE_NOTIFICATION_PAYMENT' &&
            error.statusMessage.includes('unregistered_customer_cell_phone')
          ) {
            this.snackBar.open(
              this.mxpTranslationService.getTranslation('PAYMENT_NOTIFICATION_PHONE_NOT_REGISTERED'),
              this.mxpTranslationService.getTranslation(dissmissMessage),
              { duration: duration },
            );
          } else {
            this.snackBar.open(
              this.mxpTranslationService.getTranslation(message + this.failedMessageSufix),
              this.mxpTranslationService.getTranslation(dissmissMessage),
              { duration: duration },
            );
          }
        },
      ),
    );
  }
}
