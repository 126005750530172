<mat-sidenav-container [hasBackdrop]="false" fxFlexFill>
  <!-- desktop -->
  <mat-sidenav #sidenav fxHide.lt-lg opened mode="side" disableClose class="fixed-sidenav">
    <mat-toolbar>
      <mat-toolbar-row class="header-toolbar-row">
        <ng-container *ngTemplateOutlet="navbarHeader"></ng-container>
      </mat-toolbar-row>
    </mat-toolbar>
    <ng-container *ngTemplateOutlet="navbarSection"></ng-container>
    <mat-nav-list>
      <ng-container *ngTemplateOutlet="navbarItems"></ng-container>
    </mat-nav-list>
  </mat-sidenav>

  <!-- mobile -->
  <mat-sidenav #sidenav fxHide.gt-md [opened]="opened" class="side-nav-mobile" position="end">
    <mat-toolbar>
      <mat-toolbar-row class="header-toolbar-row">
        <ng-container *ngTemplateOutlet="navbarHeader"></ng-container>
        <button mat-icon-button (click)="toggle()" fxShow="true" class="toggle-button">
          <mat-icon *ngIf="!sidenav.opened">menu</mat-icon>
          <mat-icon *ngIf="sidenav.opened">close</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
    <ng-container *ngTemplateOutlet="navbarSection; context: {$implicit: this}"></ng-container>
    <mat-nav-list>
      <ng-container *ngTemplateOutlet="navbarItems"></ng-container>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content class="sidenav-content">
    <mat-toolbar class="toolbar">
      <mat-toolbar-row fxFill fxLayoutAlign.gt-md="end center" fxLayoutAlign="center center" fxLayout="row">
        <ng-container *ngTemplateOutlet="navbarToolbarContent"></ng-container>
        <button fxHide.gt-md class="burger-button" mat-icon-button (click)="toggle()">
          <mat-icon *ngIf="!opened">menu</mat-icon>
          <mat-icon *ngIf="opened">close</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
    <ng-container *ngTemplateOutlet="navbarContent"></ng-container>
  </mat-sidenav-content>
</mat-sidenav-container>
