import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl, NgModel, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { CustomFormField } from '../../abstract/custom-form-field';

@Component({
  selector: 'rezolve-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CheckboxComponent }],
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxComponent extends CustomFormField<boolean> implements OnInit, OnDestroy {
  @Output() errorEvent: EventEmitter<string> = new EventEmitter();
  @Output() checkedChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() errorMessage = 'This field is required';
  @Input() indeterminate = false;
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
  ) {
    super(ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.focusMonitor.monitor(this.elRef, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
    this.componentName = 'checkbox';
  }

  @Input() set checked(value: boolean) {
    this.value = value;
    this.onChange(value);
    this.stateChanges.next();
  }

  get checked(): boolean {
    return this.value || false;
  }

  ngOnInit(): void {
    this.id = `rezolve-${this.componentName}-input-${CheckboxComponent.nextId++}`;
    this.controlType = `rezolve-${this.componentName}-input-control`;
    this.value = this.checked;
  }

  onNgModelChange(model: NgModel) {
    const validationResult = this.required ? Validators.requiredTrue(model.control) : null;
    this.checked = model.value;
    this.errorState = validationResult != null;
    this.checkedChanged.emit(model.value);
    model.control.markAsTouched();
    if (this.errorState) {
      model.control.setErrors(validationResult);
      this.errorEvent.emit(this.errorMessage || undefined);
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elRef);
  }
}
