import { Component, OnDestroy, OnInit } from '@angular/core';
import { Empty, Timestamp } from '@ngx-grpc/well-known-types';
import { AuthService } from '@rezolved/auth';
import {
  GenerateBillingRequest,
  GenerateBillingResponse,
  GetMxpPartnerReply,
  GetMxpPartnersReply,
  MxpBillingGrpcClient,
  MxpPartnerGrpcClient,
  PricingRuleDto,
} from '@rezolved/mxp-proto';
import * as _moment from 'moment';
import { Observable, Subscription, of, throwError } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { Auth0MxpUserRoles } from '../../core/constants/UserGroups';
import { MxpSnackBarService } from '../../core/services/mxp-snackbar.service';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {default as _rollupMoment, Moment} from 'moment';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'mxp-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
  providers:[
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class BillingComponent implements OnInit, OnDestroy {
  private partnerId?: string;
  private componentSubs = new Subscription();
  isLoading = false;
  pricingRules: PricingRuleDto[] = [];
  paymentsMade: number | undefined = 0;
  price: number | undefined = 0;
  totalPayments: number | undefined = 0;
  dateFromObj: Date = new Date();
  dateToObj: Date = new Date();
  displayedColumns: string[] = ['pricing-range', 'price'];
  dataSource: any = undefined;
  partnersList: GetMxpPartnerReply[] = [];
  mxpUserRoles = Auth0MxpUserRoles;
  rolesWithAccessToPartnersDdl = [this.mxpUserRoles.MXP_SUPER_ADMIN];
  hasAccessToPartnerDdl: boolean = false;
  isFirstCall : boolean = false;
  superAdminId : string ="";
  roles: string[] = [];
  hasError = false;
  fromDate = new FormControl(_moment());
  toDate = new FormControl(_moment());
  invoicedAmount : number | undefined = 0.0;
  invoicedPaidAmount: number | undefined = 0.0;
  constructor(
    private mxpBillingGrpcClient: MxpBillingGrpcClient,
    private mxpPartnersGrpcClient: MxpPartnerGrpcClient,
    public authService: AuthService,
    private mxpSnackBarService: MxpSnackBarService,
  ) {
  }

  ngOnInit(): void {
    this.initComponent();
    registerLocaleData(localeEs,'es');
  }

  private initComponent() {
    this.getCurrentMonthsDatesInitialized();
    this.isLoading = true;
    this.hasError = false;
    this.componentSubs.add(
      this.authService.user$
        .pipe(
          filter((user) => !!user),
          take(1),
          tap((user) => {
            if (user) {
              this.partnerId = user!['https://rezolve.com/mxp_partner_id'];
              return;
            }
          }),
          switchMap(() => {
            return this.authService.roles$;
          }),
          take(1),
          switchMap((roles) => {
            if (roles) {
              if (this.rolesWithAccessToPartnersDdl.some((role) => roles.includes(role))) {
                this.hasAccessToPartnerDdl = true;
                this.isFirstCall = true;
                return this.mxpPartnersGrpcClient.getAllPartners(new Empty()).pipe(
                  switchMap((partners: GetMxpPartnersReply) => {
                    if (partners && partners.partners && partners.partners.length > 0) {
                      this.partnersList = partners.partners!;
                      return of(null);
                    }
                    return throwError('No partners found');
                  }),
                );
              }
            }
            return of(null);
          }),
          switchMap(() => {
            return of(this.generateBilling(this.partnerId!)).pipe(
              switchMap((response) => {
                if (response === null) {
                  return of(null);
                }
                return response;
              })
            );
          })
        )
        .subscribe(
          (response: GenerateBillingResponse | null) => {
            if (response) {
              if (response.pricingRules) {
                this.pricingRules = [...response.pricingRules];
                this.dataSource = this.pricingRules;
              }
              this.paymentsMade = response.paymentMadePayments;
              this.price = response.price;
              this.totalPayments = response.totalPayments;
              this.invoicedAmount = response.invoicedAmount;
              this.invoicedPaidAmount = response.invoicedPaidAmount;
              this.mxpSnackBarService.showSnackbarMessage('BILLING_GENERATED');
            }
          },
          (error) => {
            console.log(error);
            this.isLoading = false;
            this.hasError = true;
            this.mxpSnackBarService.showSnackbarMessage('BILLING_GENERATED_FAILED');
          },
          () => {
            this.isLoading = false;
          }
        )
    );
  }

  getCurrentMonthsDatesInitialized() {
    this.dateFromObj = moment().startOf('month').toDate();
    this.dateToObj = moment().endOf('month').toDate();
    this.fromDate.setValue(this.dateFromObj);
    this.toDate.setValue(this.dateToObj);
  }

  handleMonthRangeSelected(data:any) {
    if (data !== undefined) {
      this.dateFromObj = data.startDate;
      this.dateToObj = data.endDate;
      this.handleGenerateBillingPromise();
    }
  }
  
  getPartnersList() {
    this.mxpPartnersGrpcClient.getAllPartners(new Empty()).subscribe((response: GetMxpPartnersReply) => {
      if (response) {
        this.partnersList = response.partners!;
      }
    });
  }

  handlePartnerChange(partnerId: string) {
    this.isLoading = true;
    this.isFirstCall = false;
    this.partnerId = partnerId;
    this.handleGenerateBillingPromise();
  }

  handleGenerateBillingPromise() {
    this.isLoading = true;
    let billingObservable = this.generateBilling(this.partnerId!);
    if (billingObservable) {
      billingObservable.subscribe(
        (response: GenerateBillingResponse | null) => {
          if (response) {
            if (response.pricingRules) {
              this.pricingRules = [...response.pricingRules];
              this.dataSource = this.pricingRules;
            }
            this.paymentsMade = response.paymentMadePayments;
            this.price = response.price;
            this.totalPayments = response.totalPayments;
            this.invoicedAmount = response.invoicedAmount;
            this.invoicedPaidAmount = response.invoicedPaidAmount;
            this.mxpSnackBarService.showSnackbarMessage('BILLING_GENERATED');  
          }
        },
        (error) => {
          console.log(error);
          this.isLoading = false
        },
        () => (this.isLoading = false)
      ); 
    }
  }

  generateBilling(partnerId: string)  : Observable<GenerateBillingResponse | null> {
    if (!this.isFirstCall) {
      const generateBillingRequest = new GenerateBillingRequest({
        partnerId: partnerId,
        dateFrom: Timestamp.fromDate(this.dateFromObj),
        dateTo: Timestamp.fromDate(this.dateToObj)
      });
      return this.mxpBillingGrpcClient.generateBilling(generateBillingRequest);
    }
    else
      return of(null);
  }

  ngOnDestroy() {
    this.componentSubs.unsubscribe();
  }
}
