<div class="main-container">
  <h2 mat-dialog-title>{{ 'ADD_NEW_USER' | translate }}</h2>

  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form" #userCreateForm="ngForm" fxLayout="column">
      <div class="codi-account-form-container">
        <rezolve-divider>{{ 'MEMBERSHIP_DETAILS' | translate | uppercase }}</rezolve-divider>
        <div formArrayName="roles" fxLayout="column">
          <div *ngFor="let role of roleList; let i = index">
            <rezolve-role [name]="role.name | translate" [description]="getRoleDescription(role.permissions)"
              [formControlName]="i"></rezolve-role>
          </div>
        </div>
        <rezolve-divider>{{ 'ACCOUNT_DETAILS' | translate | uppercase }}</rezolve-divider>
        <rezolve-basic-input required="true" [label]="'NAME' | translate" formControlName="name"
          [placeholder]="'ENTER_USER_NAME' | translate" [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }"></rezolve-basic-input>
        <rezolve-phone-input (errorEvent)="handlePhoneFieldError()" [required]="true" [label]="'PHONE_NUMBER' | translate" [filterCountryCodes]="['mx']"
          formControlName="phone" [phoneNumberErrorLabel]="'PLEASE_PROVIDE_VALID_PHONE_NUMBER' | translate"
          [requiredErrorLabel]="'REQUIRED' | translate"></rezolve-phone-input>
        <rezolve-email-input required="true" [label]="'EMAIL_ADDRESS' | translate" formControlName="email"
          [placeholder]="'ENTER_EMAIL_ADDRESS' | translate" [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }"></rezolve-email-input>
        <rezolve-drop-down *ngIf="!isOnlyReportUserRoleSelected" [disabled]="isOnlyReportUserRoleSelected"
          formControlName="storeId" [label]="'STORE' | translate" [placeholder]="'SELECT_STORE' | translate"
          [items]="stores" [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }">
        </rezolve-drop-down>
        <rezolve-drop-down required formControlName="language" [label]="'LANGUAGE' | translate"
          [placeholder]="'SELECT_LANGUAGE' | translate" [items]="languages" [optionTemplate]="languageOptionItems"
          [errorDescriptions]="{
          required: 'REQUIRED_ERROR_MESSAGE' | translate
        }">
          <ng-template #languageOptionItems let-item> {{ item.key | translate }}</ng-template>
        </rezolve-drop-down>
        <rezolve-toggle-control [label]="'ACTIVE' | translate" formControlName="active">
        </rezolve-toggle-control>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="end">
    <rezolve-secondary-button class="cancel-button" classList="medium" mat-dialog-close>
      {{ 'CANCEL' | translate }}</rezolve-secondary-button>
    <rezolve-primary-button classList="medium" [disabled]="!!userCreateForm.invalid  || phoneFieldHasErrors" (handleClick)="onSave()">
      {{ 'CREATE_NEW' | translate }}</rezolve-primary-button>
  </mat-dialog-actions>
  <div class="spinner-container" *ngIf="showSpinner"><mat-spinner></mat-spinner></div>
</div>