import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavbarToolbarContentService {
  mobileNavigationTitle$ = new BehaviorSubject<string | undefined>('');
  emailAddress$ = new BehaviorSubject<string | undefined>('');
  isLoading$ = new BehaviorSubject<boolean>(false);

  setMobileNavigationTitle(value: string) {
    this.mobileNavigationTitle$.next(value);
  }

  setEmailAddress(value: string) {
    this.emailAddress$.next(value);
  }

  setLoading(value: boolean) {
    this.isLoading$.next(value);
  }
}
