/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { BaseTableParams } from '../../../../../../../../libs/ui/src/lib/interfaces/base-table/base-table-params';
import { DataSourceService } from '../../../../../../../../libs/ui/src/lib/interfaces/base-table/data-source-service';
import {
  GetBySearchRequest,
  LanguageEnum,
  MxpUserGrpcClient,
  RoleEnum,
  SortDirectionEnum,
} from '@rezolved/mxp-proto';
import { AuthService } from '@rezolved/auth';
import { TranslateService } from '@ngx-translate/core';
import { UserData } from 'apps/mx-payment/src/app/core/interfaces/user-data';
import { User } from 'apps/mx-payment/src/app/core/interfaces/user';
import { QueryData } from 'libs/ui/src/lib/interfaces/base-table/query-data';
import { DatePipe } from '@angular/common';
import { DateFormatOptions } from 'apps/mx-payment/src/app/core/constants/DateFormatOptions';
import { MxpSnackBarService } from 'apps/mx-payment/src/app/core/services/mxp-snackbar.service';
import { ButtonActions } from 'apps/mx-payment/src/app/core/constants/ButtonActions';
import { SearchFilters } from 'libs/ui/src/lib/interfaces/base-table/search-filteres';
@Injectable({
  providedIn: 'root',
})
export class UserService implements DataSourceService {
  constructor(
    private mxpUserGrpcClient: MxpUserGrpcClient,
    private mxpSnackBarService: MxpSnackBarService,
    private authService: AuthService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
  ) {}

  loadData(props: BaseTableParams): Observable<UserData> {
    const sortDirection = SortDirectionEnum[props.sortDirection.toUpperCase() as keyof typeof SortDirectionEnum];

    const paginationData = {
      pageIndex: props.pageIndex,
      pageSize: props.pageSize,
      sortField: props.sortField ? props.sortField : '',
      sortDirection: props.sortField ? sortDirection : SortDirectionEnum.ASC,
    };

    let showSnackbar = true;
    if (this.isSearchFilter(props.searchFilter)) {
      if (!props.searchFilter.showSnackbar) {
        showSnackbar = false;
      }
    }
    return this.authService.user$.pipe(
      take(1),
      switchMap((authUser) => {
        return this.mxpSnackBarService
          .wrapApiCall(
            'SNACKBAR.GET_USERS',
            this.mxpUserGrpcClient.getAllUsers(
              new GetBySearchRequest({
                searchTerm: (props.searchFilter as QueryData<string,undefined>)?.searchTerm ?? '',
                paginationData: paginationData,
              })
            ),
            undefined,
            undefined,
            undefined,
            !showSnackbar
          )
          .pipe(
            map((response) => {
              return {
                value: response.users?.map((mxpUser) => {
                  let roles = ``;
                  if (mxpUser.roles) {
                    mxpUser.roles.forEach((role) => {
                      const translatedRole = this.translateItem(RoleEnum[role]);
                      roles = roles ? `${roles}, ${translatedRole}` : `${translatedRole}`;
                    });
                  }

                  return {
                    id: mxpUser.id,
                    role: roles,
                    created: this.datePipe.transform(mxpUser.createdOn?.toISOString(), DateFormatOptions.DEFAULT_DATE),
                    name: mxpUser.name,
                    language: this.translateItem(LanguageEnum[mxpUser.language || LanguageEnum.EN]),
                    store: mxpUser.storeName?.value,
                    email: mxpUser.email,
                    status: mxpUser.active ? this.translateItem('ACTIVE') : this.translateItem('INACTIVE'),
                    hiddenButtonActions: authUser?.email === mxpUser.email ? [ButtonActions.DELETE] : []
                  } as User;
                }),
                total: response.totalCount
              } as UserData;
            }),
          );
      }),
    );
  }

  private translateItem(translationKey: string): string {
    return this.translateService.instant(translationKey) ?? translationKey;
  }

  private isSearchFilter(obj : unknown) : obj is SearchFilters
  {
    return typeof obj === 'object' && obj !== null && 'date' in obj && 'showSnackbar' in obj && 'status' in obj && 'searchTerm' in obj;
  }
}