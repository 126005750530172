import { Component, Input, OnInit, ViewChild, OnDestroy, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Column } from '../../../interfaces/base-table/column';
import { BaseDataSource } from './base-data-source';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, merge } from 'rxjs';
import { BaseTableParams } from '../../../interfaces/base-table/base-table-params';
import { ListPaginationComponent } from '../list-pagination/list-pagination.component';
import { ColumnTypeEnum } from '../../../enums/column-type-enum';
import { TableAction } from '../../../interfaces/base-table/table-action';
import { NamedComponent } from '../../abstract/named-component';
import { QueryData } from '../../../interfaces/base-table/query-data';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'rezolve-base-table',
  templateUrl: './base-table.component.html',
  styleUrls: ['./base-table.component.scss'],
})

export class BaseTableComponent<T, TStatus, UType> extends NamedComponent implements OnInit, AfterViewInit, OnDestroy {
  dataSource: BaseDataSource<T>;
  tableColumns: Column[] = [];
  tableColumnsKeys: string[] = [];
  @ViewChild(ListPaginationComponent) paginator!: ListPaginationComponent;
  @ViewChild(MatSort) sort!: MatSort;
  @Input() defaultParams: BaseTableParams = {
    searchFilter: {},
    sortDirection: 'asc',
    pageIndex: 0,
    pageSize: 3,
    sortField: this.tableColumns.length ? this.tableColumns[0].sortField : '',
  };
  @Input() lastColumnActions: TableAction<T>[] = [];
  @Input() tableCaption = '';
  @Input() sortingEnabled = false;
  @Input() pageSizeOptions: number[] = [];
  @Input() query: BehaviorSubject<QueryData<TStatus,UType>> = new BehaviorSubject<QueryData<TStatus,UType>>({
    createdOnDate: {
      start: new Date(),
      end: new Date()
    },
    interactedOnDate: {
      start: new Date(),
      end: new Date()
    }
  });

  @Input() queryArray : BehaviorSubject<QueryData<TStatus[],UType>> = new BehaviorSubject<QueryData<TStatus[],UType>>({
      createdOnDate: {
        start: new Date(),
        end: new Date()
      },
      interactedOnDate: {
        start: new Date(),
        end: new Date()
      }
  });

  @Output() sortChange: EventEmitter<MatSort> = new EventEmitter<MatSort>();
  
  columnType = ColumnTypeEnum;

  constructor(private baseDataSource: BaseDataSource<T>) {
    super();
    this.dataSource = this.baseDataSource;
  }

  ngOnInit(): void {
    this.tableColumnsKeys = this.tableColumns.map((key) => key.key);
  }

  loadPage() {
    this.dataSource.loadData({
      searchFilter: this.queryArray.value.additionalData != undefined ? this.queryArray.value : this.query.value,
      sortDirection: this.sort?.direction || this.defaultParams.sortDirection,
      pageIndex: this.paginator?.currentPage || this.defaultParams.pageIndex,
      pageSize: this.paginator?.itemsPerPage || this.defaultParams.pageSize,
      sortField: this.sort?.active || this.defaultParams.sortField,
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.currentPage = 0;
      this.sortChange.emit(this.sort); // Emit sort changes to parent component
    });
    var _query = this.queryArray.value.additionalData != undefined ? this.queryArray : this.query;
    merge(this.sort.sortChange, _query, this.paginator.pageSizeChanged, this.paginator.pageChanged)
      .pipe(
        tap(() => {
          this.loadPage();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.sort.sortChange.unsubscribe();
    this.paginator.pageChanged.unsubscribe();
    this.paginator.pageSizeChanged.unsubscribe();
    this.query.unsubscribe();
  }
}
