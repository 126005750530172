import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Optional,
  Self,
  Input,
  ElementRef,
  ViewEncapsulation,
  SimpleChanges,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MatFormFieldControl } from '@angular/material/form-field';
import { BasicInputComponent } from '../basic-input/basic-input.component';

@Component({
  selector: 'rezolve-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SearchInputComponent }],
  encapsulation: ViewEncapsulation.None,
})
export class SearchInputComponent extends BasicInputComponent<string> implements OnInit, OnDestroy {
  private $destroy = new Subject();
  search = new FormControl();
  iconPrefix = 'search';
  @Input() large = true;
  @Input() shouldRefresh = false;
  @Output() textChange = new EventEmitter<string>();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    focusMonitor: FocusMonitor,
    elRef: ElementRef<HTMLElement>,
  ) {
    super(ngControl, focusMonitor, elRef);
    this.componentName = 'search';
    this.type = 'search';
  }

  ngOnInit(): void {
    this.search.valueChanges
      .pipe(
        tap((value: string) => {
          this.iconSuffix = value ? 'close' : '';
          this.textChange.emit(value);
        }),
        takeUntil(this.$destroy),
      )
      .subscribe();
  }

  clear(): void {
    this.search.setValue('');
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.shouldRefresh) {
      this.search.setValue('');
    }
  }
}