import { Component, Input, TemplateRef } from '@angular/core';
import { NamedComponent } from '../../abstract/named-component';

@Component({
  selector: 'rezolve-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
  exportAs: 'listView',
})
export class ListViewComponent<T> extends NamedComponent {
  @Input() items: T[] = [];
  @Input() showPagination = false;
  @Input() itemsPerPage = 5;
  @Input() currentPage = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() template: TemplateRef<any> | null = null;

  get start(): number {
    return this.showPagination ? this.currentPage * this.itemsPerPage : 0;
  }

  get end(): number {
    return this.showPagination ? this.currentPage * this.itemsPerPage + this.itemsPerPage : this.items.length;
  }
}
