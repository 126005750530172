<div class="container">
  <div class="userlist-header">
    <h2 class="user-subtitle">{{ 'USERS' | translate }}</h2>
    <rezolve-secondary-button prefixIcon="add" classList="large full-width" (click)="newUserClicked()"
      >{{ 'ADD_NEW_USER' | translate }}
    </rezolve-secondary-button>
  </div>
  <rezolve-search-input
    [placeholder]="'SEARCH' | translate"
    (textChange)="applySearchTerm($event)"></rezolve-search-input>
  <div class="user-list">
    <mxp-users-table
      [sortingEnabled]="true"
      [pageSizeOptions]="pageSizeOptions"
      [defaultParams]="tableConfiguration"
      (userActionSucceeded)="refreshUsersTable($event)"></mxp-users-table>
  </div>
</div>
