import { Component, Input } from '@angular/core';
import { GetByIdRequest, MxpUserGrpcClient } from '@rezolved/mxp-proto';
import { MxpSnackBarService } from '../../../core/services/mxp-snackbar.service';

@Component({
  selector: 'mxp-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
  @Input() accountDetailsId: string | undefined;

  constructor(
    private readonly mxpUserGrpcClient: MxpUserGrpcClient,
    private readonly mxpSnackBarService: MxpSnackBarService,
  ) {}

  onChangePassword() {
    this.mxpSnackBarService
      .wrapApiCall(
        'SNACKBAR.PASSWORD_RESET',
        this.mxpUserGrpcClient.changePasswordRequest(
          new GetByIdRequest({
            id: this.accountDetailsId,
          }),
        ),
      )
      .toPromise();
  }
}
