import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCcMastercard, faCcVisa, faCcAmex, faCcDinersClub, faCcDiscover } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';

export interface CreditCardBrandConfig {
  mask: string;
  icon: IconDefinition;
  iconColor?: IconColor;
}

export type CardType = 'Credit' | 'Debit';
export type CardBrand = 'Visa' | 'Master Card' | 'American Express' | 'Discover' | 'Diners' | 'Unknown';

export interface IconColor {
  stroke: string;
  color: string;
}
export const CreditCardBrandConfig: {
  [id in CardBrand]: CreditCardBrandConfig;
} = {
  'Master Card': { mask: 'XXXX XXXX XXXX 0000', icon: faCcMastercard },
  'Visa': {
    mask: 'XXXX XXXX XXXX 0000',
    icon: faCcVisa,
    iconColor: { stroke: 'rgb(26, 31, 113)', color: 'rgb(26, 31, 113)' },
  },
  'American Express': {
    mask: 'XXXX XXXXXX 00000',
    icon: faCcAmex,
    iconColor: { stroke: 'rgb(38,113,185)', color: 'rgb(38,113,185)' },
  },
  'Diners': { mask: 'XXXX XXXX XXXX 00', icon: faCcDinersClub },
  'Discover': { mask: 'XXXX XXXX XXXX 0000', icon: faCcDiscover },
  'Unknown': { mask: 'XXXX XXXX XXXX 0000', icon: faCreditCard },
};
export const DefaultMask = CreditCardBrandConfig['Unknown'];

export function getCreditCardBrandConfig(cardBrand: CardBrand): CreditCardBrandConfig {
  return CreditCardBrandConfig[cardBrand];
}

export function getCreditCardBrand(creditCardNumber: string): CardBrand {
  // first check for MasterCard
  if (/^5[1-5]/.test(creditCardNumber)) {
    return 'Master Card';
  }
  // then check for Visa
  else if (/^4/.test(creditCardNumber)) {
    return 'Visa';
  }
  // then check for AmEx
  else if (/^3[47]/.test(creditCardNumber)) {
    return 'American Express';
  }
  // then check for Diners
  else if (/3(?:0[0-5]|[68][0-9])[0-9]{11}/.test(creditCardNumber)) {
    return 'Diners';
  }
  // then check for Discover
  else if (/6(?:011|5[0-9]{2})[0-9]{12}/.test(creditCardNumber)) {
    return 'Discover';
  }

  return 'Unknown';
}

export const luhnCheck = (cardNumber: string): boolean => {
  if (!cardNumber.length) {
    return false;
  }

  // Remove all whitespaces from card number.
  cardNumber = cardNumber.replace(/\s/g, '');

  // 1. Remove last digit;
  const lastDigit = Number(cardNumber[cardNumber.length - 1]);

  // 2. Reverse card number
  const reverseCardNumber = cardNumber
    .slice(0, cardNumber.length - 1)
    .split('')
    .reverse()
    .map((x) => Number(x));

  let sum = 0;

  // 3. + 4. Multiply by 2 every digit on odd position.
  // Subtract 9 if digit > 9
  for (let i = 0; i <= reverseCardNumber.length - 1; i += 2) {
    reverseCardNumber[i] = reverseCardNumber[i] * 2;
    if (reverseCardNumber[i] > 9) {
      reverseCardNumber[i] = reverseCardNumber[i] - 9;
    }
  }

  // 5. Make the sum of obtained values from step 4.
  sum = reverseCardNumber.reduce((acc, currValue) => acc + currValue, 0);

  // 6. Calculate modulo 10 of the sum from step 5 and the last digit.
  // If it's 0, you have a valid card number :)
  return (sum + lastDigit) % 10 === 0;
};
