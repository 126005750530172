<div class="main-container">
  <h2 mat-dialog-title>{{ 'ADD_NEW_STORE' | translate }}</h2>

  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form" #storeCreateForm="ngForm" fxLayout="column">
      <div class="codi-account-form-container">
        <rezolve-divider>{{ 'STORE_DETAILS' | translate | uppercase }}</rezolve-divider>
        <rezolve-basic-input
          required="true"
          [label]="'STORE_NAME' | translate"
          formControlName="name"
          [placeholder]="'ENTER_STORE_NAME' | translate"
          [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }"></rezolve-basic-input>
        <rezolve-phone-input
          (errorEvent)="handlePhoneFieldError()"
          required="true"
          [label]="'PHONE' | translate"
          [filterCountryCodes]="['mx']"
          formControlName="phone"
          [phoneNumberErrorLabel]="'PLEASE_PROVIDE_VALID_PHONE_NUMBER' | translate"
          [requiredErrorLabel]="
            'REQUIRED_ERROR_MESSAGE' | translate
          "></rezolve-phone-input>
        <rezolve-basic-input
          required="true"
          [label]="'ADDRESS_1' | translate"
          formControlName="street1"
          [placeholder]="'ADDRESS_1_PLACEHOLDER' | translate"
          [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }"></rezolve-basic-input>
        <rezolve-basic-input
          [label]="'ADDRESS_2' | translate"
          formControlName="street2"
          [placeholder]="'ADDRESS_2_PLACEHOLDER' | translate"></rezolve-basic-input>
        <rezolve-basic-input
          required="true"
          [label]="'CITY' | translate"
          formControlName="city"
          [placeholder]="'ENTER_CITY' | translate"
          [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }"></rezolve-basic-input>
        <div fxLayout="row" class="state-content">
          <rezolve-basic-input
            required="true"
            [label]="'STATE_PROVINCE' | translate"
            formControlName="stateProvince"
            [placeholder]="'ENTER_STATE_PROVINCE' | translate"
            fxFlex="2 0 60%"
            [errorDescriptions]="{
              required: 'REQUIRED_ERROR_MESSAGE' | translate
            }"></rezolve-basic-input>
          <rezolve-numeric-input
            required="true"
            [label]="'POSTAL_CODE' | translate"
            formControlName="postalCode"
            [placeholder]="'ENTER_POSTAL_CODE' | translate"
            fxFlex="1 0 20%"
            [maxLength]="5"
            [validator]="pattern"
            [errorDescriptions]="{
              required: 'REQUIRED_ERROR_MESSAGE' | translate,
              pattern : 'PLEASE_PROVIDE_VALID_POSTAL_CODE' | translate
            }"></rezolve-numeric-input>
        </div>
        <rezolve-drop-down
          required
          formControlName="country"
          [label]="'COUNTRY' | translate"
          [placeholder]="'COUNTRY' | translate"
          [items]="countries"
          [optionTemplate]="countryTemplate"
          [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }">
          <ng-template #countryTemplate let-items> {{ items.key | translate }}</ng-template>
        </rezolve-drop-down>
        <rezolve-basic-input
          [label]="'CALLBACK_URL_LABEL' | translate"
          formControlName="callbackurl"
          [placeholder]="'CALLBACK_URL_PLACEHOLDER' | translate"></rezolve-basic-input>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="end">
    <rezolve-secondary-button class="cancel-button" classList="medium" mat-dialog-close>
      {{ 'CANCEL' | translate }}</rezolve-secondary-button
    >
    <rezolve-primary-button
      classList="medium"
      [disabled]="!!storeCreateForm.invalid || isLoading || phoneFieldHasErrors"
      (handleClick)="onSave()">
      {{ 'CREATE_NEW' | translate }}</rezolve-primary-button
    >
  </mat-dialog-actions>
  <div class="spinner-container" *ngIf="isLoading"><mat-spinner></mat-spinner></div>
</div>
