import { Inject, Injectable } from '@angular/core';
import { iif, Observable, of, throwError } from 'rxjs';
import { GrpcHandler, GrpcInterceptor } from '@ngx-grpc/core';
import { GrpcEvent, GrpcMessage, GrpcRequest } from '@ngx-grpc/common';
import { Auth0ClientConfig } from './auth0.config';
import { Auth0ClientService } from './auth0.client';
import { Auth0Client, GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import { Auth0State } from './auth0.state';
import { catchError, concatMap, pluck, switchMap, tap } from 'rxjs/operators';

const skippedByInterceptor = [
  '/identity.mxp_bulk_enrollment.MxpBulkEnrollmentGrpc/AddBulkMerchant',
  '/identity.mxp_portal_enrollment.MxpPortalEnrollmentGrpc/Enroll',
  '/identity.mxp_portal_enrollment.MxpPortalEnrollmentGrpc/GetBankCodes'
]

@Injectable()
export class AuthGrpcInterceptor implements GrpcInterceptor {
  constructor(
    private configFactory: Auth0ClientConfig,
    @Inject(Auth0ClientService) private auth0Client: Auth0Client,
    private authState: Auth0State,
  ) {}

  intercept<Q extends GrpcMessage, S extends GrpcMessage>(
    request: GrpcRequest<Q, S>,
    next: GrpcHandler,
  ): Observable<GrpcEvent<S>> {
    return of(true).pipe(
      concatMap((route) =>
        iif(
          () => route !== null,
          of(route).pipe(
            pluck('tokenOptions'),
            concatMap<GetTokenSilentlyOptions, Observable<string>>((options) => {
              return this.getAccessTokenSilently(options).pipe(
                catchError((err) => {
                  if(this.shouldSkipInterceptor(request.path))
                  {
                    return of('');
                  }
                  this.authState.setError(err);
                  return throwError(err);
                }),
              );
            }),
            switchMap((token: string) => {
              request.requestMetadata.set('Authorization', `Bearer ${token}`);
              return next.handle(request);
            }),
          ),
          next.handle(request),
        ),
      ),
    );
  }

  private getAccessTokenSilently(options?: GetTokenSilentlyOptions): Observable<string> {
    return of(this.auth0Client).pipe(
      concatMap((client) => client.getTokenSilently(options)),
      tap((token) => this.authState.setAccessToken(token)),
      catchError((error) => {
        this.authState.refresh();
        return throwError(error);
      }),
    );
  }

  private shouldSkipInterceptor(endpoint: string): boolean {
    let shouldSkip = false;
    skippedByInterceptor.forEach((item: string) => {
      if(endpoint.includes(item))
      {
         shouldSkip = true;
      }
    });
    return shouldSkip;
  }
}
