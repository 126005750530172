import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mxp-store-remove-confirm',
  templateUrl: './store-remove-confirm.component.html',
  styleUrls: ['./store-remove-confirm.component.scss'],
})
export class StoreRemoveConfirmComponent {
  constructor(public confirmDialog: MatDialogRef<StoreRemoveConfirmComponent>) {}
  actionConfirmed = () => {
    this.confirmDialog.close(true);
  };
}
