import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self, ViewEncapsulation } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomFormField } from '../../abstract/custom-form-field';

@Component({
  selector: 'rezolve-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: RoleComponent }],
  encapsulation: ViewEncapsulation.None,
})
export class RoleComponent extends CustomFormField<boolean> implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  @Input() name?: string;
  @Input() description?: string;
  @Input() hideCheckbox = false;

  role: FormControl = new FormControl(false);

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
  ) {
    super(ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.focusMonitor
      .monitor(this.elRef, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe((origin) => {
        this.focused = !!origin;
        this.stateChanges.next();
      });
    this.componentName = 'role';
  }

  ngOnInit(): void {
    this.id = `rezolve-${this.componentName}-${RoleComponent.nextId++}`;
    this.controlType = `rezolve-${this.componentName}`;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setState(value: boolean | null): void {
    this.role.setValue(value);
  }

  getState(): boolean | null {
    return this.role.value;
  }

  onValueChange() {
    this.markAsTouched();
    this.onChange(this.value);
  }

  disableControl(disabled: boolean): void {
    disabled ? this.role.disable() : this.role.enable();
  }
}
