<mat-form-field
  class="rezolve-form-field"
  [appearance]="appearance"
  [floatLabel]="floatLabel"
  [attr.data-cy]="generateDataCyId('-form-field')">
  <mat-label [attr.data-cy]="generateDataCyId('-form-field-label')">
    {{ label }}
  </mat-label>

  <mat-select
    #paymentStatusSelect
    [panelClass]="isMultiSelect ? 'bottom-panel-position-2' : 'bottom-panel-position'"
    disableOptionCentering
    [attr.data-cy]="generateDataCyId('-form-field-select')"
    [attr.disabled]="disabled"
    [placeholder]="placeholder"
    [value]="value"
    [required]="required"
    [formControl]="formControl"
    [multiple] = "isMultiSelect"
    [attr.allSelected]="allSelected"
    (selectionChange)="handleSelectionChange($event)">
    <mat-select-trigger *ngIf="selectTriggerTemplate">
      <ng-container *ngTemplateOutlet="selectTriggerTemplate"></ng-container>
    </mat-select-trigger>
    
    <div class="select-all" *ngIf="isMultiSelect">
      <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection($event)">
        All</mat-checkbox>
    </div>
    
    <mat-option *ngFor="let item of items" [value]="item.value">
      <ng-container
        *ngTemplateOutlet="optionTemplate ?? defaultOptionTemplate; context: { $implicit: item }"></ng-container>
    </mat-option>

    <mat-option (click)="handleActionItemClick($event)" [fxShow]="isActionItemVisible">
      <ng-container *ngTemplateOutlet="actionItemTemplate"></ng-container>
    </mat-option>
  </mat-select>

  <mat-error *ngIf="isInvalid" [attr.data-cy]="generateDataCyId('-form-field-error')">
    {{ errorMessage }}
  </mat-error>
</mat-form-field>
<ng-template #defaultOptionTemplate let-item>{{ item.key }}</ng-template>
