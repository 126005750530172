import { Component, ContentChild, Input } from '@angular/core';
import { TabBodyComponent } from '../tab-body/tab-body.component';
import { TabHeaderComponent } from '../tab-header/tab-header.component';

@Component({
  selector: 'rezolve-tab-item',
  templateUrl: './tab-item.component.html',
})
export class TabItemComponent {
  @Input()
  label: string | undefined;

  @Input()
  isSelected: boolean | undefined;

  @ContentChild(TabBodyComponent)
  bodyComponent: TabBodyComponent | undefined;

  @ContentChild(TabHeaderComponent)
  headerComponent: TabHeaderComponent | undefined;
}
