import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Subject } from 'rxjs';
import { NamedComponent } from '../../abstract/named-component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'rezolve-calendar-date-picker',
  templateUrl: './calendar-date-picker.component.html',
  styleUrls: ['./calendar-date-picker.component.scss'],
})
export class CalendarDatePickerComponent extends NamedComponent implements OnInit, OnDestroy {
  @Output()
  dateChosen: EventEmitter<Date> = new EventEmitter<Date>();
  @Input() labelText! : string;
  @Input() placeholder! : string;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly adapter: DateAdapter<Date>) {
    super();
    this.componentName = 'calendar-date-picker';
  }

  ngOnInit(): void {
    this.adapter.setLocale('en-GB');
  }

  dateValueSelected(event: MatDatepickerInputEvent<Date>) {
    const date = moment.isMoment(event.value) ? event.value.toDate() : new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(0);
    this.dateChosen.emit(date);
  };

  dateFilter = (d: Date | null): boolean => {
    const date = moment.isMoment(d) ? d.toDate() : new Date();
    let dayBefore = new Date();
    dayBefore.setDate(new Date().getDate() - 1);
    return date.getTime() > dayBefore.getTime();
  };

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}