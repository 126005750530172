import { createAction } from '@ngrx/store';

export const requestPayment = createAction('[mx] request payment');

export const qrPaymentSent = createAction('[mx] qr payment sent');

export const notificationPaymentSent = createAction('[mx] notification payment sent');

export const manageStores = createAction('[mx] manage stores');

export const displayUsers = createAction('[mx] display users');

export const displayReports = createAction('[mx] display reports');

export const displayMyProfile = createAction('[mx] display my profile');

export const setLoading = createAction('[mx] set loading');

export const setLoaded = createAction('[mx] set loaded');
