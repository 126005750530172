<div class="phone-input" fxLayout="row">
  <div fxFlex="25" fxLayoutAlign="center center">
    <mat-form-field [appearance]="appearance" class="rezolve-form-field">
      <mat-label>{{ label }}</mat-label>
      <mat-select
        id="dialCode"
        name="dialCode"
        [required]="required"
        [errorStateMatcher]="errorMatcher"
        [disabled]="disabled"
        [(value)]="selectedCountry"
        (selectionChange)="dialCodeChanged()">
        <mat-select-trigger>
          <span [ngClass]="selectedCountry.flagClass"></span>
          <span *ngIf="selectedCountry?.dialCode"> {{ selectedCountry.dialCode | dialCode }}</span>
        </mat-select-trigger>
        <mat-option *ngFor="let country of avaiableCountries" [value]="country">
          <span [ngClass]="country.flagClass"></span>
          <span *ngIf="country?.dialCode"> {{ country.dialCode | dialCode }}</span>
        </mat-option>
      </mat-select>
      <mat-error> test </mat-error>
    </mat-form-field>
  </div>
  <div fxFlex="75" class="phone-number-input">
    <mat-form-field [appearance]="appearance" class="rezolve-form-field">
      <input
        #model="ngModel"
        matInput
        autocomplete="off"
        [placeholder]="phonePlaceHolder"
        [(ngModel)]="phoneNumber"
        (ngModelChange)="onNgModelChange(model)"
        [disabled]="disabled"
        [maxlength]="maxLength"
        numericInput
        [required]="required"
        [errorStateMatcher]="errorMatcher" />
      <mat-error *ngIf="!this.errorMessage"> {{ requiredErrorLabel }} </mat-error>
      <mat-error *ngIf="this.errorMessage">
        {{ errorMessage }}
      </mat-error>
    </mat-form-field>
  </div>
</div>
