<ng-container class="main-container">
  <mat-dialog-content class="mat-typography">
    {{ 'ARE_YOU_SURE' | translate }}
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="end">
    <rezolve-secondary-button class="cancel-button" classList="medium" mat-dialog-close>
      {{ 'CANCEL' | translate }}</rezolve-secondary-button
    >
    <rezolve-primary-button classList="medium" (handleClick)="deleteMxpStore()">
      {{ 'YES' | translate }}</rezolve-primary-button
    >
  </mat-dialog-actions>
  <div class="spinner-container spinnerAbsolute"  *ngIf="isLoading"><mat-spinner></mat-spinner></div>
</ng-container>
