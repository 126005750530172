import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'rezolve-instant-buy-button',
  templateUrl: './instant-buy-button.component.html',
  styleUrls: ['./instant-buy-button.component.scss'],
})
export class InstantBuyButtonComponent {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      `icon-rezolve-logo`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/svg/rezolve-icon.svg'),
    );
  }

  @Output() clicked = new EventEmitter<MouseEvent>();
  @Input() type = '';
  @Input() color = '#11B0F0';

  onClickButton($event: MouseEvent) {
    this.clicked.emit($event);
  }
}
