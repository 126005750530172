import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import Route from './core/constants/Route';
import { AuthGuard } from '@rezolved/auth';
import { StoresComponent } from './components/stores-module/stores/stores.component';
import { MyDetailsComponent } from './components/my-details/my-details.component';
import { ReportsComponent } from './components/reports/reports.component';
import { UsersComponent } from './components/user/user-list/users.component';
import { PaymentsRequestComponent } from './components/payments-module/payments-request/payments-request.component';
import { PaymentCompleteComponent } from './components/payments-module/payment-complete/payment-complete.component';
import { WelcomebackComponent } from './components/welcomeback/welcomeback.component';
import { PaymentProcessingComponent } from './components/payments-module/payment-processing/payment-processing.component';
import { PaymentStatusUpdateComponent } from './components/payments-module/payment-status-update/payment-status-update.component';
import { MerchantEnrollmentComponent } from './components/merchant-enrollment/merchant-enrollment.component';
import { PaymentsAuthGuard } from './core/guards/payments-auth.guard';
import { StoresAuthGuard } from './core/guards/stores-auth.guard';
import { ReportsAuthGuard } from './core/guards/reports-auth.guard';
import { UsersAuthGuard } from './core/guards/users-auth.guard';
import { BulkPaymentComponent } from './components/payments-module/bulk-payment/bulk-payment.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { RefundsComponent } from './components/refunds/refunds.component';
import { PartnersComponent } from './components/partners/partners.component';
import { RefundsAuthGuard } from './core/guards/refunds-auth.guard';
import { PartnersAuthGuard } from './core/guards/partners-auth.guard';
import { BillingComponent } from './components/billing/billing.component';
import { BillingAuthGuard } from './core/guards/billing-auth.guard';

const routes: Routes = [
  {
    path: Route.REQUEST_PAYMENT,
    canActivate: [PaymentsAuthGuard],
    component: PaymentsRequestComponent,
  },
  {
    path: Route.STORE_LIST,
    canActivate: [StoresAuthGuard],
    component: StoresComponent,
  },
  {
    path: Route.MY_DETAILS,
    canActivate: [AuthGuard],
    component: MyDetailsComponent,
  },
  {
    path: Route.REPORTS,
    canActivate: [ReportsAuthGuard],
    component: ReportsComponent,
  },
  {
    path: Route.USER_LIST,
    canActivate: [UsersAuthGuard],
    component: UsersComponent,
  },
  {
    path: Route.PAYMENT_ROUTE,
    canActivate: [PaymentsAuthGuard],
    component: PaymentProcessingComponent,
  },
  {
    path: Route.PAYMENT_COMPLETE,
    canActivate: [PaymentsAuthGuard],
    component: PaymentCompleteComponent,
  },
  {
    path: Route.PAYMENT_STATUS,
    canActivate: [PaymentsAuthGuard],
    component: PaymentStatusUpdateComponent,
  },
  {
    path: Route.BULK_PAYMENTS,
    canActivate: [PaymentsAuthGuard],
    component: BulkPaymentComponent
  },
  {
    path: Route.MERCHANT_ENROLLMENT,
    component: MerchantEnrollmentComponent,
  },
  {
    path: Route.BILLING,
    component: BillingComponent,
    canActivate : [BillingAuthGuard]
  },
  {
    path: '',
    component: WelcomebackComponent,
    pathMatch: 'full',
  },
  {
    path: Route.DASHBOARD,
    canActivate: [AuthGuard],
    component: DashboardComponent
  },
  {
    path: Route.REFUNDS,
    canActivate: [RefundsAuthGuard],
    component: RefundsComponent
  },
  {
    path: Route.PARTNERS,
    canActivate: [PartnersAuthGuard],
    component: PartnersComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}