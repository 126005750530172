import { Directive, HostBinding, Input, OnChanges, OnDestroy, Optional, Self, SimpleChanges } from '@angular/core';
import { MatFormFieldAppearance, MatFormFieldControl } from '@angular/material/form-field';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { NamedComponent } from './named-component';

@Directive()
export abstract class CustomFormField<T>
  extends NamedComponent
  implements OnChanges, OnDestroy, ControlValueAccessor, MatFormFieldControl<T>
{
  static nextId = 0;
  static ngAcceptInputType_disabled: BooleanInput;
  static ngAcceptInputType_required: BooleanInput;
  @Input() appearance: MatFormFieldAppearance;
  @Input() label?: string;
  @Input('aria-describedby') userAriaDescribedBy?: string;
  @HostBinding() id: string;
  errorMessage: string | null = '';
  autofilled?: boolean;
  controlType?: string;
  focused: boolean;
  shouldLabelFloat: boolean;
  stateChanges: Subject<void>;

  private _type = '';
  private _disabled = false;
  private _placeholder = '';
  private _required = false;
  private _touched = false;
  private _errorState = false;
  private _value: T | null = null;

  protected constructor(@Optional() @Self() public ngControl: NgControl, id: string = '') {
    super();
    this.appearance = 'outline';
    this.focused = false;
    this.shouldLabelFloat = true;
    this.stateChanges = new Subject<void>();
    this.id = id;
  }

  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  get placeholder(): string {
    return this._placeholder;
  }

  @Input() set type(type: string) {
    this._type = type;
    this.stateChanges.next();
  }

  get type(): string {
    return this._type;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.disableControl(value);
    this.stateChanges.next();
  }

  @Input() set value(value: T | null) {
    this.setState(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  get value(): T | null {
    return this.getState();
  }

  get empty(): boolean {
    return this.value == null;
  }

  abstract ngOnDestroy(): void;

  get errorState(): boolean {
    return this._errorState;
  }

  set errorState(value: boolean) {
    this._errorState = coerceBooleanProperty(value);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  disableControl(disabled: boolean): void {
    // Not implemented
  }

  setState(value: T | null): void {
    this._value = value;
  }

  getState(): T | null {
    return this._value;
  }

  // eslint-disable-next-line
  ngOnChanges(changes: SimpleChanges): void {
    // Not implemented
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: any = () => {
    // Function assigned in registerOnChange
  };

  // eslint-disable-next-line
  onContainerClick(event: MouseEvent): void {
    // Implements MatFormField
  }

  onTouch = () => {
    // Function assigned in registerOnTouched
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  markAsTouched() {
    if (!this._touched) {
      this.onTouch();
      this._touched = true;
    }
  }

  isTouched(): boolean {
    return this._touched;
  }

  setDescribedByIds(ids: string[]): void {
    this.userAriaDescribedBy = ids.join(' ');
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: T | null): void {
    this.value = value;
  }
}
