import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { BasicDropDownInputComponent } from '../../atoms/basic-drop-down-input/basic-drop-down-input.component';
import { CreditCard } from './credit-card';

@Component({
  selector: 'rezolve-credit-card-list',
  templateUrl: './credit-card-list.component.html',
  styleUrls: ['./credit-card-list.component.scss'],
})
export class CreditCardListComponent extends BasicDropDownInputComponent<string> implements OnInit {
  @ViewChild(BasicDropDownInputComponent)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardList!: BasicDropDownInputComponent<any>;
  @Input() creditCards: CreditCard[] = [];
  @Input() selectedCard: CreditCard | undefined;
  @Input() manageCardLabel = 'Manage Cards';
  @Input() ccLabel = 'Credit Cards';
  @Output() selectedCardChanged: EventEmitter<CreditCard> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() manageCardClicked: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() removeCard: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() editCard: EventEmitter<any> = new EventEmitter();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    focusMonitor: FocusMonitor,
    elRef: ElementRef<HTMLElement>,
  ) {
    super(ngControl, focusMonitor, elRef);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.componentName = 'credit-card-list';
  }

  ngOnInit(): void {
    this.id = `${this.componentName}-${CreditCardListComponent.nextId++}`;
    if (this.value) {
      this.selectedCard = this.creditCards.find((f) => f.number == this.value);
    }
    if (!this.selectedCard) {
      this.selectedCard = this.creditCards.find((f) => f.isSelected) || this.creditCards[0];
    }
    this.value = this.selectedCard.number;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardChanged = (e: any) => {
    if (e && e.number) {
      this.selectedCard = this.creditCards.find((f) => f.number == e.number);
      this.selectedCardChanged.emit(this.selectedCard);
    }
  };

  manageItemsClicked = () => {
    this.manageCardClicked.emit();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeCardClicked = (e: any) => {
    this.removeCard.emit(e);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editCardClicked = (e: any) => {
    this.removeCard.emit(e);
  };
}
