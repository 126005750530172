import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardDisplay',
})
// eslint-disable-next-line @angular-eslint/use-pipe-transform-interface
export class CardDisplayPipe implements PipeTransform {
  private visibleLengthOfCardNumber = 4;
  transform(value: string | undefined): string {
    return `**** **** **** ${(value && value.substr(value.length - this.visibleLengthOfCardNumber)) || ''}`;
  }
}
