import { Inject, Injectable } from '@angular/core';
import {
  Auth0Client,
  GetTokenSilentlyOptions,
  LogoutOptions,
  RedirectLoginResult,
  RedirectLoginOptions,
} from '@auth0/auth0-spa-js';
import { defer, from, iif, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, concatMap, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { Auth0ClientService } from './auth0.client';
import { Auth0ClientConfig } from './auth0.config';
import { Auth0State } from './auth0.state';
import { AbstractNavigator } from './abstract-navigator';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private appStateSubject$ = new ReplaySubject<any>(1);

  private ngUnsubscribe$ = new Subject<void>();

  readonly isLoading$ = this.authState.isLoading$;

  readonly isAuthenticated$ = this.authState.isAuthenticated$;

  readonly user$ = this.authState.user$;

  readonly roles$ = this.authState.user$.pipe(map((user) => (user ? user['http://localhost:44346/roles'] : [])));

  readonly idTokenClaims$ = this.authState.idTokenClaims$;

  readonly error$ = this.authState.error$;

  readonly appState$ = this.appStateSubject$.asObservable();

  constructor(
    @Inject(Auth0ClientService) private auth0Client: Auth0Client,
    private configFactory: Auth0ClientConfig,
    private location: Location,
    private navigator: AbstractNavigator,
    private authState: Auth0State,
    private translateService: TranslateService,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const checkSessionOrCallback$ = (isCallback: boolean): Observable<any> =>
      iif(
        () => isCallback,
        this.handleRedirectCallback(),
        defer(() => this.auth0Client.checkSession()),
      );

    this.shouldHandleCallback()
      .pipe(
        switchMap((isCallback) =>
          checkSessionOrCallback$(isCallback).pipe(
            catchError((error) => {
              const { errorPath } = this.configFactory.get();
              this.authState.setError(error);
              this.navigator.navigateByUrl(errorPath || '/');
              return of(undefined);
            }),
          ),
        ),
        tap(() => {
          this.authState.setIsLoading(false);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  login(redirectPath?: string, queryParams?: any) {
    const { currentLang, defaultLang } = this.translateService;
    this.loginWithRedirect(redirectPath, queryParams, {
      language: currentLang || defaultLang,
      ui_locales: currentLang || defaultLang,
      translatedTitle: this.translateService.instant('AUTH0_LOGIN_SCREEN_TITLE'),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signup(redirectPath?: string, queryParams?: any) {
    const { currentLang, defaultLang } = this.translateService;
    this.loginWithRedirect(redirectPath, queryParams, {
      screen_hint: 'signup',
      language: currentLang || defaultLang,
      ui_locales: currentLang || defaultLang,
      translatedTitle: this.translateService.instant('AUTH0_LOGIN_SCREEN_TITLE'),
    });
  }

  loginWithRedirect(
    redirectPath: string = '/',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryParams?: any,
    options?: RedirectLoginOptions,
  ) {
    const { tenant, redirectUri } = this.configFactory.get();
    const { currentLang, defaultLang } = this.translateService;
    return from(
      this.auth0Client.loginWithRedirect({
        appState: { target: redirectPath, queryParams },
        rezolve_tenant: queryParams || tenant,
        redirect_uri: redirectUri,
        language: currentLang || defaultLang,
        ui_locales: currentLang || defaultLang,
        translatedTitle: this.translateService.instant('AUTH0_LOGIN_SCREEN_TITLE'),
        ...options,
      }),
    );
  }

  logout(options?: LogoutOptions): void {
    const logout = this.auth0Client.logout(options) || of(null);

    from(logout).subscribe(() => {
      if (options?.localOnly) {
        this.authState.refresh();
      }
    });
  }

  getAccessTokenSilently(options?: GetTokenSilentlyOptions): Observable<string> {
    return of(this.auth0Client).pipe(
      concatMap((client) => client.getTokenSilently(options)),
      tap((token) => this.authState.setAccessToken(token)),
      catchError((error) => {
        this.authState.setError(error);
        this.authState.refresh();
        return throwError(error);
      }),
    );
  }

  handleRedirectCallback(url?: string): Observable<RedirectLoginResult> {
    return defer(() => this.auth0Client.handleRedirectCallback(url)).pipe(
      withLatestFrom(this.authState.isLoading$),
      tap(([result, isLoading]) => {
        if (!isLoading) {
          this.authState.refresh();
        }
        const appState = result?.appState;
        const target = appState?.target ?? '/';

        if (appState) {
          this.appStateSubject$.next(appState);
        }

        this.navigator.navigateByUrl(target);
      }),
      map(([result]) => result),
    );
  }

  private shouldHandleCallback(): Observable<boolean> {
    return of(this.location.path()).pipe(
      map((path) => {
        return (path.includes('code=') || path.includes('error=')) && path.includes('state=');
      }),
    );
  }
}
