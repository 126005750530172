<mat-form-field [appearance]="appearance" class="card-number-container">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-icon class="margin-right-10" matPrefix>
    <fa-icon matPrefix [icon]="cardBrandConfig.icon"></fa-icon>
  </mat-icon>
  <input
    id="rezolve-card-number"
    #cardNumber="ngModel"
    #ccNumber
    matInput
    [mask]="cardBrandConfig.mask"
    [hiddenInput]="is12DigitsHidden"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    [required]="required"
    [disabled]="disabled"
    (keyup)="updateMask()"
    (ngModelChange)="onNgModelChange(cardNumber)" />
  <mat-error *ngIf="cardNumber.errors"></mat-error>
</mat-form-field>
