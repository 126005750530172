<button mat-button class="instant-buy-button-container" type="button" (click)="onClickButton($event)">
  <span class="button-content" fxLayout="row">
    <mat-icon svgIcon="icon-rezolve-logo" class="button-icon"></mat-icon>
    <span class="button-text">
      <span class="title">Instant Buy</span>
      <br />
      <span>One Click to Pay</span>
    </span>
  </span>
</button>
