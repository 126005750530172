import { Component, OnDestroy } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { AuthService } from '@rezolved/auth';
import { MatFormFieldAppearance } from '@angular/material/form-field';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { NamedComponent } from '../../../../../../libs/ui/src/lib/components/abstract/named-component';
import { Router } from '@angular/router';
import Route from '../../core/constants/Route';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';

@Component({
  selector: 'mxp-welcomeback',
  templateUrl: './welcomeback.component.html',
  styleUrls: ['./welcomeback.component.scss'],
})
export class WelcomebackComponent extends NamedComponent implements OnDestroy {
  private destroy$ = new Subject();
  color: ThemePalette = 'primary';
  type = 'submit';
  appearance: MatFormFieldAppearance = 'fill';

  constructor(public authService: AuthService, private router: Router) {
    super();

    combineLatest([authService.isAuthenticated$, authService.roles$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([isAuthenticated, roles]) => {
        if (!isAuthenticated) return;
        if (roles.includes('MXP_USER') || roles.includes('MXP_POWER_USER') || roles.includes('MXP_POWER_ADMIN') || roles.includes('MXP_STORE_ADMIN')) {
          this.router.navigate([Route.REQUEST_PAYMENT]);
        }  
        else if (roles.includes('MXP_SUPER_ADMIN')) {
          this.router.navigate([Route.DASHBOARD]);
        } else {
          this.router.navigate([Route.MY_DETAILS]);
        }
      });
  }

  loginWithRedirect(): void {
    this.authService.loginWithRedirect();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
