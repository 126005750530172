import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { AbstractControl, NgControl, NgModel, ValidationErrors, Validator } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import {
  CardBrand,
  CreditCardBrandConfig,
  DefaultMask,
  getCreditCardBrand,
  getCreditCardBrandConfig,
  luhnCheck,
} from './card-number-input.config';
import { CustomFormField } from '../../abstract/custom-form-field';

@Component({
  selector: 'rezolve-card-number-input',
  templateUrl: './card-number-input.component.html',
  styleUrls: ['./card-number-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: CardNumberInputComponent },
    // Commented temporarily to make it working in Wallet Storybook
    // {
    //   provide: NG_VALUE_ACCESSOR,
    //   useExisting: forwardRef(() => CardNumberInputComponent),
    //   multi: true,
    // },
    // {
    //   provide: NG_VALIDATORS,
    //   useExisting: forwardRef(() => CardNumberInputComponent),
    //   multi: true,
    // },
  ],
})
export class CardNumberInputComponent extends CustomFormField<number> implements Validator, OnDestroy {
  cardBrandConfig: CreditCardBrandConfig = DefaultMask;
  @Input() is12DigitsHidden = false;
  @ViewChild('ccNumber') ccNumberField?: ElementRef;
  @Output() cardBrandChange: EventEmitter<CardBrand> = new EventEmitter();

  @Input() set value(value: number | null) {
    this.cardBrandConfig = getCreditCardBrandConfig(getCreditCardBrand(value ? value.toString() : ''));
    super.value = value;
    this.onChange(value);
    this.handleValidationChange();
    this.stateChanges.next();
  }

  get value(): number | null {
    return super.value;
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
  ) {
    super(ngControl, `rezolve-card-number-${CardNumberInputComponent.nextId++}`);
    this.controlType = 'rezolve-card-number-control';
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    focusMonitor.monitor(elRef, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elRef);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const isIncorrectCard = !luhnCheck(control.value);
    return isIncorrectCard ? { invalidCard: isIncorrectCard } : null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.handleValidationChange = fn;
  }

  updateMask() {
    let trimmedCardNum = (this.value || '').toString();

    if (trimmedCardNum.length > 16) {
      trimmedCardNum = trimmedCardNum.substr(0, 16);
    }
    const cardBrand = getCreditCardBrand(trimmedCardNum);
    this.cardBrandChange.next(cardBrand);
    this.cardBrandConfig = getCreditCardBrandConfig(cardBrand);
  }

  onNgModelChange(model: NgModel) {
    this.errorState = !model.valid;
  }

  handleValidationChange = () => {
    // Not implemented
  };
}
