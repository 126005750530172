<div class="main-container">
  <h2 mat-dialog-title>{{ 'EDIT' | translate }} {{ store.name }}</h2>

  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form" #editStoreForm="ngForm" class="edit-address-container">
      <div>
        <rezolve-divider>{{ 'ACCOUNT_DETAILS' | translate | uppercase }}</rezolve-divider>

        <rezolve-drop-down
          [label]="'ACCOUNT' | translate"
          [items]="accountTypes"
          [value]="accountType"
          [disabled]="true"
          [optionTemplate]="translatedItem"
          [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }">
        </rezolve-drop-down>

        <rezolve-divider>{{ 'STORE_DETAILS' | translate | uppercase }}</rezolve-divider>
        <rezolve-basic-input
          required="true"
          formControlName="name"
          [label]="'STORE_NAME' | translate"
          [placeholder]="'ENTER_STORE_NAME' | translate"
          [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }"></rezolve-basic-input>

        <rezolve-phone-input
          (errorEvent)="handlePhoneFieldError()"
          formControlName="phone"
          [phoneNumber]="store.phone || ''"
          [filterCountryCodes]="['mx']"
          [label]="'PHONE' | translate"
          [phoneNumberErrorLabel]="'PLEASE_PROVIDE_VALID_PHONE_NUMBER' | translate"
          [requiredErrorLabel]="
            'REQUIRED_ERROR_MESSAGE' | translate
          "></rezolve-phone-input>
        <rezolve-basic-input
          required="true"
          formControlName="street1"
          [label]="'ADDRESS_1' | translate"
          [placeholder]="'LINE_OF_ADDRESS' | translate"
          [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }"></rezolve-basic-input>

        <rezolve-basic-input
          required="false"
          formControlName="street2"
          [label]="'ADDRESS_2' | translate"
          [placeholder]="'SECOND_LINE_OF_ADDRESS' | translate"></rezolve-basic-input>

        <rezolve-basic-input
          required="true"
          [label]="'CITY' | translate"
          [placeholder]="'CITY' | translate"
          formControlName="city"
          [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }"></rezolve-basic-input>
        <div class="address">
          <rezolve-basic-input
            class="state"
            required="true"
            formControlName="state"
            [label]="'STATE_PROVINCE' | translate"
            [placeholder]="'STATE_PROVINCE' | translate"
            [errorDescriptions]="{
              required: 'REQUIRED_ERROR_MESSAGE' | translate
            }"></rezolve-basic-input>

          <rezolve-numeric-input
            class="zip"
            [validator]="pattern"
            required="true"
            [label]="'POSTAL_CODE' | translate"
            formControlName="zip"
            [placeholder]="'POSTAL_CODE' | translate"
            [maxLength]="5"
            [errorDescriptions]="{
              required: 'REQUIRED_ERROR_MESSAGE' | translate,
              pattern : 'PLEASE_PROVIDE_VALID_POSTAL_CODE' | translate
            }"></rezolve-numeric-input>
        </div>
        <rezolve-drop-down
          required
          formControlName="country"
          [label]="'COUNTRY' | translate | titlecase"
          [placeholder]="'COUNTRY' | translate | titlecase"
          [items]="countries"
          [optionTemplate]="translatedItem"
          [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }">
        </rezolve-drop-down>
        <rezolve-basic-input
          [label]="'CALLBACK_URL_LABEL' | translate"
          formControlName="callbackurl"
          [placeholder]="'CALLBACK_URL_PLACEHOLDER' | translate"></rezolve-basic-input>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <rezolve-secondary-button class="cancel-button" classList="large" mat-dialog-close>
      {{ 'CANCEL' | translate }}</rezolve-secondary-button
    >
    <rezolve-primary-button
      classList="large"
      type="submit"
      (handleClick)="save()"
      [disabled]="!!form.invalid || isLoading || !form.touched || phoneFieldHasErrors">
      {{ 'SAVE_CHANGES' | translate }}</rezolve-primary-button
    >
  </mat-dialog-actions>
  <div class="spinner-container" *ngIf="isLoading"><mat-spinner></mat-spinner></div>
</div>
<ng-template #translatedItem let-item>{{ item.key | translate }}</ng-template>
