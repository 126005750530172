import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { CustomFormField } from '../../abstract/custom-form-field';

@Component({
  selector: 'rezolve-cvv-input',
  templateUrl: './cvv-input.component.html',
  styleUrls: ['./cvv-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CvvInputComponent }],
  encapsulation: ViewEncapsulation.None,
})
export class CvvInputComponent extends CustomFormField<number> implements OnChanges, OnDestroy {
  private _has4Digits = false;
  cvvPattern = new RegExp('^[0-9]{3}$');
  maxLength = 3;

  @Input() set has4Digits(value: boolean) {
    this._has4Digits = value;
    this.stateChanges.next();
  }

  get has4Digits(): boolean {
    return this._has4Digits;
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
  ) {
    super(ngControl, `rezolve-cvv-${CvvInputComponent.nextId++}`);
    this.stateChanges = new Subject<void>();
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    focusMonitor.monitor(elRef, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.has4Digits) {
      this.maxLength = changes.has4Digits.currentValue ? 4 : 3;
      this.cvvPattern = changes.has4Digits.currentValue ? new RegExp('^[0-9]{4}$') : new RegExp('^[0-9]{3}$');
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elRef);
  }

  onNgModelChange(model: NgModel) {
    this.errorState = !model.valid;
  }
}
