/* eslint-disable @angular-eslint/use-pipe-transform-interface */
import { Pipe, PipeTransform } from '@angular/core';

export type Region = 'MXN';
@Pipe({
  name: 'formatPhoneNumber',
})
export class FormatPhoneNumberPipe implements PipeTransform {
  transform(value: string | undefined, region: Region): string {
    if (!value || value?.length < 12) return '';

    switch (region) {
      case 'MXN':
        return `${value.substring(0, 3)} ${value.substring(3, 7)} ${value.substr(7)}`;

      default:
        break;
    }

    return value;
  }
}
