import { Component, Input } from '@angular/core';

@Component({
  selector: 'rezolve-data-view',
  templateUrl: 'data-view.component.html',
  styleUrls: ['data-view.component.scss'],
})
export class DataViewComponent {
  @Input() label?: string;
  @Input() value?: string;
}
