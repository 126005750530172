import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { BasicInputComponent } from '../basic-input/basic-input.component';
import { NumberPatternEnum } from '../../../enums/number-pattern.enum';
const NAVIGATION_KEYS = [
  'Backspace',
  'Delete',
  'Tab',
  'Escape',
  'Enter',
  'Home',
  'End',
  'ArrowLeft',
  'ArrowRight',
  'Clear',
  'Copy',
  'Paste',
];

const CONTROL_KEYS = ['a', 'c', 'v', 'x'];

@Component({
  selector: 'rezolve-numeric-input',
  templateUrl: '../basic-input/basic-input.component.html',
  styleUrls: ['../basic-input/basic-input.component.scss', '../../../styles/common.styles.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: NumericInputComponent }],
  encapsulation: ViewEncapsulation.None,
})
export class NumericInputComponent extends BasicInputComponent<string> implements OnInit, OnDestroy {
  @Input() validator = NumberPatternEnum.ALL;
  @Input() decimalCount = 2;
  pattern: string = NumberPatternEnum.ALL;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    focusMonitor: FocusMonitor,
    elRef: ElementRef<HTMLElement>,
  ) {
    super(ngControl, focusMonitor, elRef);
    this.type = 'number';
    this.componentName = 'numeric-input';
  }
  ngOnInit() {
    switch (this.validator) {
      case NumberPatternEnum.POSITIVE_ONLY:
        this.pattern = NumberPatternEnum.POSITIVE_ONLY;
        break;
      case NumberPatternEnum.DECIMAL:
        this.pattern = NumberPatternEnum.DECIMAL;
        break;
      case NumberPatternEnum.POSITIVE_DECIMAL:
        this.pattern = NumberPatternEnum.POSITIVE_DECIMAL;
        break;
      default:
        this.pattern = NumberPatternEnum.ALL;
    }
    super.ngOnInit();
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.isAllowed(event)) {
      return;
    }
    const decimalNumberLength = this.value?.includes('.') ? this.value.split('.')[1].length : 0;
    if (
      (!!this.maxLength && !!this.value && (this.value.length == this.maxLength)) ||
      (this.pattern && decimalNumberLength > this.decimalCount - 1) ||
      event.key === ' ' ||
      (!this.pattern && isNaN(Number(event.key)))
    ) {
      event.preventDefault();
    }
  }
  /**
   * Allowed Control Keyboard Events: Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X, Cmd+A (Mac), Cmd+C (Mac), Cmd+V (Mac), Cmd+X (Mac).
   * Allowed Navigation Keyboard Events: Backspace, Delete, Tab, Escape, Enter...
   */
  private isAllowed(event: KeyboardEvent): boolean {
    return (
      NAVIGATION_KEYS.indexOf(event.key) > -1 ||
      (CONTROL_KEYS.indexOf(event.key) > -1 && (event.ctrlKey || event.metaKey))
    );
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
