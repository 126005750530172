import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MxpPortalEnrollRequest, MxpPortalEnrollmentGrpcClient, LanguageEnum, MerchantStateEnum, MxpPortalEnrollResponse, BanxicoOperationTypeEnum, BanxicoCommunicationErrorTypeEnum, Auth0OperationTypeEnum, Auth0CommunicationErrorTypeEnum,  } from '@rezolved/mxp-proto';
import { BaseDropDownItem, BaseDropDownItemUtils } from '@rezolved/ui';
import { NumberPatternEnum } from 'libs/ui/src/lib/enums/number-pattern.enum';
import { MxpSnackBarService } from '../../core/services/mxp-snackbar.service';
import { Router } from '@angular/router';
import Route from '../../core/constants/Route';
import { MatDialog } from '@angular/material/dialog';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { Empty, StringValue, Value } from '@ngx-grpc/well-known-types';
import { AuthOErrorCodeEnum, BanxicoErrorCodeEnum } from '../../core/enums/enrollment-error-code.enum';

@Component({
  selector: 'app-merchant-enrollment',
  templateUrl: './merchant-enrollment.component.html',
  styleUrls: ['./merchant-enrollment.component.scss']
})
export class MerchantEnrollmentComponent {
  languages: BaseDropDownItem[] = [];
  merchantStates: BaseDropDownItem[] = [];
  bankCodes: BaseDropDownItem[] = [];
  form: FormGroup;
  langForm: FormGroup;
  isLoading = false;
  pattern = NumberPatternEnum.POSITIVE_DECIMAL;
  //termsAndConditionsAgreed = true; //TODO: change to false when terms and conditions are back
  isSigned = false;
  byteArraySignature: Uint8Array = new Uint8Array();
  phoneFieldHasErrors = false;
  isProduction:boolean = false;
  constructor(
    private mxpSnackBarService: MxpSnackBarService,
    private translate: TranslateService,
    private mxpBulkEnrollmentGrpcClient: MxpPortalEnrollmentGrpcClient,
    private router: Router,
    private tncDialog: MatDialog
  ) {
    this.isProduction = window.location.href.includes('https://mxp.mx.rezolve.com/');
    this.languages = BaseDropDownItemUtils.transformEnum(LanguageEnum);
    this.merchantStates = BaseDropDownItemUtils.transformEnumAndReplaceUnderscores(MerchantStateEnum).filter(x => x.value !== MerchantStateEnum.NOSTATESELECTED);
    this.langForm = new FormGroup({
      lang: new FormControl(this.languages.find((x) => x.key === this.translate.getDefaultLang().toUpperCase())?.value)
    });
    this.form = new FormGroup(
      {
        merchantBankCode: new FormControl('', Validators.required),
        merchantBankAccount: new FormControl('',  Validators.compose([Validators.required, Validators.maxLength(20)])),
        merchantName: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(100), Validators.pattern(this.isProduction ? "^[a-zA-Z0-9, ]*$" : "^[a-zA-Z0-9, #]*$")])),
        merchantRfc: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(20)])),
        merchantStreet: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(40)])),
        merchantNumber: new FormControl(
          null,
          Validators.compose([Validators.required, Validators.pattern('\\d+'), Validators.maxLength(8)])
        ),
        merchantZip: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(5)])),
        merchantCity: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(30)])),
        merchantState: new FormControl('', Validators.required),
        userFullName: new FormControl('', Validators.required),
        userEmail: new FormControl('', Validators.compose([Validators.required, Validators.email])),
        userPhone: new FormControl('', Validators.compose([Validators.required, Validators.pattern('\\+?\\d+')])),
        userLanguage: new FormControl('', Validators.required)
        //termsAndConditions: new FormControl('', Validators.required)
      },
      {
        validators: this.toggleValidator(Validators.required, ['userEmail', 'userPhone'])
        // validators: this.toggleValidator(Validators.required, ['userEmail', 'userPhone', 'termsAndConditions'])
      }
    );

    this.langForm.get('lang')?.valueChanges.subscribe((value) => {
      if (value === LanguageEnum.EN) {
        this.translate.setDefaultLang('en');
      } else {
        this.translate.setDefaultLang('es');
      }
    });

    this.form.get('userPhone')?.valueChanges.subscribe((x) => {
      this.phoneFieldHasErrors = false;
    });

    this.mxpBulkEnrollmentGrpcClient.getBankCodes(new Empty()).subscribe(response => {
      if(response.bankCodes) {
        this.bankCodes = response.bankCodes.map((item) => {
          return { key: item.bankName?.value, value: item.bankNumber } as BaseDropDownItem;
        }).sort((a,b) => a.key.localeCompare(b.key));
      }
    });
  }

  onSubmit() {
    this.isLoading = true;
    const {
      merchantName,
      merchantRfc,
      merchantStreet,
      merchantNumber,
      merchantZip,
      merchantCity,
      merchantState,
      merchantBankCode,
      merchantBankAccount,
      userEmail,
      userLanguage,
      userPhone,
      //termsAndConditions,
      userFullName
      } = this.form.value;

    let userPhoneCountryCode = +(userPhone as string).slice(1, 3);
    let userPhoneWithoutPlus = (userPhone as string).substring(1);

    let merchantNameObj = new StringValue();
    merchantNameObj.value = merchantName;
    let merchantRfcObj = new StringValue(); 
    merchantRfcObj.value = merchantRfc;
    let merchantStreetObj = new StringValue(); 
    merchantStreetObj.value = merchantStreet;
    let merchantZipObj = new StringValue();
    merchantZipObj.value = merchantZip;
    let merchantCityObj = new StringValue();
    merchantCityObj.value = merchantCity;
    let merchantBankCodeObj = new StringValue();
    merchantBankCodeObj.value = merchantBankCode + '';
    let merchantBankAccountObj = new StringValue();
    merchantBankAccountObj.value = merchantBankAccount;
    let userEmailObj = new StringValue();
    userEmailObj.value = userEmail;
    let userFullNameObj = new StringValue();
    userFullNameObj.value = userFullName;

    const request = new MxpPortalEnrollRequest({
      merchantName: merchantNameObj,
      merchantRfc: merchantRfcObj, 
      merchantStreet: merchantStreetObj, 
      merchantNumber: merchantNumber,
      merchantZip: merchantZipObj,
      merchantCity: merchantCityObj,
      merchantState: merchantState,
      merchantBankCode: merchantBankCodeObj,
      merchantBankAccount: merchantBankAccountObj,
      userEmail:userEmailObj,
      userLanguage: userLanguage,
      userPhoneCountryCode: userPhoneCountryCode,
      userPhone: userPhoneWithoutPlus,
      userFullName: userFullNameObj,
      userSignature: this.byteArraySignature
    });

    this.mxpBulkEnrollmentGrpcClient.enroll(request).subscribe(
      (response: MxpPortalEnrollResponse) => {
        this.isLoading = false;
        if (response && response.success) {
          this.mxpSnackBarService.showSnackbarMessage('SNACKBAR.ENROLL_MERCHANT');
          this.router.navigate([Route.MAIN_PAGE]);
        } else if (
          response?.enrollmentError?.banxicoError?.operationType == BanxicoOperationTypeEnum.ACCOUNT_VALIDATION &&
          response?.enrollmentError?.banxicoError?.errorType == BanxicoCommunicationErrorTypeEnum.OPERATION &&
          response?.enrollmentError?.banxicoError?.errorCode == BanxicoErrorCodeEnum.ACCOUNT_VALIDATION
        ) {
          this.mxpSnackBarService.showSnackbarMessage('SNACKBAR.ENROLL_MERCHANT_ERROR_ACCOUNT_VALIDATION');
        } else if (
          response?.enrollmentError?.banxicoError?.operationType == BanxicoOperationTypeEnum.ENROLLMENT &&
          response?.enrollmentError?.banxicoError?.errorType == BanxicoCommunicationErrorTypeEnum.OPERATION &&
          response?.enrollmentError?.banxicoError?.errorCode == BanxicoErrorCodeEnum.ALREADY_REGISTERED
        ) {
          this.mxpSnackBarService.showSnackbarMessage('SNACKBAR.ENROLL_MERCHANT_ERROR_ACCOUNT_ALREADY_REGISTERED');
        } else if (
          response?.enrollmentError?.banxicoError?.operationType == BanxicoOperationTypeEnum.ENROLLMENT &&
          response?.enrollmentError?.banxicoError?.errorType == BanxicoCommunicationErrorTypeEnum.OPERATION &&
          response?.enrollmentError?.banxicoError?.errorCode == BanxicoErrorCodeEnum.WRONG_RFC
        ) {
          this.mxpSnackBarService.showSnackbarMessage('SNACKBAR.ENROLL_MERCHANT_ERROR_WRONG_RFC');
        } else if (
          response?.enrollmentError?.authOError?.operationType == Auth0OperationTypeEnum.AUTH0_REGISTRATION &&
          response?.enrollmentError?.authOError?.errorType == Auth0CommunicationErrorTypeEnum.AUTH0_OPERATION &&
          response?.enrollmentError?.authOError?.errorCode == AuthOErrorCodeEnum.AUTH0_IDP_ERROR
        ) {
          this.mxpSnackBarService.showSnackbarMessage('SNACKBAR.ENROLL_MERCHANT_ERROR_EMAIL_ALREADY_REGISTERED');
        } else {
          this.mxpSnackBarService.showSnackbarMessage('SNACKBAR.ENROLL_MERCHANT_FAIL');
        }
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
        this.mxpSnackBarService.showSnackbarMessage('SNACKBAR.ENROLL_MERCHANT_FAIL');
      },
    );
  }

  handlePhoneFieldError() {
    this.phoneFieldHasErrors = true;
  }

  toggleValidator(validator: ValidatorFn, controls: string[] = []): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control) return null;
      const formGroup = control as FormGroup;
      return formGroup && controls.some((form) => !validator(formGroup.controls[form]))
        ? null
        : {
            toggleValidator: true,
          };
    };
  }
  handleIsEmptySignature(isSignatureEmpty: boolean) {
    this.isSigned = !isSignatureEmpty;
  }

  handleOnSaveSignature(signatureByteArray: Uint8Array) {
    if (signatureByteArray != undefined) {
      this.byteArraySignature = signatureByteArray;
      this.isSigned = true;
      return;
    }
    this.isSigned = false;
  }

  // handleAgreement() {
  //   this.termsAndConditionsAgreed = !this.termsAndConditionsAgreed;
  // }

  openTandCPopUp() {
    this.tncDialog.open(TermsConditionsComponent, { data: { message: 'T_AND_C_WRITEUP' } });
  }
}