<div
  fxLayout="row"
  fxLayout.xs="column-reverse"
  fxLayoutAlign="space-between"
  fxLayoutAlign.xs="center center"
  class="pagination-container"
  [attr.data-cy]="generateDataCyId('-pagination-container')"
  [ngClass]="small ? 'small' : 'large'">
  <div fxLayout="row" class="section summary" [attr.data-cy]="generateDataCyId('-pagination-summary')">
    {{ getSummary('OF' | translate, 'ITEMS' | translate) }}
  </div>

  <div fxLayout="row" fxLayoutGap="20px">
    <div fxLayout="row" class="paginator section" [attr.data-cy]="generateDataCyId('-paginator')">
      <button
        mat-icon-button
        type="button"
        [title]="'PREVIOUS' | translate"
        (click)="navigateToPage(currentPage - 1, $event)"
        [disabled]="currentPage + 1 === pageLinks[0]"
        class="paginator-button">
        <mat-icon>chevron_left</mat-icon>
      </button>

      <div fxLayout="row" class="section" *ngIf="itemsPerPage < itemsLength">
        <ng-template [ngIf]="shouldShowPaginatorDots('left')">
          <button
            mat-icon-button
            type="button"
            (click)="navigateToPage(pageLinks[0] - 1, $event)"
            class="paginator-button">
            <span>{{ pageLinks[0] }}</span>
          </button>

          <mat-icon class="disabled">more_horiz</mat-icon>
        </ng-template>

        <button
          *ngFor="let pl of visiblePageLinks"
          mat-icon-button
          type="button"
          [class.current]="pl === currentPage + 1"
          (click)="navigateToPage(pl - 1, $event)"
          class="paginator-button">
          <span>{{ pl }}</span>
        </button>

        <ng-template [ngIf]="shouldShowPaginatorDots('right')">
          <mat-icon class="disabled">more_horiz</mat-icon>

          <button
            type="button"
            mat-icon-button
            class="paginator-button"
            (click)="navigateToPage(pageLinks[pageLinks.length - 1] - 1, $event)">
            <span>{{ pageLinks[pageLinks.length - 1] }}</span>
          </button>
        </ng-template>
      </div>

      <button
        mat-icon-button
        type="button"
        [title]="'NEXT' | translate"
        (click)="navigateToPage(currentPage + 1, $event)"
        [disabled]="currentPage + 1 === pageLinks[pageLinks.length - 1]"
        class="paginator-button">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>

    <div
      fxLayout="row"
      class="section"
      *ngIf="pageSizeOptions && pageSizeOptions.length > 0 && pageSizeOptions[0] < itemsLength"
      fxHide.xs>
      <mat-form-field>
        <mat-select (selectionChange)="itemsPerPageValueChange($event)" [value]="itemsPerPage">
          <mat-option *ngFor="let option of pageSizeOptions" [value]="option">
            {{ getOption(option, 'PER_PAGE' | translate) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
