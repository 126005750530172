<a
  mat-flat-button
  [ngClass]="buttonClass"
  [disabled]="disabled"
  [href]="href"
  [target]="target"
  [attr.data-cy]="generateDataCyId('-anchor-button')"
  ><span class="button-content">
    <mat-icon
      inline
      *ngIf="!!prefixIcon"
      class="button-icon"
      [attr.data-cy]="generateDataCyId('-anchor-button-prefix')"
      >{{ prefixIcon }}</mat-icon
    >
    <span class="text"><ng-content></ng-content></span>
    <mat-icon *ngIf="!!suffixIcon" class="button-icon" [attr.data-cy]="generateDataCyId('-anchor-button-suffix')">{{
      suffixIcon
    }}</mat-icon>
  </span></a
>
