<div class="list-container" [attr.data-cy]="generateDataCyId('-container')">
  <div class="list-view" [attr.data-cy]="generateDataCyId('-view')">
    <rezolve-list-view
      [template]="template"
      [itemsPerPage]="itemsPerPage"
      [currentPage]="currentPage"
      [items]="items"
      [showPagination]="showPagination"
      [componentName]="componentName">
    </rezolve-list-view>
  </div>
  <div class="pagination" *ngIf="showPagination" [attr.data-cy]="generateDataCyId('-pagination')">
    <rezolve-list-pagination
      [itemsLength]="items.length"
      [currentPage]="currentPage"
      [itemsPerPage]="itemsPerPage"
      [pageSizeOptions]="pageSizeOptions"
      [componentName]="componentName"
      (pageChanged)="handlePageChanged($event)"
      (pageSizeChanged)="handlePageSizeChanged($event)">
    </rezolve-list-pagination>
  </div>
</div>
