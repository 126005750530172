import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoresComponent } from './stores/stores.component';
import { StoreComponent } from './store/store.component';
import { StoreFormComponent } from './store-form/store-form.component';
import { StoreRemoveConfirmComponent } from './store-remove-confirm/store-remove-confirm.component';
import { UiModule } from '@rezolved/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexModule } from '@angular/flex-layout';
import { DeleteStoreComponent } from './delete-store/delete-store.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

@NgModule({
  declarations: [StoresComponent, StoreComponent, StoreFormComponent, StoreRemoveConfirmComponent, DeleteStoreComponent],
  exports: [StoresComponent, StoreComponent, StoreFormComponent, StoreRemoveConfirmComponent],
  imports: [CommonModule, UiModule, TranslateModule.forChild(), ReactiveFormsModule, MatDialogModule, FlexModule,MatProgressSpinnerModule],
})
export class StoresModule {}
