import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Self } from '@angular/core';
import { CustomFormField } from '../../abstract/custom-form-field';
import { NgControl } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { RezolveFile } from '../../../interfaces/upload/rezolve-file';
import { DropzoneText } from '../../../interfaces/upload/dropzone-text';
import { getFileType } from '../../../enums/file-mimetypes.enum';
import { FileUploadConfiguration } from '../../../interfaces/upload/file-upload-configuration';
import { MatFormFieldControl } from '@angular/material/form-field';

function removePrefixFromBase64(base64: string) {
  // removes prefix from file stream, e.g data:image/png;base64,iVBORw0KGgoAAAAN
  return base64.split(',')[1];
}
function getFileValidationErrors(file: File, configuration: FileUploadConfiguration): string[] {
  const errors = [];
  const acceptedMIMETypes = configuration.acceptedFileTypes.map((type) => getFileType(type).MIMEType);

  if (file.size > configuration.maxFileSize) {
    errors.push(`MAXIMUM_UPLOAD_SIZE_EXCEEDED`);
  }

  if (acceptedMIMETypes.length > 0 && !acceptedMIMETypes.includes(file.type)) {
    errors.push(`TYPE_IS_NOT_ALLOWED`);
  }

  return errors;
}

@Component({
  selector: 'rezolve-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: UploadComponent }],
})
export class UploadComponent extends CustomFormField<string> implements OnInit, OnDestroy {
  rezolveFile: RezolveFile | null = null;
  @Input() dropzoneText: DropzoneText[] = [
    { label: 'Drop items here or', isClickable: false },
    { label: 'Browse', isClickable: true },
    { label: 'for files', isClickable: false },
  ];
  acceptedFileExtensions = '';
  @Input() configuration: FileUploadConfiguration = {
    acceptedFileTypes: [],
    maxFileSize: 1000000,
  };
  @Output() handleUploadedFile: EventEmitter<RezolveFile> = new EventEmitter();
  @Output() handleUploadErrors: EventEmitter<string[]> = new EventEmitter();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    protected focusMonitor: FocusMonitor,
    protected elRef: ElementRef<HTMLElement>,
  ) {
    super(ngControl, `rezolve-upload-${UploadComponent.nextId++}`);
    this.controlType = `rezolve-upload`;

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this.focusMonitor.monitor(this.elRef, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

    this.componentName = 'basic';
  }

  ngOnInit(): void {
    this.configuration.acceptedFileTypes.forEach((acceptedTypes) => {
      const type = getFileType(acceptedTypes);

      this.acceptedFileExtensions = `${this.acceptedFileExtensions},${type.extension}`;
    });
  }

  async handleUpload(files: FileList | null) {
    if (files && files.length > 0) {
      const file = files[0];
      const errors = getFileValidationErrors(file, this.configuration);

      if (!errors.length) {
        this.rezolveFile = {
          file,
          fileBase64: (await this.toBase64(file)) as string,
        };

        this.value = removePrefixFromBase64(this.rezolveFile.fileBase64);
        this.handleUploadedFile.emit(this.rezolveFile);
        this.errorState = false;
        this.handleUploadErrors.emit([]);

        return;
      }

      this.errorState = true;
      this.errorMessage = errors[0];
      this.handleUploadErrors.emit(errors);
    }
  }

  onRemove() {
    this.rezolveFile = null;
    this.value = null;
  }

  toBase64 = (uploadedFile: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elRef);
  }
}
