import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[expiryDateInput]',
})
export class ExpiryDateInputDirective {
  private readonly _dateSeparator: string = '/';

  constructor(private _el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: InputEvent) {
    if (event.data == null) {
      return;
    }

    const inputValue: string = this._el.nativeElement.value;
    const numbers: string = inputValue.replace(/[^0-9]*/g, '');
    if (!numbers) {
      this._el.nativeElement.value = null;
      return;
    }

    this._el.nativeElement.value = this.getCardExpiryDateFormat(numbers);
    if (inputValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

  /**
   * Helper function used for generating card expiry date in format MM/YY
   * @param numbers
   * @returns date string format MM/YY
   */
  private getCardExpiryDateFormat(numbers: string): string | null {
    let monthPart: string;
    let yearPart: string;
    switch (numbers.length) {
      case 1: {
        const firstDigit = +numbers.slice(-1);
        return firstDigit > 1 ? `0${firstDigit}${this._dateSeparator}` : firstDigit.toString();
      }
      case 2: {
        if (Number(numbers) === 0) {
          return numbers[0];
        }

        const secondDigit = +numbers.slice(-1);
        return secondDigit > 2 ? numbers.slice(0, 1) : `${numbers}${this._dateSeparator}`;
      }
      case 3: {
        const thirdDigit = +numbers.slice(-1);
        monthPart = numbers.slice(0, 2);
        return `${monthPart}${this._dateSeparator}${thirdDigit}`;
      }
      case 4:
        monthPart = numbers.slice(0, 2);
        yearPart = numbers.slice(-2);
        return `${monthPart}${this._dateSeparator}${yearPart}`;
      default:
        break;
    }

    return null;
  }
}
