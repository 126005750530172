import { Component, Inject, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  GetByIdRequest,
  GetMxpStoresReply,
  LanguageEnum,
  MxpStoreDto,
  MxpStoreGrpcClient,
  MxpUserGrpcClient,
  RoleEnum,
  UpdateMxpUserRequest,
} from '@rezolved/mxp-proto';
import { Auth0MxpUserRoles, UserRoles } from '../../../core/constants/UserGroups';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StoreCreateComponent } from '../../stores-module/store-create/store-create.component';
import { of, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { DateFormatOptions } from '../../../core/constants/DateFormatOptions';
import { BaseDropDownItem, BaseDropDownItemUtils } from '@rezolved/ui';
import { PermissionTypeEnum } from '../../../core/enums/permission-type.enum';
import { MxpTranslationService } from '../../../core/services/mxp-translation.service';
import { RolesValidator } from '../../../core/validators/roles.validator';
import { MxpSnackBarService } from '../../../core/services/mxp-snackbar.service';
import { AuthService } from '@rezolved/auth';
import { Empty } from '@ngx-grpc/well-known-types';

@Component({
  selector: 'mxp-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnDestroy {
  private _mxpStoreDtos: MxpStoreDto[] = [];
  protected destroy$: Subject<boolean> = new Subject<boolean>();
  form: FormGroup;
  storeOptions: string[] = [];
  languages: BaseDropDownItem[] = [];
  stores: BaseDropDownItem[] = [];
  allRoleList = UserRoles;
  roleList: any[] = [];
  roleBelowStoreAdminList = UserRoles.filter((role) => role.id == RoleEnum.USER || role.id == RoleEnum.POWER_USERS);
  userId: string | undefined;
  createdOn: string | undefined;
  updatedOn: string | undefined;
  dateFormat = DateFormatOptions.DEFAULT_DATE_TIME;
  isOnlyReportUserRoleSelected = false;
  showSpinner = false; 
  constructor(
    private dialogRef: MatDialogRef<StoreCreateComponent>,
    private mxpTranslationService: MxpTranslationService,
    private mxpStoreGrpcClient: MxpStoreGrpcClient,
    private mxpUserGrpcClient: MxpUserGrpcClient,
    private mxpSnackBarService: MxpSnackBarService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) id: string,
  ) {
    if (id) {
      this.showSpinner = true;
      this.authService.user$.pipe(first()).subscribe((user) => {
        this.mxpUserGrpcClient.getUserById(new GetByIdRequest({ id }))
          .pipe(
            takeUntil(this.destroy$),
            switchMap((mxpUserReply) => {
              if (
                mxpUserReply.storeId &&
                user?.['http://localhost:44346/roles']?.includes(Auth0MxpUserRoles.MXP_STORE_ADMIN)
              ) {
                this.roleList = this.roleBelowStoreAdminList.filter((role) => role.id != RoleEnum.PARTNER);
              } else {
                this.roleList = this.allRoleList.filter((role) => role.id != RoleEnum.PARTNER);
              }
              this.buildRolesForm();
              return this.mxpSnackBarService
                .wrapApiCall(
                  'SNACKBAR.GET_STORES',
                  this.mxpStoreGrpcClient.getAllStores(new Empty()
                  ),
                  undefined,
                  undefined,
                  undefined,
                  true
                )
                .pipe(
                  takeUntil(this.destroy$),
                  map((storesReply) => {
                    return { mxpUser: mxpUserReply, storesReply };
                  }),
                );
            }),
          )
          .subscribe(({ mxpUser, storesReply }) => {
            if (storesReply?.stores && storesReply.stores.length > 0) {
              this.loadStoresWithPsp(storesReply.stores);
            }
            this.userId = mxpUser.id;
            this.createdOn = mxpUser.createdOn?.toISOString();
            this.updatedOn = mxpUser.updatedOn?.toISOString();
            this.form.patchValue({
              name: mxpUser.name,
              email: mxpUser.email,
              phone: mxpUser.phone,
              storeName: mxpUser.storeName?.value,
              active: mxpUser.active,
              language: mxpUser.language,
              roles: this.roleList.map((role) => mxpUser.roles?.includes(role.id))
            });
            this.showSpinner = false;
            this.form.markAsPristine();
          });
      });
    }
    this.languages = BaseDropDownItemUtils.transformEnum(LanguageEnum);
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      phone: new FormControl(null, Validators.required),
      storeName: new FormControl(null),
      active: new FormControl(false, Validators.required),
      language: new FormControl(null, Validators.required),
      roles: new FormArray([], RolesValidator)
    });

    this.form.controls.roles.valueChanges.subscribe((roles) => {
      if (!roles) {
        return;
      }
      const rolesArray = roles as Array<boolean>;
      if (rolesArray.findIndex((role) => role == true) === 4) {
        this.isOnlyReportUserRoleSelected = true;
        this.form.controls.storeName.setValidators(Validators.nullValidator);
      } else {
        this.isOnlyReportUserRoleSelected = false;
        this.form.controls.storeName.setValidators(Validators.required);
      }
      this.form.controls.storeName.updateValueAndValidity();
    });
  }

  get rolesFormArray(): FormArray {
    return this.form.controls.roles as FormArray;
  }

  get roles(): boolean[] {
    return this.form.get(`roles`)?.value || [];
  }

  onSave() {
    this.showSpinner = true;
    if (!this.userId) {
      this.showSpinner = false;
      return;
    }

    const { name, email, phone, active, language, storeName } = this.form.value;
    const roles = this.roleList.filter((role) => this.roles[this.roleList.indexOf(role)]).map((role) => role.id);

    const storeId: string | undefined =
      storeName != '' || this.isOnlyReportUserRoleSelected
        ? this._mxpStoreDtos.filter((a) => a.name === storeName).map((a) => a.id)[0]
        : undefined;

    this.mxpSnackBarService
      .wrapApiCall(
        'SNACKBAR.UPDATE_USER',
        this.mxpUserGrpcClient.updateUser(
          new UpdateMxpUserRequest({
            id: this.userId,
            name,
            email,
            phone,
            language,
            active,
            roles,
            storeId
          }),
        ),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => this.dialogRef.close(true),
        () => this.dialogRef.close(false),
        () => this.authService.user$.subscribe((user) => {
          if (user) {
            if(user['https://rezolve.com/mxp_user_id'] === this.userId)
            {
              this.mxpTranslationService.updateTranslationLanguage(language);
            }
            this.showSpinner = false;
          }
        },
        (error)=>{
          console.error(error);
          this.showSpinner = false;
          this.dialogRef.close(false);
        })
      );
  }

  getRoleDescription(permissions: PermissionTypeEnum[]): string {
    return permissions
      .map((permission) => this.mxpTranslationService.getTranslation('PERMISSIONS.' + permission))
      .join(', ');
  }

  private buildRolesForm(): void {
    this.rolesFormArray.clear();

    this.roleList.forEach(() => this.rolesFormArray.push(new FormControl(false)));
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private loadStoresWithPsp(stores: MxpStoreDto[]): void {
    const storesWithPsp = stores.reduce((storeList: string[], store: MxpStoreDto) => {
      if (store.pspId && store.name) {
        storeList.push(store.name);
        this._mxpStoreDtos = [...this._mxpStoreDtos, store];
      }

      return storeList;
    }, []);
    this.stores = BaseDropDownItemUtils.transformList(storesWithPsp);
  }
}
