<div class="main-container">
  <div class="title-container">
    <h2 mat-dialog-title>{{ 'CONFIGURE_NEW_CODI_ACCOUNT' | translate }}</h2>
    <rezolve-checkbox class="codi-checkbox" (checkedChanged)="handlePropagateToAll($event)">
      <span>
        {{'PROPAGATE_TO_ALL_CHECKBOX' | translate }}
      </span>
    </rezolve-checkbox>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form" #codiAccountForm="ngForm" fxLayout="column">
      <div class="codi-account-form-container">
        <rezolve-divider>{{ 'ACCOUNT_DETAILS' | translate | uppercase }}</rezolve-divider>
        <rezolve-basic-input
          required="true"
          [label]="'BENEFICIARY_NAME' | translate"
          formControlName="beneficiaryName"
          [placeholder]="'BENEFICIARY_NAME_PLACEHOLDER' | translate"
          [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }"></rezolve-basic-input>
          <rezolve-drop-down required formControlName="beneficiaryBank" [label]="'BENEFICIARY_BANK' | translate"
          [placeholder]="'BENEFICIARY_BANK_PLACEHOLDER' | translate" [items]="bankCodes" [optionTemplate]="bankCodeItems"
          [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }">
          <ng-template #bankCodeItems let-item> {{ item.key | translate }}</ng-template>
        </rezolve-drop-down>
        <rezolve-basic-input
          required="true"
          [label]="'BENEFICIARY_ACCOUNT_NUMBER' | translate"
          formControlName="beneficiaryAccountNumber"
          [placeholder]="'BENEFICIARY_ACCOUNT_NUMBER_PLACEHOLDER' | translate"
          [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }">
        </rezolve-basic-input>
        <rezolve-divider>{{ 'CODI_CREDENTIALS' | translate | uppercase }}</rezolve-divider>
        <rezolve-basic-input
        required="true"
        [label]="'CERTIFICATE_SERIAL_NUMBER' | translate"
        formControlName="certificateSerialNumber"
        [placeholder]="'CERTIFICATE_SERIAL_NUMBER_PLACEHOLDER' | translate"
        [errorDescriptions]="{
          required: 'REQUIRED_ERROR_MESSAGE' | translate
        }"></rezolve-basic-input>
        <rezolve-basic-input
          required="true"
          [label]="'BENEFICIARY_ACCOUNT_ALIAS' | translate"
          formControlName="beneficiaryAccountAlias"
          [placeholder]="'BENEFICIARY_ACCOUNT_ALIAS_PLACEHOLDER' | translate"
          [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }"></rezolve-basic-input>
        <rezolve-basic-input
          required="true"
          [label]="'BENEFICIARY_ACCOUNT_DIGIT' | translate"
          formControlName="beneficiaryAccountDigit"
          [placeholder]="'BENEFICIARY_ACCOUNT_DIGIT_PLACEHOLDER' | translate"
          [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }"></rezolve-basic-input>
        <rezolve-basic-input
          required="true"
          [label]="'BENEFICIARY_ACCOUNT_KEY_SOURCE' | translate"
          formControlName="beneficiaryAccountKeySource"
          [placeholder]="'BENEFICIARY_ACCOUNT_KEY_SOURCE_PLACEHOLDER' | translate"
          [isPassword]="true"
          [errorDescriptions]="{
            required: 'REQUIRED_ERROR_MESSAGE' | translate
          }"></rezolve-basic-input>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="end">
    <rezolve-secondary-button class="cancel-button" classList="medium" mat-dialog-close>
      {{ 'CANCEL' | translate }}</rezolve-secondary-button
    >
    <rezolve-primary-button
      [disabled]="!!codiAccountForm.invalid || isLoading"
      classList="medium"
      (handleClick)="onSave()">
      {{ 'CREATE_NEW' | translate }}</rezolve-primary-button
    >
  </mat-dialog-actions>
  <div class="spinner-container" *ngIf="isLoading"><mat-spinner></mat-spinner></div>
</div>
