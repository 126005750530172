import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentsRequestComponent } from './payments-request/payments-request.component';
import { PaymentQrComponent } from './payment-qr/payment-qr.component';
import { PaymentCompleteComponent } from './payment-complete/payment-complete.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@rezolved/ui';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '../../shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { PaymentProcessingComponent } from './payment-processing/payment-processing.component';
import { PaymentNotificationComponent } from './payment-notification/payment-notification.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PaymentNotificationSentComponent } from './payment-notification-sent/payment-notification-sent.component';
import { PaymentStatusUpdateComponent } from './payment-status-update/payment-status-update.component';
import { PaymentSentComponent } from './payment-sent/payment-sent.component';
import { CustomPipesModule } from '../../core/pipes/custom-pipes.module';
import { BulkPaymentComponent } from './bulk-payment/bulk-payment.component';
import { MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    PaymentsRequestComponent,
    PaymentQrComponent,
    PaymentCompleteComponent,
    PaymentProcessingComponent,
    PaymentNotificationComponent,
    PaymentNotificationSentComponent,
    PaymentStatusUpdateComponent,
    PaymentSentComponent,
    BulkPaymentComponent
  ],
  exports: [
    PaymentsRequestComponent,
    PaymentQrComponent,
    PaymentCompleteComponent,
    PaymentProcessingComponent,
    PaymentNotificationComponent,
    PaymentNotificationSentComponent,
    PaymentStatusUpdateComponent,
    PaymentSentComponent,
    BulkPaymentComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    UiModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule.forChild(),
    SharedModule,
    MatCardModule,
    FlexModule,
    FlexLayoutModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    CustomPipesModule,
    MatProgressBarModule
  ],
})
export class PaymentsModule {}
