import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MxpStoreDto, MxpStoreGrpcClient, UpdateMxpStoreRequest, UpdateMxpStoreResponse } from '@rezolved/mxp-proto';
import { CountriesEnum } from '../../../core/enums/countries.enum';
import { finalize } from 'rxjs/operators';
import { BaseDropDownItem, BaseDropDownItemUtils } from '@rezolved/ui';
import { MxpSnackBarService } from '../../../core/services/mxp-snackbar.service';
import { NumberPatternEnum } from 'libs/ui/src/lib/enums/number-pattern.enum';
import { StringValue } from '@ngx-grpc/well-known-types';

@Component({
  selector: 'mxp-store-edit-form',
  templateUrl: './store-form.component.html',
  styleUrls: ['./store-form.component.scss'],
})
export class StoreFormComponent {
  form: FormGroup;

  isAdmin = true;
  isLoading = false;
  countries: BaseDropDownItem[];
  accountTypes: BaseDropDownItem[];
  phoneFieldHasErrors = false;
  pattern = NumberPatternEnum.POSITIVE_DECIMAL;
  constructor(
    @Inject(MAT_DIALOG_DATA) public store: MxpStoreDto,
    public dialogRef: MatDialogRef<StoreFormComponent>,
    private mxpStoreGrpcClient: MxpStoreGrpcClient,
    private mxpSnackBarService: MxpSnackBarService,
    private dialog: MatDialog,
  ) {
    this.countries = BaseDropDownItemUtils.transformEnum(CountriesEnum);
    this.accountTypes = BaseDropDownItemUtils.transformList([this.accountType]);
    this.form = new FormGroup({
      name: new FormControl(store.name, Validators.required),
      street1: new FormControl(store.street1, Validators.required),
      street2: new FormControl(store.street2),
      city: new FormControl(store.city, Validators.required),
      state: new FormControl(store.state, Validators.required),
      zip: new FormControl(store.zip, Validators.compose([Validators.required, Validators.pattern('\\d+'), Validators.maxLength(5)])),
      country: new FormControl(store.country, Validators.required),
      phone: new FormControl(store.phone, Validators.compose([Validators.required, Validators.pattern('\\+?\\d+')])),
      callbackurl: new FormControl(store.callbackWebhookUrl?.value, Validators.compose([Validators.pattern(
        '^([a-zA-Z]+:\\/\\/)' + // protocol
        '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
        '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-zA-Z\\d_]*)?$')]))
    });
    this.form.get('phone')?.valueChanges.subscribe((x) => {
      if (x && x !== store.phone) {
        this.form.markAsTouched();
      }
      this.phoneFieldHasErrors = false;
    });
    this.form.markAsUntouched();
  }

  get country(): string {
    return this.form.get(`country`)?.value || ``;
  }

  get accountType(): string {
    return this.store.pspId?.value || ``;
  }

  handlePhoneFieldError() {
    this.phoneFieldHasErrors = true;
  }

  save = () => {
    this.isLoading = true;

    const { name, street1, street2, city, state, zip, country, phone } = this.form.value;
    let callbackWebhookUrl: StringValue = new StringValue();
    callbackWebhookUrl.value = this.form.get('callbackurl')?.value;

    const updateMxpStoreRequest = new UpdateMxpStoreRequest({
      id: this.store.id,
      name,
      street1,
      street2,
      city,
      state: state,
      zip: zip,
      country,
      phone,
      callbackWebhookUrl: callbackWebhookUrl
    });

    var response = this.mxpStoreGrpcClient.updateStore(updateMxpStoreRequest);
    response.subscribe(
      (response: UpdateMxpStoreResponse) => {
        this.isLoading = false
        const targetMessage = response.success ? 'SNACKBAR.UPDATE_STORE' : 'SNACKBAR.UPDATE_STORE_FAIL';
        this.mxpSnackBarService.showSnackbarMessage(targetMessage);
        this.dialogRef.close(response.success ? updateMxpStoreRequest : null);
      },
      (error) => {
        this.isLoading = false
        this.mxpSnackBarService.showSnackbarMessage('SNACKBAR.UPDATE_STORE_FAIL');
      }
    );
  };
}
