import { Component, OnInit } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { BasicButtonDirective } from '../../abstract/basic-button';

@Component({
  selector: 'rezolve-basic-button',
  templateUrl: './basic-button.component.html',
  styleUrls: ['./basic-button.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: BasicButtonComponent }],
})
export class BasicButtonComponent extends BasicButtonDirective implements OnInit {
  ngOnInit(): void {
    this.buttonClass = `basic-button ${this.classList}`;
  }
}
