/* eslint-disable */
// TODO: This will be refactored so lint is disabled
import { ChangeDetectorRef, Component, ComponentRef, TemplateRef, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'rezolve-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseModalComponent {
  data: any;
  showCloseButton = true;
  headerTemplate: TemplateRef<any> | null = null;
  contentTemplate: TemplateRef<any> | null = null;
  headerComponent: any;
  contentComponent: any;
  constructor(public dialogRef: MatDialogRef<BaseModalComponent>, private changeDetector: ChangeDetectorRef) {}

  updateHeaderTemplate(header: TemplateRef<any>, data?: any, showCloseButton = true): void {
    this.headerTemplate = header;
    this.data = data;
    this.showCloseButton = showCloseButton;
    this.changeDetector.detectChanges();
  }

  updateDialogContentTemplate(content: TemplateRef<any>, data?: any, showCloseButton = true): void {
    this.contentTemplate = content;
    this.data = data;
    this.showCloseButton = showCloseButton;
    this.changeDetector.detectChanges();
  }

  updateHeaderComponent(header: ComponentRef<any>, showCloseButton = true): void {
    this.headerComponent = header;
    this.showCloseButton = showCloseButton;
    this.changeDetector.detectChanges();
  }

  updateDialogContentComponent(content: any, showCloseButton = true): void {
    this.contentComponent = content;
    this.showCloseButton = showCloseButton;
    this.changeDetector.detectChanges();
  }
  close() {
    this.dialogRef.close();
  }
}
