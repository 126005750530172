export const enTranslations = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  PAST_MONTH: 'Past month',
  PAST_THREE_MONTHS: 'Past 3 months',
  PAST_SIX_MONTHS: 'Past 6 months',
  ALWAYS: 'Always',
  APPLY: 'Apply',
  FROM: 'From',
  TO: 'To'
};
