<div class="container">
  <img alt="payment-complete" [src]="info?.imageUrl" />
  <p class="primary-text">
    {{ info?.primaryStatusText || '' | translate }}
  </p>
  <span class="primary-text">
    {{ info?.paymentStatus?.amount | currency: 'MXN' }} {{ info?.secondaryStatusText || '' | translate }}
  </span>
  <span class="payment-description">
    {{ info?.paymentStatus?.description }}
  </span>
  <rezolve-primary-button (handleClick)="requestNewPayment()">
    {{ 'REQUEST_NEW_PAYMENT' | translate }}
  </rezolve-primary-button>
</div>
