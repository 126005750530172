import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { StoreFormComponent } from '../store-form/store-form.component';
import { MxpStoreDto, UpdateMxpStoreRequest } from '@rezolved/mxp-proto';
import { PspCreationComponent } from '../../psps-module/psp-creation/psp-creation.component';
import { BaseDropDownItem, BaseDropDownItemUtils } from '@rezolved/ui';
import { DeleteStoreComponent } from '../delete-store/delete-store.component';
import { AuthService } from '@rezolved/auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Auth0MxpUserRoles } from '../../../core/constants/UserGroups';
import { PspUpdateComponent } from '../../psps-module/psp-update/psp-update.component';

@Component({
  selector: 'mxp-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss'],
})
export class StoreComponent implements OnInit {
  @Input() store!: MxpStoreDto;
  @Output() mxpStoreDeletedEvent: EventEmitter<string> = new EventEmitter();
  @Output() mxpPspUpdated : EventEmitter<boolean> = new EventEmitter();
  selectedAccount: string | undefined;
  accountTypes: BaseDropDownItem[] = [];
  canEditStoreAndEditCodeAccount$: Observable<boolean> | undefined;
  canDeleteStore$: Observable<boolean> | undefined;

  constructor(private dialog: MatDialog, private authService: AuthService) {
    this.canDeleteStore$ = this.authService.roles$.pipe(
      map((roles: string[]) => roles.includes(Auth0MxpUserRoles.MXP_PARTNER)
      || roles.includes(Auth0MxpUserRoles.MXP_POWER_ADMIN)),
    );
    this.canEditStoreAndEditCodeAccount$ = this.authService.roles$.pipe(
      map((roles: string[]) => roles.includes(Auth0MxpUserRoles.MXP_STORE_ADMIN)
      || roles.includes(Auth0MxpUserRoles.MXP_PARTNER)
      || roles.includes(Auth0MxpUserRoles.MXP_POWER_ADMIN)),
    );
  }

  ngOnInit(): void {
    this.accountTypes = BaseDropDownItemUtils.transformList([this.accountType]);
  }

  editStoreClicked = () => {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '600px';
    dialogConfig.data = this.store;

    this.dialog
      .open(StoreFormComponent, dialogConfig)
      .afterClosed()
      .subscribe((updateRequest: UpdateMxpStoreRequest | null) => {
        if (updateRequest) {
          this.store = Object.assign(this.store, { ...updateRequest });
        }
      });
  };

  deleteStoreClicked = () => {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '370px';
    dialogConfig.data = this.store.id;

    this.dialog
      .open(DeleteStoreComponent, dialogConfig)
      .afterClosed()
      .subscribe((isDeleted: boolean | null) => {
        if (isDeleted) {
          this.mxpStoreDeletedEvent.emit(dialogConfig.data);
        }
      });
  };

  verifyStoreClicked = () => {
    // implementation of verify here
  };

  getAddress(): string {
    const { street1, street2, city, state, zip } = this.store;
    return `${street1} ${street2}, ${city}, ${state} ${zip}`;
  }

  get accountType(): string {
    return this.store.pspId?.value || ``;
  }

  createNewCodiAccount() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '600px';
    dialogConfig.data = this.store.id;

    this.dialog
      .open(PspCreationComponent, dialogConfig)
      .afterClosed()
      .subscribe((response: any) => {
        if (response) {
          this.mxpPspUpdated.emit(true);
        }
      });
  }

  editCodiAccount() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '600px';
    dialogConfig.data = this.store.id;
    dialogConfig.panelClass = 'relative-overlay'

    this.dialog.open(PspUpdateComponent, dialogConfig);
  }
}
