/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileFormatEnum } from '../../core/enums/file-format-enum';
import { PaymentStatusOption, PaymentStatusOptions } from '../../core/enums/payment-status-enum';
import { BehaviorSubject } from 'rxjs';
import { QueryData } from '../../../../../../libs/ui/src/lib/interfaces/base-table/query-data';
import { ICalendarOption } from '../../../../../../libs/ui/src/lib/components/atoms/calendar-date-ranger-picker/ICalendarOption';
import * as moment from 'moment';
import { Empty, StringValue, Timestamp } from '@ngx-grpc/well-known-types';
import { PaymentStatusUtils } from '../../core/utils/payment-status.utils';
import { AuthService } from '@rezolved/auth';
import { BaseDropDownItem, BaseDropDownItemUtils } from '@rezolved/ui';
import { MxpSnackBarService } from '../../core/services/mxp-snackbar.service';
import { displayReports } from '../../core/store/actions/app.actions';
import { IAppState } from '../../core/store/app.interface';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { ReportsTableService } from './reports-table/reports-table.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertDialogComponent } from '../../shared/components/alert-dialog/alert-dialog.component';
import { saveAs } from 'file-saver';
import {
  MxpPaymentGrpcClient,
  PaymentReportFileTypeEnum,
  PaymentStatusEnum,
  PaymentTypeEnum,
  GetUpdatePaymentStatusResponse,
  UpdateMultiplePaymentsRequest,
  MxpReportGrpcClient,
  ReportUsersFilterDto,
  ReportStoresFilterDto,
  MxpPaymentReportGrpcClient,
  GenerateReportFileRequestV3,
  SortDirectionEnum,
  PaginationData
} from '@rezolved/mxp-proto';
import { MatSort } from '@angular/material/sort';

const allStoreId = 'ALL';
const allUserName = 'ALL';
const allPaymentType = PaymentTypeEnum.ALL;
const allUserId = '';

@Component({
  selector: 'mxp-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent {

  private partnerId?: string;
  private merchantId?: string;
  statusOptions: BaseDropDownItem[];
  //fileFormatOption: BaseDropDownItem[];
  //selectedFormat = FileFormatEnum.CSV;
  selectedStatus: PaymentStatusOption[] = PaymentStatusOptions;
  allPaymentStatus: boolean = true;
  createdOnDate = undefined;
  interactedOnDate = undefined;
  selectedStoreId: string = allStoreId;
  selectedUserId: string = '';
  selectedPaymentType: PaymentTypeEnum = allPaymentType;
  interactedOnDateLabel = 'INTERACTED_ON_DATE_RANGE';
  createdOnDateLabel = 'CREATED_ON_DATE_RANGE';
  downloadIcon = 'download';
  pageSizeOptions = [2, 5, 10, 20];
  stores: BaseDropDownItem[] = [];
  users: BaseDropDownItem[] = [];
  paymentTypes: BaseDropDownItem[] = [];
  usersSource: ReportUsersFilterDto[] = [];
  searchDescription: string = '';
  updatingPayments: boolean = false;
  isUpdatePaymentMessageReady: boolean = false;
  paymentsToUpdate: number = 0;
  paymentsAlreadyUpdated: number = 0;
  paymentStatusSelectedValues: PaymentStatusEnum[] = [];
  sort!: MatSort;
  PaymentReportFileTypeEnum = PaymentReportFileTypeEnum

  constructor(private mxpPaymentGrpcClient: MxpPaymentGrpcClient,
              private mxpPaymentReportGrpcClient: MxpPaymentReportGrpcClient,
              public authService: AuthService,
              private mxpSnackBarService: MxpSnackBarService,
              private mxpReportGrpcClient: MxpReportGrpcClient,
              private readonly store: Store<IAppState>,
              public reportsTableService: ReportsTableService,
              private translate: TranslateService,
              private dialog: MatDialog)
  {

    this.authService.user$.subscribe((user) => {
      if (user) {
        this.partnerId = user['https://rezolve.com/mxp_partner_id'];
        this.merchantId = user['https://rezolve.com/mxp_merchant_id'];
      }
    });

    //this.fileFormatOption = BaseDropDownItemUtils.transformEnum(FileFormatEnum);

    this.statusOptions = [
      ...BaseDropDownItemUtils.transformEnum(PaymentStatusEnum),
    ];

    this.paymentStatusSelectedValues = [
      PaymentStatusEnum.NOT_INTERACTED,
      PaymentStatusEnum.INTERIM,
      PaymentStatusEnum.PAYMENT_MADE,
      PaymentStatusEnum.REQUEST_POSTPONED,
      PaymentStatusEnum.REQUEST_REJECTED,
    ];

    this.paymentTypes = [
      ...BaseDropDownItemUtils.transformEnum(PaymentTypeEnum),
    ];

    //this.store.dispatch(displayReports());
    this.fetchStoresAndUsers();
    this.getUpdatePaymentStatuses();
  }

  

  query: BehaviorSubject<QueryData<PaymentStatusEnum[],PaymentTypeEnum>> = new BehaviorSubject<QueryData<PaymentStatusEnum[],PaymentTypeEnum>>({
    status: [
      PaymentStatusEnum.NOT_INTERACTED,
      PaymentStatusEnum.INTERIM,
      PaymentStatusEnum.PAYMENT_MADE,
      PaymentStatusEnum.REQUEST_POSTPONED,
      PaymentStatusEnum.REQUEST_REJECTED
    ],
    type:PaymentTypeEnum.ALL,
    createdOnDate: {
      start: moment.utc('0001-01-01').toDate(),
      end: moment().endOf('day').toDate()
    },
    interactedOnDate: {
      start: moment.utc('0001-01-01').toDate(),
      end: moment().endOf('day').toDate()
    },
    additionalData: []
  });

  getUpdatePaymentsMessage(): string {
    if (this.paymentsToUpdate !== 0) {
      return (
        this.translate.instant('UPDATING_PAYMENTS') + `[${this.paymentsAlreadyUpdated} / ${this.paymentsToUpdate}]`
      );
    }
    return '';
  }

  fetchStoresAndUsers(deleteSelectedUser: boolean = true) {
    this.mxpReportGrpcClient.getReportFilters(new Empty()).subscribe(response => {
      if (response.stores) {
        this.stores = response.stores.reduce(
          (storeList: BaseDropDownItem[], store: ReportStoresFilterDto) => {
            if (store.id && store.name) {
              const dropDownItem: BaseDropDownItem = { value: store.id || '', key: store.name };
              storeList.push(dropDownItem);
            }

            return storeList;
          },
          [{ key: allStoreId, value: allStoreId }]
        );
      }

      if (response.users) {
        this.usersSource = response.users;
        this.prepareUsersDropdown(deleteSelectedUser);
      }
    },
      (error) => console.log(error)
    );
  }

  prepareUsersDropdown(deleteSelectedUser: boolean = true) {
    let filteredUsersSource =
      this.selectedStoreId == allStoreId
        ? this.usersSource
        : this.usersSource.filter((x) => x.storeId == this.selectedStoreId);
    this.users = filteredUsersSource.reduce(
      (usersList: BaseDropDownItem[], user: ReportUsersFilterDto) => {
        if (user.id && user.name) {
          const dropDownItem: BaseDropDownItem = { value: user.id || '', key: user.name };
          usersList.push(dropDownItem);
        }

        return usersList;
      },
      [{ key: allUserName, value: allUserId }]
    );

    this.users.sort((a, b) => (a.key > b.key ? 1 : -1));
    if (deleteSelectedUser || !this.users.some((x) => x.value == this.selectedUserId)) {
      this.selectedUserId = '';
    }
  }

  refresh(): void {
    this.store.dispatch(displayReports());
    this.fetchStoresAndUsers(false);
    this.getUpdatePaymentStatuses();
    this.query.next(
      {
        ...this.query.value,
        additionalData: [
          ([this.selectedStoreId].filter((storeId) => storeId !== allStoreId)),
          this.selectedUserId,
          this.searchDescription]
      });
  }

  updatePaymentStatuses() {

    //TODO: verify if we should update payment statuses based on interacted on field ??
    if (parseInt(this.reportsTableService.totalCount) > 100) {
      this.dialog
        .open(AlertDialogComponent, { data: { message: "VALIDATION_ERROR_UPDATE_PAYMENTS_COUNT" } });
      return;
    }
    let partnerIdMapped = new StringValue();
    let merchantIdMapped = new StringValue();
    let selectedUserIdMapped = new StringValue();
    let searchDescriptionMapped = new StringValue();
    let selectedPaymentStatuses: PaymentStatusEnum[] = [];
    let createdOnDateStart = moment.isMoment(this.query.value.createdOnDate.start) ? this.query.value.createdOnDate.start.toDate() : this.query.value.createdOnDate.start;
    let createdOnDateEnd = moment.isMoment(this.query.value.createdOnDate.end) ? this.query.value.createdOnDate.end.toDate() : this.query.value.createdOnDate.end;   
    const createdOnDateFrom = new Timestamp();
    const createdOnDateTo = new Timestamp();
    createdOnDateFrom.fromDate(createdOnDateStart);
    createdOnDateTo.fromDate(createdOnDateEnd);
    let interactedOnDateStart = moment.isMoment(this.query.value.interactedOnDate.start) ? this.query.value.interactedOnDate.start.toDate() : this.query.value.interactedOnDate.start;
    let interactedOnDateEnd = moment.isMoment(this.query.value.interactedOnDate.end) ? this.query.value.interactedOnDate.end.toDate() : this.query.value.interactedOnDate.end;
    const interactedOnDateFrom = new Timestamp();
    const interactedOnDateTo = new Timestamp();
    interactedOnDateFrom.fromDate(interactedOnDateStart);
    interactedOnDateTo.fromDate(interactedOnDateEnd);
    const storeIds: string[] = this.query.value.additionalData[0] ?? [];
    partnerIdMapped.value = this.partnerId;
    merchantIdMapped.value = this.merchantId;
    selectedUserIdMapped.value = this.selectedUserId;
    searchDescriptionMapped.value = this.searchDescription;
    selectedPaymentStatuses = this.paymentStatusSelectedValues;
    let selectedValuesArray: PaymentStatusEnum[] = [];
    selectedPaymentStatuses.forEach((item, index) => {
      if (item != undefined) selectedValuesArray.push(item as PaymentStatusEnum);
    });
    let request = new UpdateMultiplePaymentsRequest({
      userId: selectedUserIdMapped,
      statusList: selectedValuesArray,
      createdOnDateFrom: createdOnDateFrom,
      createdOnDateTo: createdOnDateTo,
      interactedOnDateFrom: interactedOnDateFrom,
      interactedOnDateTo: interactedOnDateTo,
      storeIds: storeIds,
      paymentType: PaymentTypeEnum.ALL,
      searchTerm: searchDescriptionMapped
    });

    this.mxpSnackBarService
      .wrapApiCall(
        'SNACKBAR.PAYMENT_UPDATE_STATUSES',
        this.mxpPaymentGrpcClient.updateMultiplePayments(request),
        false,
        'SNACKBAR.DISMISS',
        3000,
        true
      )
      .subscribe(
        () => {
          this.updatingPayments = true;
          this.isUpdatePaymentMessageReady = false;
        },
        (error) => {
          this.updatingPayments = false;
          console.log(error);
        }
      );
  }

  getUpdatePaymentStatuses() {
    this.authService.user$.pipe(first()).subscribe((user) => {
      if (user && (user['https://rezolve.com/mxp_partner_id'] || user['https://rezolve.com/mxp_merchant_id'])) {
        this.mxpPaymentGrpcClient.getUpdatePaymentStatus(new Empty()).subscribe(
          (response: GetUpdatePaymentStatusResponse) => {
            this.updatingPayments = response.updatingPayments ?? false;
            this.paymentsToUpdate = response.totalPaymentsToUpdate ?? 0;
            this.paymentsAlreadyUpdated = response.paymentsAlreadyUpdated ?? 0;
            this.isUpdatePaymentMessageReady = true;
          },
          (error) => {
            this.updatingPayments = false;
            console.log(error);
          }
        );
      }
    });
  }

  getLabel(option: PaymentStatusOption[]): string {
    return PaymentStatusUtils.getPaymentStatusString(option);
  }

  //formatChanged(fileFormat: FileFormatEnum) {
  //  this.selectedFormat = fileFormat;
  //}

  storeChange(store: string) {
    this.selectedStoreId = store;
    this.prepareUsersDropdown();
    this.query.next(
      {
        ...this.query.value,
        additionalData: [
          ([this.selectedStoreId].filter((storeId) => storeId !== allStoreId)),
          this.selectedUserId,
          this.searchDescription]
      });
  }

  userChange(user: string) {
    this.query.next({
      ...this.query.value,
      additionalData: [
        [this.selectedStoreId].filter((storeId) => storeId !== allStoreId),
        user,
        this.searchDescription
      ]
    });
    this.selectedUserId = user;
  }

 paymentTypeChanged(paymentType:PaymentTypeEnum){  
  this.query.next({
    ...this.query.value,
    type:paymentType,
    additionalData: [
      [this.selectedStoreId].filter((storeId) => storeId !== allStoreId),
      this.searchDescription
    ]
  });
  this.selectedPaymentType = paymentType
 }

  statusChanged(statuses: PaymentStatusOption[]) {
    let selectedValuesArray: PaymentStatusEnum[] = [];
    statuses.forEach((item, index) => {
      if (item != undefined) selectedValuesArray.push(item as PaymentStatusEnum);
    });
    this.paymentStatusSelectedValues = selectedValuesArray;
    this.query.next({
      ...this.query.value,
      status: PaymentStatusUtils.getPaymentStatus(this.paymentStatusSelectedValues)
    });
    this.selectedStatus = statuses;
  }

  selectAllStateChanged(selectAll: any) {
    let allStatusQueryArray: PaymentStatusEnum[] = [];
    if (!selectAll.checked) {
      this.paymentStatusSelectedValues = [];
      allStatusQueryArray = [];
    }
    else {
      allStatusQueryArray = [
        PaymentStatusEnum.NOT_INTERACTED,
        PaymentStatusEnum.INTERIM,
        PaymentStatusEnum.PAYMENT_MADE,
        PaymentStatusEnum.REQUEST_POSTPONED,
        PaymentStatusEnum.REQUEST_REJECTED
      ];
      this.paymentStatusSelectedValues = allStatusQueryArray;
    }
    this.query.next({
      ...this.query.value,
      status: allStatusQueryArray
    });
  }

  createdOnDateChanged(event: ICalendarOption) {
    this.query.next(<QueryData<PaymentStatusEnum[],PaymentTypeEnum>>{
      ...this.query.value,
      createdOnDate: event.dateRange
    });
    return this.createdOnDate === event;
  }

  interactedOnDateChanged(event: ICalendarOption) {
    this.query.next(<QueryData<PaymentStatusEnum[],PaymentTypeEnum>>{
      ...this.query.value,
      interactedOnDate: event.dateRange
    });
    return this.interactedOnDate === event;
  }

  applySearchTerm(searchTerm: string) {
    this.searchDescription = searchTerm ? searchTerm.trim() : '';
    this.query.next({
      ...this.query.value,
      additionalData: [
        [this.selectedStoreId].filter((storeId) => storeId !== allStoreId),
        this.selectedUserId,
        this.searchDescription,
      ]
    });
  }

  handleSortChange(sort: MatSort) {
    this.sort = sort;
  }

  downloadReport(fileType: PaymentReportFileTypeEnum) {
    const enc = new TextDecoder('utf-8');
    let storeIds: string[];
    let userId: StringValue = new StringValue();
    let description: StringValue = new StringValue();
    let userTimeZone: StringValue = new StringValue();
    let selectedPaymentStatuses: PaymentStatusEnum[] = [];
    let createdOnDateStart = moment.isMoment(this.query.value.createdOnDate.start) ? this.query.value.createdOnDate.start.toDate() : this.query.value.createdOnDate.start;
    let createdOnDateEnd = moment.isMoment(this.query.value.createdOnDate.end) ? this.query.value.createdOnDate.end.toDate() : this.query.value.createdOnDate.end;   
    const createdOnDateFrom = new Timestamp();
    const createdOnDateTo = new Timestamp();
    createdOnDateFrom.fromDate(createdOnDateStart);
    createdOnDateTo.fromDate(createdOnDateEnd);
    let interactedOnDateStart = moment.isMoment(this.query.value.interactedOnDate.start) ? this.query.value.interactedOnDate.start.toDate() : this.query.value.interactedOnDate.start;
    let interactedOnDateEnd = moment.isMoment(this.query.value.interactedOnDate.end) ? this.query.value.interactedOnDate.end.toDate() : this.query.value.interactedOnDate.end;
    const interactedOnDateFrom = new Timestamp();
    const interactedOnDateTo = new Timestamp();
    interactedOnDateFrom.fromDate(interactedOnDateStart);
    interactedOnDateTo.fromDate(interactedOnDateEnd);
    selectedPaymentStatuses = this.paymentStatusSelectedValues;
    let selectedValuesArray: PaymentStatusEnum[] = [];
    selectedPaymentStatuses.forEach((item, index) => {
      if (item != undefined) selectedValuesArray.push(item as PaymentStatusEnum);
    });
    storeIds = this.query.value.additionalData[0] ?? [];
    userId.value = this.selectedUserId;
    description.value = this.searchDescription;
    userTimeZone.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const paginationData = {
      pageIndex: undefined,
      pageSize: undefined,
      sortField: this.sort?.active ? this.sort.active : 'createdOn',
      sortDirection: this.sort ?
        SortDirectionEnum[this.sort.direction.toUpperCase() as keyof typeof SortDirectionEnum] :
        SortDirectionEnum.DESC
    };

    const request = new GenerateReportFileRequestV3({
      userId: userId,
      statusList: selectedValuesArray,
      createdOnDateFrom: createdOnDateFrom,
      createdOnDateTo: createdOnDateTo,
      interactedOnDateFrom: interactedOnDateFrom,
      interactedOnDateTo: interactedOnDateTo,
      fileType: fileType,
      storeIds: storeIds,
      paymentType: PaymentTypeEnum.ALL,
      searchTerm: description,
      userTimezone: userTimeZone,
      paginationData
    });

    this.mxpSnackBarService
      .wrapApiCall('SNACKBAR.PAYMENT_REPORT', this.mxpPaymentReportGrpcClient.generateReportFileV3(request))
      .subscribe(
        (result) => {
          const bytes: Uint8Array = result.content as Uint8Array;
          const blob = new Blob([bytes]);
          saveAs(blob, result.fileName );
        },
        (error) => console.log(error)
      );
  }
}
