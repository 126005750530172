import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataSourceService } from '../../../interfaces/base-table/data-source-service';
import { map, take } from 'rxjs/operators';
import { BaseTableParams } from '../../../interfaces/base-table/base-table-params';

export interface TestTableData {
  value: [
    {
      role: string;
      timezone: string;
      created: string;
      name: string;
      language: string;
      id: string;
      store: string;
      email: string;
      status: string;
    },
  ];
  total: number;
}

@Injectable({
  providedIn: 'root',
})
export class TableTestService implements DataSourceService {
  constructor(private http: HttpClient) {}
  users$ = new BehaviorSubject({
    value: [
      {
        id: '1',
        name: 'Jonathan Doe',
        email: 'jonathandoe@rezolve.com',
        role: 'User',
        store: 'Moneymatic',
        timezone: 'America/Mexico',
        language: 'Spanish/Mexico',
        created: '18/07/2021',
        status: 'Active',
      },
      {
        id: '2',
        name: 'Jonathan Test',
        email: 'testdoe@rezolve.com',
        role: 'Administrator',
        store: 'Rezolve',
        timezone: 'Europe/London',
        language: 'Deutsch/Germany',
        created: '01/09/2021',
        status: 'Active',
      },
      {
        id: '3',
        name: 'Test Doe',
        email: 'jonathantest@rezolve.com',
        role: 'User',
        store: 'Limited',
        timezone: 'Europe/London',
        language: 'English/UK',
        created: '10/02/2021',
        status: 'Active',
      },
      {
        id: '4',
        name: 'Chris Dicken',
        email: 'chrisdicken@rezolve.com',
        role: 'Administrator',
        store: 'Git',
        timezone: 'India/Asia',
        language: 'Hindi/India',
        created: '11/11/2020',
        status: 'Active',
      },
    ],
    total: 4,
  });

  loadData(props: BaseTableParams): Observable<TestTableData> {
    return this.users$.asObservable().pipe(
      map((data) => {
        const startIndex = props.pageIndex * props.pageSize;
        return {
          value: data.value.slice(startIndex, startIndex + props.pageSize),
          total: data.total,
        } as TestTableData;
      }),
      take(1),
    );
  }
}
