export interface ICurrentWorkflow {
  currentStep: string;
  isLoading: boolean;
}

export interface IAppState {
  AppState: ICurrentWorkflow;
}

export const initialAppState: ICurrentWorkflow = {
  currentStep: '',
  isLoading: false,
};
